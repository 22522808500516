import React, { useState } from "react";
import { NavLink, useNavigate, Link } from "react-router-dom";
import HeaderLogo from "../assets/logos/Header_Logo.svg";
import Button from "../Components/Button";
import FormTextInput from "../Components/common/FormTextInput";
import TransitionAlert from "../Components/common/SnackbarAlert";
import { registerUser } from "../services/AuthService";
import { registrationFormSchema } from "../validations/AuthValidation";
import { getYupValidationErrors } from "../validations/ValidationHelper";
export default function RegistrationPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [registrationResult, setRegistrationResult] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [inputErrors, setInputErrors] = useState({});

  let navigate = useNavigate();
  const handleRegistration = async () => {
    setIsLoading(true);
    setShowAlert(false);
    try {
      await registrationFormSchema.validate(
        { firstname, lastname, email, password, phone },
        { abortEarly: false }
      );
      let result = await registerUser(firstname, lastname, email, phone, password);
      setRegistrationResult({
        messageType: "success",
        message: result.message,
        title: "Success!",
      });
      setInputErrors({})
      navigate(`/auth/transition?email=${email}`, { replace: true });
      setShowAlert(true);

    } catch (e) {
      if (e.name === "ValidationError") {
        let errors = getYupValidationErrors(e);
        setInputErrors(errors);
      } else {
        setRegistrationResult({
          messageType: "error",
          message: e.message,
          title: "Something went wrong!",
        });
        setShowAlert(true);
      }
    }
    setIsLoading(false);
  };

  const closeAlert = () => {
    setShowAlert(false);
  };

  return (
    <div className="flex bg-backgroundColor">
      <div className="flex flex-1 flex-col items-center">
        <img
          className="w-52 h-auto mt-4"
          src={HeaderLogo}
          alt="Vacosta Style Logo"
        />
        <div className="mt-8 md:mt-12 lg:mt-20 w-10/12 sm:w-9/12 md:w-5/12">
          <h1 className="text-primary text-4xl font-semibold border-b-4 border-b-gray-50 pb-4">
            SIGN UP
          </h1>
          <p className="my-5 text-gray-500 text-right">
            Already have an account? &nbsp;
            <Link to={'/auth/login'}>
              <span className='uppercase text-primary font-semibold cursor-pointer'>LOGIN</span>
            </Link>

          </p>
          <TransitionAlert
            open={showAlert}
            message={registrationResult.message}
            severity={registrationResult.messageType}
            title={registrationResult.title}
            handleClose={closeAlert}
          />
          <FormTextInput
            id="firstname"
            label="First Name"
            placeholder="First Name..."
            value={firstname}
            onChange={setFirstname}
            error={inputErrors.firstname}
          />
          <FormTextInput
            id="lastname"
            label="Last Name"
            placeholder="Last Name..."
            value={lastname}
            onChange={setLastname}
            error={inputErrors.lastname}
          />
          <FormTextInput
            id="email"
            label="Email"
            placeholder="Email..."
            value={email}
            onChange={setEmail}
            error={inputErrors.email}
          />
          <FormTextInput
            id="phone"
            label="Phone"
            placeholder="Phone Number..."
            value={phone}
            onChange={setPhone}
            error={inputErrors.phone}
          />
          <FormTextInput
            id="password"
            label="Password"
            placeholder="Password..."
            value={password}
            onChange={setPassword}
            error={inputErrors.password}
          />
          <div className="mt-5" onClick={handleRegistration}>
            <Button

              disabled={isLoading}
              bg_color={'#C84027'}
              color={'white'}
              label={isLoading ? "Loading ..." : "SIGN UP"}
            >

            </Button>
          </div>
          <p className="mt-5 mb-5 font-light tracking-wide text-gray-400">
            By signing up, you agree to our{" "}
            <NavLink to="/auth/register">
              <span className="font-semibold text-gray-500 hover:cursor-pointer">
                Privacy Policy
              </span>
            </NavLink>{" "}
            and{" "}
            <NavLink to="/auth/register">
              <span className="font-semibold text-gray-500 hover:cursor-pointer">
                Terms & Conditions
              </span>
            </NavLink>
            .
          </p>
        </div>
      </div>
      {/* <img
        src={require("../assets/images/registration-picture.jpg")}
        className="w-0 lg:w-auto h-screen"
        alt=""
      /> */}
    </div>
  );
}
