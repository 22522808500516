import React from 'react'
import { BsBoxSeam, BsImages } from "react-icons/bs";
import { MdGridView } from "react-icons/md";
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import HeaderLogo from '../../assets/SVG/Header_Logo.svg';
import useAuth from '../../hooks/useAuth';
import Button from '../Button';
import { logoutUser } from '../../services/AuthService';
import TransitionAlert from "./SnackbarAlert";
export default function AdminSidebar() {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      let result = await logoutUser();
      logout();
      navigate('/', { replace: true })
    }
    catch (e) {
      console.log(e);
    }
  };
  return (
    <div className='w-full mx-auto flex flex-col mt-2'>
      <div className='flex justify-between items-center'>
        <img src={HeaderLogo} className='w-[45%]'></img>

        <div className='w-[35%]' onClick={handleLogout} >
          <Button bg_color='#3a3a3a' color="white" label='Log Out'></Button>
        </div>

      </div>
      <div className="bg-[#F5F8FB] py-5 flex justify-center items-center mt-5">
        <p className='text-xl font-bold text-primary uppercase'>Admin</p>
      </div>

      <div className='flex flex-col mt-5'>
        <Link to="/admin/categoriesAdmin">
          <div className="bg-[#F5F8FB] py-5 flex items-center mt-3 hover:cursor-pointer relative justify-center rounded-sm">
            <p className='text-base font-medium uppercase'>Category Management</p>
          </div>
        </Link>
        <Link to="/admin/products">
          <div className="bg-[#F5F8FB] py-5 flex items-center mt-3 hover:cursor-pointer relative justify-center rounded-sm">
            <p className='text-base font-medium uppercase'>Product Management</p>
          </div>
        </Link>
        <Link to="/admin/banner">
          <div className="bg-[#F5F8FB] py-5 flex items-center mt-3 hover:cursor-pointer relative justify-center rounded-sm">

            <p className='text-base font-medium uppercase'>Banner Management</p>
          </div>
        </Link>
        <Link to="/admin/adminOrders">
          <div className="bg-[#F5F8FB] py-5 flex items-center mt-3 hover:cursor-pointer relative justify-center rounded-sm">

            <p className='text-base font-medium uppercase'>Orders History</p>
          </div>
        </Link>
      </div>
    </div >

  )
}
