import React from 'react'

const InputComponent = ({ placeholder, label, id, value, setValue, error }) => {
    return (
        <div className='w-full flex flex-col'>
            <label htmlFor={id} className='uppercase text-slate-500 mb-[2px]'>{label}</label>
            <input className='bg-white rounded-sm border-slate-100 p-4' placeholder={placeholder} value={value} onChange={(e) => setValue(e.currentTarget.value)} />
            {error && <p className="mt-2 text-sm font-bold text-primary">{error}</p>}
        </div>
    )
}

export default InputComponent