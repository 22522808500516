import React from 'react'
import { Delete, InstallMobile, Star, StarBorder } from '@mui/icons-material';
const ImageComponent = ({ setPreviousThumbnail, removeFromUI, item, index, setUploadedThumbnail, removeUploaded, type }) => {
    const [order, setOrder] = React.useState(item.order);
    const changeOrder = (item, order) => {
        setOrder(order);
        item.order = order;
    }
    if (type == 'images') {
        return <div className='w-full h-[250px] border border-primary text-center relative overflow-hidden'>
            <img src={`${item.img_url}`} className="mx-auto max-h-[250px]"></img>
            <div className='absolute top-2 right-2 cursor-pointer flex' >
                {item.is_thumbnail == 0 ? <StarBorder onClick={() => setPreviousThumbnail(item.image_id, index)} className='text-primary' /> : <Star className='text-primary'></Star>}
                <div className='mx-1' />
                <Delete className='text-primary' onClick={() => removeFromUI(index, item.image_id)}></Delete>
            </div>
            <div className='absolute bottom-4 right-4'>
                <input value={order} min={0} type='number' className='max-w-[100px] rounded-sm' onChange={(e) => changeOrder(item, e.target.value)}></input>
            </div>
        </div>
    }
    else {
        return <div className='w-full h-[250px] border border-primary text-center relative overflow-hidden'>
            <img className="mx-auto max-h-[250px]" src={item.url}></img>
            <div className='absolute top-2 right-2 cursor-pointer flex' >
                {item.is_thumbnail == 0 ? <StarBorder onClick={() => setUploadedThumbnail(index)} className='text-primary' /> : <Star className='text-primary'></Star>}
                <Delete onClick={() => removeUploaded(index)} className='text-primary'></Delete>
            </div>
            <div className='absolute bottom-4 right-4'>
                <input defaultValue={0} type='number' className='max-w-[100px] rounded-sm' onChange={(e) => changeOrder(item, e.target.value)}></input>
            </div>
        </div>
    }
}

export default ImageComponent