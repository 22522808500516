import React from 'react';
import { useEffect, useContext } from 'react';
import HeaderLogo from '../assets/SVG/Header_Logo.svg';
import '../Components/Header.css'
import { Badge, Menu, MenuItem } from '@mui/material';
import { GlobalContext } from '../context/GlobalContext';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { search } from '../services/ProductsService';
import SearchResults from './SearchResults';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { getSections } from '../services/CategoryService';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FavoriteIcon from '@mui/icons-material/Favorite';
import useAuth from '../hooks/useAuth';
import Button from './Button';
import { logoutUser } from '../services/AuthService';

const Header = ({ toggleDrawer, visibility, executeScroll }) => {
    const { cart } = useContext(GlobalContext);
    const navigate = useNavigate();
    const { isLoggedIn, logout } = useAuth();
    const [openNav, setOpenNav] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [query, setQuery] = React.useState(null);
    const [searchResults, setSearchResults] = React.useState([]);
    const [open, setOpen] = React.useState(true);
    const [sections, setSections] = React.useState({});
    const openMenu = Boolean(anchorEl);
    const [section, setSection] = React.useState(1);
    const [preventLoad, setPreventLoad] = React.useState(true);
    const today = new Date();
    const fetchSections = async () => {
        let result = await getSections();
        setSections(result);
    }
    const handleChange = (e) => {
        setOpenNav(false);
        if (open == false)
            setOpen(true);
        // const timeout = setTimeout(() => {
        // handleChange(e.event.target);
        setQuery(e);
        // }, 2000)
        // setQuery(e);
    };

    const handleClickAway = () => {
        if (searchResults.length != 0)
            setOpen(false);
    };
    const searchHandler = async () => {
        // setSection(1);
        let result = await search(query, 1);
        setSearchResults(result.data);
        if (result.current_page == result.last_page) setPreventLoad(true);
        else setPreventLoad(false);
        // setSearchResults(result.data);
    }
    const loadMore = async () => {
        let result = await search(query, section);
        const newArr = searchResults.concat(result.data);
        setSearchResults(newArr);
        if (result.current_page == result.last_page) setPreventLoad(true);
        else setPreventLoad(false);
    }
    const handleLogout = async () => {
        try {
            let result = await logoutUser();
            logout();
            navigate('/', { replace: true })
        }
        catch (e) {
            console.log(e);
        }
    }
    const handleClose = () => {
        setAnchorEl(null);
    }
    const handleOut = () => {
        handleClose();
        handleLogout();
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);

    };
    const focusSearch = () => {
        setOpen(true);
        setOpenNav(false);
    }
    useEffect(() => {
        if (query != null)
            searchHandler();
        // setSection(1);
    }, [query])
    useEffect(() => {
        if (query != null)
            loadMore();
        console.log(searchResults)
    }, [section])
    useEffect(() => {
        fetchSections();
    }, [])

    return (
        <div className='header'>
            <div onMouseLeave={() => setOpenNav(false)}>
                <header className='w-full bg-secondary p-3 pr-5 lg:pr-3 lg:p-5 flex flex-row justify-between items-center overflow-visible'>
                    <div className='logo'>
                        <Link to={'/'}>
                            <img src={HeaderLogo} className='w-40 lg:w-40' />
                        </Link>
                    </div>

                    <div className='items-center lg:flex'>
                        <div className='lg:flex flex-row hidden'>
                            <div id='home' className='uppercase mr-10 cursor-pointer transition ease-in-out duration-200 text-black hover:text-primary'>
                                <Link onClick={() => setOpenNav(false)} to="/"> Home</Link>
                            </div>
                            <div id='products' onMouseEnter={() => setOpenNav(true)} className='uppercase mr-10 cursor-pointer transition ease-in-out duration-200 text-black hover:text-primary'>
                                Products
                            </div>
                            <div id='daily' className='uppercase mr-10 cursor-pointer transition ease-in-out duration-200 text-black hover:text-primary' >
                                <Link onClick={() => setOpenNav(false)} to={"/new/" + today.toLocaleDateString('en-GB').replace(/\//g, '-')}>Daily New</Link>
                            </div>

                        </div>
                        <ClickAwayListener onClickAway={handleClickAway}>
                            <div className='mr-5 hidden lg:block relative' >
                                <input max={'75'} type="text" className="search rounded-lg bg-white text-[#808080] border-0 ring-0 outline-0 block min-w-[300px] max-w-[300px] w-[300px] min-h-[44px] max-h-[44px] text-md p-2.5 focus:border-2 focus:border-primary focus:ring-0 focus:outline-0" placeholder="Search for items..." value={query} onChange={(e) => handleChange(e.currentTarget.value)} onFocus={focusSearch} />
                                <SearchResults preventLoad={preventLoad} section={section} setSection={setSection} searchResults={searchResults} open={open} setOpen={setOpen} setQuery={setQuery} />
                            </div>
                        </ClickAwayListener>
                        <div className='flex'>
                            <div className='flex items-center'>
                                <div className={`cart mr-4 cursor-pointer ${visibility}`} onClick={toggleDrawer('right', true)}>
                                    <Badge badgeContent={cart.length} color="primary" variant={'dot'} >
                                        <ShoppingCartIcon fontSize='small' sx={{ color: "#495057" }}> </ShoppingCartIcon>
                                    </Badge>
                                </div>
                                <div className='hidden lg:block mr-4'>
                                    <Link to={'/wishList'}>
                                        <FavoriteIcon sx={{ color: "#495057" }}></FavoriteIcon>
                                    </Link>
                                </div>
                                <div className='block lg:hidden' >
                                    <MenuIcon fontSize='medium' sx={{ color: '#C84027' }} onClick={toggleDrawer('left', true)} />
                                </div>
                            </div>
                            <div className='profile hidden lg:block'>
                                {!isLoggedIn ? <Link to={'/auth/login'}><Button label={'Sign in'} color="white" bg_color={"#C84027"}></Button></Link> :
                                    <div className='cursor-pointer' onClick={handleClick}><AccountCircleIcon sx={{ color: "#495057" }}></AccountCircleIcon></div>
                                }
                                <Menu
                                    anchorEl={anchorEl}
                                    open={openMenu}
                                    onClose={handleClose}
                                    autoFocus={false}
                                    sx={{ fontFamily: 'Lato', textTransform: 'uppercase', mt: '10px' }}
                                >
                                    <MenuItem><Link onClick={handleClose} to={'/ordersHistory'}>Orders History</Link></MenuItem>
                                    <MenuItem onClick={handleOut} sx={{ color: 'red' }}>Sign Out</MenuItem>
                                </Menu>
                            </div>
                        </div>
                    </div>
                </header>

                <div id='navBar' className='navBar w-full bg-secondary p-3 pb-6 flex-row justify-center transition ease-in-out duration-300 absolute z-10' style={openNav ?
                    { display: 'flex' } : { display: 'none' }}>
                    {Object.keys(sections).map((key, index) => (sections[key]['categories'].length != 0 &&
                        <div key={'section-' + index} className='men bg-white p-5 w-[20%] rounded-lg mr-2'>
                            <h4 className='text-primary font-bold uppercase mb-1'>{key}</h4>
                            <ul className='font-light max-h-52 overflow-auto'>
                                {sections[key]['categories'].map((data, index) => (
                                    <Link to={`/products/${data.category_id}/1`} key={"subC-" + index}>
                                        <li ><div className='cursor-pointer hover:underline text-sm mb-[1px] capitalize'>{data.category_name}</div></li>
                                    </Link>
                                ))}
                            </ul>
                        </div>
                    ))
                    }

                </div>
            </div>
        </div >
    )
}

export default Header