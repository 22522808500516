import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import AdminSidebar from '../Components/common/AdminSidebar'
import { getVariantImages, editThumbnail } from '../services/AdminService';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import Radio from '@mui/material/Radio';

import { Link } from 'react-router-dom';
// import Button from '../Components/Button'
import { default as ButtonCustom } from '../Components/Button';
import { Online, Offline } from 'react-detect-offline';
const EditThumbnail = () => {
    const [selectedValue, setSelectedValue] = React.useState('-1');
    const [isLoading, setIsLoading] = React.useState(false);
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };
    const [images, setImages] = useState([]);
    const product_id = useParams();
    console.log(product_id['product_id'])
    const fetchAll = async () => {
        let images = await getVariantImages(product_id.product_id);
        setImages(images.variants);
        setSelectedValue(images.img_id);
    }

    const handleThumbnail = async (product_id, image_id) => {
        setIsLoading(true);
        await editThumbnail(product_id, image_id);
        setIsLoading(false);
    }

    useEffect(() => {
        fetchAll();
    }, [])

    return (
        <div className="flex flex-col lg:flex-row w-[95%] lg:w-[98%] mx-auto">
            <div className="w-full lg:w-1/4">
                <AdminSidebar />
            </div>

            <div className="w-full lg:w-3/4 lg:ml-6">

                <div className="flex justify-between items-center mt-5">
                    <h1 className="text-left uppercase text-2xl underline decoration-4 underline-offset-0 decoration-primary">
                        Edit Thumbnail
                    </h1>
                </div>

                <div className='grid gap-3 grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 w-full mt-4 mb-6'>


                    {images.map((item) => (
                        <ImageListItem key={"image-" + item.image_id}>
                            <img
                                src={`${item.img_url}`}
                                loading="lazy"
                            />
                            <ImageListItemBar
                                // title={item.title}
                                // subtitle={item.author}
                                actionIcon={
                                    <IconButton
                                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}

                                    >
                                        <Radio
                                            checked={selectedValue == item.image_id}
                                            onChange={handleChange}
                                            value={item.image_id}
                                            name="radio-buttons"
                                        />

                                    </IconButton>
                                }
                            />

                        </ImageListItem>
                    ))}

                </div>
                <div className="flex justify-between md:justify-end my-5">
                    <Link to="/admin/products">
                        <div className="md:mr-2">
                            <ButtonCustom bg_color='#f8f8f8' label="cancel" color="gray" disabled={isLoading} ></ButtonCustom>
                        </div>

                    </Link>
                    <div onClick={() => handleThumbnail(product_id.product_id, selectedValue)}>
                        <Online>
                            <ButtonCustom bg_color='#C84027' label={isLoading ? "Loading ..." : "SUBMIT"} color="white" disabled={isLoading}></ButtonCustom>
                        </Online>
                        <Offline>
                            <ButtonCustom bg_color='#C84027' label={'No Internet'} color="white" disabled={true}></ButtonCustom>
                        </Offline>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default EditThumbnail