import React from "react";
import { BsXCircle } from "react-icons/bs";
export default function FormSelectInput({
  id,
  multiple,
  selectedValues,
  removeValue,
  label,
  value,
  options,
  placeholder,
  onChange,
  error,
  className,
  ...props
}) {
  const getOptionWithValue = (value) => {
    let returnOption;
    options.forEach((option) => {

      if (option.value == value) {
        returnOption = option;
      }
    });
    return returnOption;
  };
  return (
    <div className="mt-2 lg:mt-5">
      <label
        className="block mb-3 text-lg text-formLabel font-bold"
        htmlFor={id}
      >
        {label}:
      </label>
      <select
        id={id}
        value={value}
        className="block rounded-sm bg-gray-50 w-full py-3 px-5 text-lg text-gray-500 border-[#AAAAAA] border"
        onChange={(e) => {
          onChange(e.target.value);
        }}
        {...props}
      >
        <option value="">{placeholder}</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {multiple && (
        <div className="flex mt-2">
          {selectedValues.map((value, index) => {
            let option = getOptionWithValue(value);
            return (
              <div
                key={index}
                className="rounded-sm mr-2 flex items-center bg-[#F5F8FB] px-2 py-1"
              >
                <p className="font-base text-primary mr-2">{option.label}</p>
                <BsXCircle
                  className="text-primary hover:cursor-pointer"
                  onClick={() => {
                    removeValue(index);
                  }}
                />
              </div>
            );
          })}
        </div>
      )}
      {error && <p className="mt-2 text-sm font-bold text-primary">{error}</p>}
    </div>
  );
}
