import React from "react";
import { useEffect, useRef } from "react";
import LOGO from "../assets/SVG/Footer_logo.svg";
import arrowRight from "../assets/SVG/arrow-right.svg";
import Button from "./Button";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import "../Components/Item.css";
import { Link, useNavigate } from "react-router-dom";
import { getSections } from "../services/CategoryService";
import HomeIcon from "@mui/icons-material/Home";
import MobileSearch from "./MobileSearch";
import useAuth from "../hooks/useAuth";
import { logoutUser } from "../services/AuthService";
const Sidebar = ({ toggleDrawer, setState, state }) => {
  const [expanded, setExpanded] = React.useState(false);
  const [sections, setSections] = React.useState({});
  const [mobileSearch, setMobileSearch] = React.useState(false);
  const navigate = useNavigate();
  const today = new Date();
  const { isLoggedIn, logout } = useAuth();
  const fetchSections = async () => {
    let result = await getSections();
    setSections(result);
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleCategory = (category) => {
    setState({ ...state, ["left"]: false });
    navigate(`/products/${category}/1`);
  };

  const signOut = async () => {
    // toggleDrawer('left', false);
    let response = await logoutUser();
    logout();
    navigate("/");
  };

  useEffect(() => {
    fetchSections();
  }, []);

  return mobileSearch ? (
    <MobileSearch
      setMobileSearch={setMobileSearch}
      toggleDrawer={toggleDrawer}
    ></MobileSearch>
  ) : (
    <div className="overflow-scroll w-full bg-secondary h-screen z-10 text-center p-6">
      <div className="flex justify-between mb-5 items-center">
        <div className="logo">
          <img src={LOGO} className="w-1/4" />
        </div>
        <div onClick={toggleDrawer("left", false)}>
          <CloseRoundedIcon
            fontSize="medium"
            sx={{ color: "#C84027" }}
          ></CloseRoundedIcon>
        </div>
      </div>
      <div className="flex flex-row justify-between mt-5">
        <Link onClick={toggleDrawer("left", false)} to={"/"}>
          <div className="bg-white text-primary font-medium p-2 rounded-sm h-full">
            <HomeIcon sx={{ color: "#495057" }} />
          </div>
        </Link>
        <input
          type="text"
          className="search rounded-sm bg-white text-[#808080] border-0 ring-0 outline-0 block w-[100%] text-md p-2.5 focus:border-2 focus:border-primary focus:ring-0 focus:outline-0 ml-2"
          placeholder="Search for items..."
          onFocus={() => setMobileSearch(true)}
        />
      </div>
      <Link
        onClick={toggleDrawer("left", false)}
        to={"/new/" + today.toLocaleDateString("en-GB").replace(/\//g, "-")}
      >
        <div className="bg-white text-primary font-medium text-sm mt-[10px] p-2 rounded-sm uppercase">
          Daily New
        </div>
      </Link>
      <div className="mt-5">
        {" "}
        {Object.keys(sections).map(
          (key, index) =>
            sections[key]["categories"].length != 0 && (
              <Accordion
                expanded={expanded === "panel" + index}
                onChange={handleChange("panel" + index)}
                children
                sx={{ marginTop: "10px" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div className="uppercase text-primary font-semibold">
                    {key}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="text-left text-sm font-light">
                    <ul>
                      {sections[key]["categories"].map((data, index) => (
                        <div onClick={() => handleCategory(data.category_id)}>
                          <li className="leading-7" key={"category-M" + index}>
                            {data.category_name}
                          </li>
                        </div>
                      ))}
                    </ul>
                  </div>
                </AccordionDetails>
              </Accordion>
            )
        )}
      </div>
      <div className="mt-2">
        {isLoggedIn && (
          <Link to="/wishList">
            <Button label="WISHLIST" bg_color="#C84027" color="white" />{" "}
          </Link>
        )}
      </div>
      <div className="mt-2">
        {isLoggedIn ? (
          <div className="flex justify-between">
            <div className="w-[49%]">
              <Link onClick={toggleDrawer("left", false)} to="/account">
                <Button label="Account" bg_color="white" color="#C84027" />
              </Link>
            </div>
            <div className="w-[49%]" onClick={signOut}>
              <Button label="SIGN OUT" bg_color="#2b2d42" color="white" />
            </div>
          </div>
        ) : (
          <Link onClick={toggleDrawer("left", false)} to="/auth/login">
            {" "}
            <Button label="SIGN IN" bg_color="#C84027" color="white"></Button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
