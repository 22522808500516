import React, { useContext, useEffect, useRef } from 'react'
import SwiperComponent from './SwiperComponent'
import ITEMS from '../Items';
import Item from './Item';
import Category from './Category';
import '../Components/Home.css'
import { Link, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, Parallax, Lazy, EffectFade } from 'swiper';
import { getBestSellers, getDaily, getHomeNewest, search } from '../services/ProductsService';
import { getSections } from '../services/CategoryService';
import { Skeleton } from '@mui/material';
import 'swiper/css/navigation';
const Home = ({ wishList, setWishList }) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [newest, setNewest] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const [best, setBest] = React.useState([]);
    const fetchNewest = async () => {
        setIsLoading(true);
        let newest = await getHomeNewest();
        setIsLoading(false);
        setNewest(newest);

    }
    const skeleton = [
        {},
        {},
        {},
        {}
    ]
    const fetchBest = async () => {
        setIsLoading(true);
        let result = await getBestSellers();
        setIsLoading(false);
        setBest(result);
    }
    const fetchCategories = async () => {
        let categories = await getSections();
        setCategories(categories);
    }


    useEffect(() => {
        fetchNewest();
        fetchBest();
        fetchCategories();
    }, [])

    return (
        <div className=''>
            <SwiperComponent></SwiperComponent>
            <div className='xl:container xl:mx-auto xl:px-4 mx-4'>
                <h1 className='text-left uppercase text-2xl lg:text-3xl underline decoration-4 underline-offset-1 decoration-primary my-4 lg:mt-8 lg:my-4 md:mr-16 !leading-relaxed'>Daily New</h1>
                <div className='block md:flex md:flex-row  overflow-hidden'>
                    {isLoading ?
                        <div className='grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 flex-wrap w-full'>
                            {skeleton.map((data) => (<Skeleton variant="rectangular" sx={{ bgcolor: "#FFF6EB" }} width={'100%'} height={'546px'} animation="wave"></Skeleton>))}
                        </div>
                        : <Swiper
                            className='daily-swiper'
                            modules={[Navigation, Pagination, Scrollbar, A11y, Parallax, Autoplay, Lazy, EffectFade]}
                            loop={false}
                            lazy={true}
                            navigation
                            spaceBetween={16}
                            slidesPerView={'auto'}
                            allowTouchMove={true}
                            effect='slide'
                            autoplay={false}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1.2
                                },
                                540: {
                                    slidesPerView: 2.2
                                },
                                950: {
                                    slidesPerView: 3.3
                                },
                                1536: {
                                    slidesPerView: 4.3
                                }
                            }
                            }
                        >
                            {newest.map((data, index) =>

                                <SwiperSlide key={'newest-' + index}>
                                    <Item item_name={data.product_name} item_price={data.price} thumbnail={data.img_url} currency={data.currency} content={data} wishList={wishList} setWishList={setWishList} img_data={data.img_data} overall_quantity={data.overall_quantity}></Item>
                                </SwiperSlide>

                            )}
                        </Swiper>}

                    {/* </div> */}
                </div>
                <h1 className='text-left uppercase text-2xl lg:text-3xl underline decoration-4 underline-offset-1 decoration-primary mt-6 mb-2'>Best Sellers</h1>
                <div className='grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 flex-wrap w-full mt-4'>
                    {isLoading ? <Skeleton variant="rectangular" sx={{ bgcolor: "#FFF6EB" }} width={'100%'} height={'546px'} animation="wave"></Skeleton> : best.map((data, index) => (
                        <Item key={'best-' + index} item_name={data.product_name} item_price={data.price} thumbnail={data.img_url} currency={data.currency} content={data} wishList={wishList} setWishList={setWishList} ></Item>
                    ))}
                </div>
                <h1 className='text-left uppercase text-2xl lg:text-3xl underline decoration-4 underline-offset-1 decoration-primary mb-4 mt-8 lg:mt-16'>Categories</h1>
                {isLoading ?
                    <div className='grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-2 flex-wrap w-full mb-4'>
                        <Skeleton variant="rectangular" sx={{ bgcolor: "#FFF6EB" }} width={'100%'} height={'50px'} animation="wave"></Skeleton>
                        <Skeleton variant="rectangular" sx={{ bgcolor: "#FFF6EB" }} width={'100%'} height={'50px'} animation="wave"></Skeleton>
                        <Skeleton variant="rectangular" sx={{ bgcolor: "#FFF6EB" }} width={'100%'} height={'50px'} animation="wave"></Skeleton>
                        <Skeleton variant="rectangular" sx={{ bgcolor: "#FFF6EB" }} width={'100%'} height={'50px'} animation="wave"></Skeleton>
                        <Skeleton variant="rectangular" sx={{ bgcolor: "#FFF6EB" }} width={'100%'} height={'50px'} animation="wave"></Skeleton>
                    </div> :
                    Object.keys(categories).map((key, index) => (categories[key]['categories'].length != 0 &&
                        <div key={'section-' + index}>
                            <h4 className='text-primary font-bold uppercase mb-1'>{key}</h4>
                            <div className='grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-2 flex-wrap w-full mb-4'>
                                {categories[key]['categories'].map((data, index) => (
                                    <Link to={`/products/${data.category_id}/1`} key={"subC-" + index}>
                                        <Category category={data.category_name}></Category>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    ))
                }
            </div>


        </div >
    )
}

export default Home