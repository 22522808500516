import React from 'react'
import LOGO from '../assets/SVG/Footer_logo.svg';
import FB from '../assets/SVG/facebook.svg';
import IG from '../assets/SVG/instagram.svg';
import { Link } from 'react-router-dom';
const Footer = () => {
    return (
        <footer className='w-full bg-secondary p-5 md:py-5 md:px-8 lg:p-8 flex flex-col md:flex-row justify-around lg:justify-around items-center'>
            {/* <footer className='w-full bg-secondary p-5 md:py-5 md:px-8 lg:p-8 flex flex-col md:flex-row justify-around lg:justify-around items-center 
        fixed bottom-0
        '> */}
            <div className='col-1 flex flex-col mb-5 md:mb-0 justify-center'>
                <Link to={'/'}>
                    <img src={LOGO} width='85px' />
                </Link>
            </div>
            <div className='col-2  md:flex md:flex-row justify-between flex-col text-sm text-center leading-8'>
                <Link to={'/'}>
                    <p className='uppercase cursor-pointer transition ease-in-out duration-200 text-black hover:text-primary md:mr-16'>Home</p>
                </Link>
                <Link to={'/terms'}>
                    <p className='uppercase cursor-pointer transition ease-in-out duration-200 text-black hover:text-primary md:mr-16'>Terms & Conditions</p>
                </Link>
                <Link to={'/privacy'}>
                    <p className='uppercase cursor-pointer transition ease-in-out duration-200 text-black hover:text-primary'>Privacy Policy</p>
                </Link>
            </div>
            <div className='socials flex flex-row items-center justify-between mt-8 md:mt-0'>
                <a href='https://www.facebook.com/vacostastyle/' className='mr-4'>
                    <img src={FB} className='cursor-pointer'></img>
                </a>
                <a href='https://www.instagram.com/vacostastyleleb/'>
                    <img src={IG} className='cursor-pointer'></img>
                </a>
            </div>

        </footer>
    )
}

export default Footer