import axios from "axios";

// const BASE_URL = "http://127.0.0.1:8000/api";
const BASE_URL = "https://api-vacosta-6sf6b.ondigitalocean.app/api";

const addShippingAddress = async (shipping) => {
  const token = localStorage.getItem("vacosta_user_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  const { addressLine, city, country, postalCode, district } = shipping;
  const formData = new FormData();
  formData.append("address_line1", addressLine);
  formData.append("city", city);
  formData.append("country", country);
  formData.append("district", district);
  formData.append("postal_code", postalCode);
  const response = await axios.post(
    `${BASE_URL}/addShippingAddress`,
    formData,
    {
      headers: { Accept: "application/json" },
    }
  );

  return response.data;
};

const getShippingAddresses = async () => {
  const token = localStorage.getItem("vacosta_user_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const response = await axios.get(`${BASE_URL}/getShippingAddresses`, {
      headers: { Accept: "application/json" },
    });

    return response.data;
  } catch (error) {
    // console.log(error);
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try again.");
    } else {
      console.warn(error);
      throw Error("Something wrong happened please try again.");
    }
    // console.log(error);
  }
};
const deleteShippingAddress = async (shipping_id) => {
  const token = localStorage.getItem("vacosta_user_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const formData = new FormData();
    formData.append("shipping", shipping_id);
    const response = await axios.post(
      `${BASE_URL}/deleteShippingAddress`,
      formData,
      {
        headers: { Accept: "application/json" },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try again.");
    } else {
      console.warn(error);
      throw Error("Something wrong happened please try again.");
    }
  }
};
const order = async (cart, shipping_id, payment_id) => {
  const token = localStorage.getItem("vacosta_user_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const formData = new FormData();
    formData.append("shipping_id", shipping_id);
    formData.append("cart", JSON.stringify(cart));
    formData.append("payment", payment_id);
    const response = await axios.post(`${BASE_URL}/order`, formData, {
      headers: { Accept: "application/json" },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try again.");
    } else {
      throw Error("Something wrong happened please try again.");
    }
    // console.log(error);
  }
};

const addToWishlist = async (product_id) => {
  const token = localStorage.getItem("vacosta_user_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const formData = new FormData();
    formData.append("product", product_id);
    const response = await axios.post(`${BASE_URL}/addToWishlist`, formData, {
      headers: { Accept: "application/json" },
    });

    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try again.");
    } else {
      throw Error("Something wrong happened please try again.");
    }
    // console.log(error);
  }
};

const deleteWishlistItem = async (product_id) => {
  const token = localStorage.getItem("vacosta_user_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const formData = new FormData();
    formData.append("product", product_id);
    const response = await axios.post(
      `${BASE_URL}/deleteWishlistItem`,
      formData,
      {
        headers: { Accept: "application/json" },
      }
    );

    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try again.");
    } else {
      throw Error("Something wrong happened please try again.");
    }
    // console.log(error);
  }
};
const getWishlist = async () => {
  const token = localStorage.getItem("vacosta_user_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const response = await axios.get(`${BASE_URL}/getWishlist`, {
      headers: { Accept: "application/json" },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try again.");
    } else {
      throw Error("Something wrong happened please try again.");
    }
  }
};

const getOrdersHistory = async (page) => {
  const token = localStorage.getItem("vacosta_user_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const response = await axios.get(
      `${BASE_URL}/getOrderHistory?page=${page}`,
      {
        headers: { Accept: "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try again.");
    } else {
      throw Error("Something wrong happened please try again.");
    }
  }
};

const getPaymentTypes = async () => {
  const token = localStorage.getItem("vacosta_user_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const response = await axios.get(`${BASE_URL}/getPaymentTypes`, {
      headers: { Accept: "application/json" },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try again.");
    } else {
      throw Error("Something wrong happened please try again.");
    }
  }
};

export {
  addShippingAddress,
  getShippingAddresses,
  order,
  addToWishlist,
  getWishlist,
  deleteWishlistItem,
  getPaymentTypes,
  getOrdersHistory,
  deleteShippingAddress,
};
