import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams, useParams, Link } from "react-router-dom";
import HeaderLogo from "../assets/logos/Header_Logo.svg";
import TransitionAlert from "../Components/common/SnackbarAlert";
import LoadingPage from "../Components/LoadingPage";
import { verifyAccount } from "../services/AuthService";

export default function AccountVerificationPage() {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  // const [isVerifying, setIsVerifying] = useState(true);
  const [accountVerificationResult, setAccountVerificationResult] = useState(
    {}
  );
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();

  const handleVerifyAccount = async () => {
    setIsLoading(true);
    setShowAlert(false);
    const email = searchParams.get("email");
    const verificationToken = searchParams.get("verification_token");
    try {
      if (email && verificationToken) {
        let result = await verifyAccount(email, verificationToken);
        setAccountVerificationResult({
          messageType: "success",
          message: result.message,
          title: "Success!",
        });
        setShowAlert(true);
        navigate('/auth/login', { replace: true })
      } else {
        throw Error("An email and valid verification token is required.");
      }
    } catch (e) {
      setAccountVerificationResult({
        messageType: "error",
        message: e.message,
        title: "Something went wrong!",
      });
      setShowAlert(true);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    handleVerifyAccount();
  }, [])

  const closeAlert = () => {
    setShowAlert(false);
  };

  return (
    <div className="flex bg-backgroundColor">
      <div className="flex flex-1 flex-col items-center">
        <img
          className="w-52 h-auto mt-4"
          src={HeaderLogo}
          alt="Vacosta Style Logo"
        />
        <div className="mt-20 sm:w-9/12 md:w-5/12">
          <h1 className="text-primary text-4xl font-semibold border-b-4 border-b-gray-50 pb-4 uppercase">
            Account Verification
          </h1>
          <TransitionAlert
            open={showAlert}
            message={accountVerificationResult.message}
            severity={accountVerificationResult.messageType}
            title={accountVerificationResult.title}
            handleClose={closeAlert}
          />


          {
            isLoading && <LoadingPage></LoadingPage>
          }

        </div>
      </div>
      <img
        src={require("../assets/images/registration-picture.jpg")}
        className="w-0 lg:w-auto h-screen"
        alt=""
      />
    </div>
  );
}
