import React from 'react'
import '../Components/loader.css'
const Loader = () => {
    return (
        <div className="loader-container my-6 text-center mx-auto">
            <div className="shape shape-1"></div>
            <div className="shape shape-2"></div>
            <div className="shape shape-3"></div>
            <div className="shape shape-4"></div>
        </div>

    )
}

export default Loader