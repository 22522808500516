import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import AdminSidebar from '../Components/common/AdminSidebar'
import CustomButton from "../Components/Button";
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import { getOrderHeaders, getOrderDetails, exportOrders } from '../services/AdminService';
import OrderDetails from '../Components/OrderDetails';
import { Pagination } from '@mui/material';
import usePagination from '../Components/usePagination';
import TransitionAlert from "../Components/common/SnackbarAlert";

const AdminOrders = () => {
    const [headers, setHeaders] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [showAlert, setShowAlert] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [result, setResult] = React.useState({})
    const handleHeaders = async () => {
        let response = await getOrderHeaders(page);
        setHeaders(response);
    }
    const handleExport = async () => {
        setIsLoading(true);
        setShowAlert(false);

        try {
            let result = await exportOrders();
            setResult({
                messageType: "success",
                message: result.message,
                title: "Success!",
            });
            setShowAlert(true);
            handleHeaders();
        } catch (e) {
            setResult({
                messageType: "error",
                message: e.message,
                title: "Something went wrong!",
            });
            setShowAlert(true);
        }
        setIsLoading(false);
    };
    const closeAlert = () => {
        setShowAlert(false);
    };
    const PAGE_LENGTH = 24;
    let count = 1;
    let data = [];

    if (headers?.length != 0) {
        count = Math.ceil(headers.total / PAGE_LENGTH);
        data = headers.data
    }

    const HEADERS = usePagination(data, PAGE_LENGTH);

    const handlePage = (e, p) => {
        setPage(p);
        HEADERS.jump(p);
    };
    useEffect(() => {
        handleHeaders();
    }, [])
    return (
        <div className="flex flex-col lg:flex-row w-[95%] lg:w-[98%] mx-auto">
            <div className="w-full lg:w-1/4">
                <AdminSidebar />
            </div>
            <TransitionAlert
                open={showAlert}
                message={result.message}
                severity={result.messageType}
                title={result.title}
                handleClose={closeAlert}
            />
            <div className="w-full lg:w-3/4 lg:ml-6">
                <div className="w-full mx-auto mt-5">
                    <div className="flex justify-between items-start">
                        <h1 className="text-left uppercase text-2xl underline decoration-4 decoration-primary">
                            Orders History
                        </h1>
                        <div className='' onClick={handleExport}>
                            <CustomButton bg_color="#2d6a4f" color='white' label={'Export to Excel'} >  </CustomButton>
                        </div>
                    </div>
                    <List
                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"

                    >
                        {HEADERS.currentData().map((data) => (
                            <OrderDetails handleHeaders={handleHeaders} data={data} />
                        ))}
                    </List>
                    <Pagination count={count} page={page} onChange={handlePage} variant="outlined" shape="rounded" className='mb-4 justify-center' />
                </div>
            </div >
        </div >

    )
}

export default AdminOrders