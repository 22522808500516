import React, { useEffect, useState } from "react";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { deleteVariant, deleteProduct } from "../services/AdminService";
import SpeedDial from "@mui/material/SpeedDial";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import styles from "../Components/admin.css";
import TransitionAlert from "../Components/common/SnackbarAlert";
import DeleteIcon from "@mui/icons-material/Delete";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CompareIcon from "@mui/icons-material/Compare";
import {
  ThemeProvider,
  createTheme,
  FormControl,
  FormLabel,
  FormControlLabel,
  Switch,
} from "@mui/material";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { Link, useNavigate } from "react-router-dom";
import { FlipCameraAndroid } from "@mui/icons-material";
const ItemAdmin = ({
  item_name,
  currency,
  item_price,
  content,
  thumbnail,
  img_data,
  img_url,
  quantity,
  colorName,
  sizeName,
  variant_id,
  product_id,
  is_available,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [productCreationResult, setProductCreationResult] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [inputErrors, setInputErrors] = useState({});
  const handleDelete = async () => {
    setIsLoading(true);
    setShowAlert(false);
    setInputErrors({});
    try {
      let result = await deleteVariant(variant_id);

      setProductCreationResult({
        messageType: "success",
        message: result.message,
        title: "Success!",
      });

      setInputErrors({});
      setShowAlert(true);
      refresh();
    } catch (e) {
      setProductCreationResult({
        messageType: "error",
        message: e.message,
        title: "Something went wrong!",
      });
      setShowAlert(true);
    }
    setIsLoading(false);
  };
  const handleDeleteProduct = async () => {
    setIsLoading(true);
    setShowAlert(false);
    setInputErrors({});
    try {
      let result = await deleteProduct(product_id);
      setProductCreationResult({
        messageType: "success",
        message: result.message,
        title: "Success!",
      });

      setInputErrors({});
      setShowAlert(true);
      refresh();
    } catch (e) {
      setProductCreationResult({
        messageType: "error",
        message: e.message,
        title: "Something went wrong!",
      });
      setShowAlert(true);
    }
    setIsLoading(false);
  };
  let navigate = useNavigate();
  const routeChange = async (product_id, variant_id, value) => {
    if (value == 1) {
      let path = `/admin/editProduct/${product_id}`;
      navigate(path);
    } else if (value == 2) {
      let path = `/admin/editImages/${variant_id}`;
      navigate(path);
    } else if (value == 3) {
      let path = `/admin/addVariant/${variant_id}`;
      navigate(path);
    } else if (value == 4) {
      let path = `/admin/editThumbnail/${product_id}`;
      navigate(path);
    } else if (value === 5) {
      handleDelete();
    } else if (value === 6) {
      handleDeleteProduct();
    }
  };

  const [qty, setQty] = React.useState(quantity);
  const [direction, setDirection] = React.useState("down");
  const [hidden, setHidden] = React.useState(false);
  // const updateQuantity = async (variant_id, quantity) => {
  //     let qty = await insertQuantity(variant_id, quantity);
  //     setQty(qty);
  // }
  const theme = createTheme({
    palette: {
      primary: {
        main: "#C84027",
      },
    },
  });
  const closeAlert = () => {
    setShowAlert(false);
  };
  // const [checked, setChecked] = React.useState(in_stock);

  // const handleSwitch = (event) => {
  //   setChecked(event.target.checked);
  // };
  // useEffect(() => {
  //   setChecked(in_stock);
  // }, []);
  const actions = [
    { icon: <ChangeCircleIcon />, name: "Edit Product", value: 1 },
    { icon: <FlipCameraAndroid />, name: "Edit Images", value: 2 },
    { icon: <CompareIcon />, name: "Add Variants", value: 3 },
    { icon: <AccountBoxIcon />, name: "Change Thumbnail", value: 4 },
    {
      icon: <DeleteIcon color="error"></DeleteIcon>,
      name: "Delete Variant",
      value: 5,
    },
    {
      icon: <DeleteForeverIcon color="error"></DeleteForeverIcon>,
      name: "Delete Product",
      value: 6,
    },
  ];
  const refresh = () => window.location.reload(true);
  return (
    <div
      className="item bg-white shadow-sm rounded-sm w-[100%] h-[450px] sm:h-[550px] md:h-[550px] relative transition duration-200 ease-in-out border-0"
      style={{ backgroundImage: `url(${thumbnail})` }}
    >
      <TransitionAlert
        open={showAlert}
        message={productCreationResult.message}
        severity={productCreationResult.messageType}
        title={productCreationResult.title}
        handleClose={closeAlert}
      />
      {/* <div className='item bg-white shadow-sm rounded-sm w-[100%] h-[450px] sm:h-[550px] md:h-[450px] relative transition duration-200 ease-in-out border-0' style={{ backgroundImage: `url(/images/${thumbnail})` }}> */}
      <div className="absolute inset-x-0 top-2 flex justify-between items-start">
        <div className="flex">
          <div className="bg-primary text-white rounded-sm uppercase text-xs p-2 mx-2">
            {colorName}
          </div>
          <div className="bg-primary text-white rounded-sm uppercase text-xs p-2">
            {sizeName}
          </div>
        </div>
        <div className="mr-2">
          <ThemeProvider theme={theme}>
            <SpeedDial
              ariaLabel="SpeedDial playground example"
              hidden={hidden}
              icon={<ModeEditOutlineIcon />}
              direction={direction}
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  onClick={() =>
                    routeChange(product_id, variant_id, action.value)
                  }
                />
              ))}
            </SpeedDial>
          </ThemeProvider>
        </div>
      </div>
      <div className="absolute right-2 top-2"></div>
      {/* <img src={props.thumbnail} className='w-full lg:h-[350px] xl:h-[420px]'></img> */}
      {/* <img src={woman} className='w-full lg:h-[420px]'></img> */}
      <div className="bg-secondary p-3 px-4 md:p-3 flex flex-row items-center justify-between absolute inset-x-0 bottom-0">
        <div className="">
          <p className="text-sm md:text-sm text-ellipsis overflow-hidden max-w-[200px] sm:max-w-[150px] md:max-w-[150px] lg:max-w-[200px] xl:max-w-[180px] whitespace-nowrap capitalize">
            {item_name}
          </p>
          <p className="font-bold text-md md:text-md">
            {"$ " + (Math.round(item_price * 100) / 100).toFixed(2)}
          </p>
        </div>
        {/* <FormControl component="fieldset">
          <FormLabel component="legend">In Stock</FormLabel>
          <Switch
            checked={checked}
            onChange={handleSwitch}
            inputProps={{ "aria-label": "controlled" }}
          />
        </FormControl> */}
        <div className="text-green-600 text-xs font-light uppercase">
          {is_available ? "IN STOCK" : ""}
        </div>
        {/* <div className='flex items-center text-right absolute inset-x-0 bottom-0'> */}
        {/* <button type='button' className='bg-white rounded-sm p-3 mr-1 hover:brightness-95 active:brightness-90 cursor-pointer transition duration-200 ease-in-out'>
                        <img src={favorite} className='w-[20px] sm:w-[25px]'></img>
                    </button> */}
        {/* <div className='mr-4 text-lg font-semibold text-primary'>
                        {quantity}
                    </div> */}
        {/* <div className='w-full qty'>
                        <EasyEdit
                            type={Types.TEXT}
                            value={quantity.toString()}
                            onSave={(val) => updateQuantity(variant_id, val)}
                        />
                    </div> */}
        {/* <button type='button' className='bg-white rounded-sm p-3 hover:brightness-95 active:brightness-90 cursor-pointer transition duration-200 ease-in-out' >
                        <ModeEditOutlineIcon></ModeEditOutlineIcon>
                    </button> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default ItemAdmin;
