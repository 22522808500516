import React from 'react'

const Category = ({ category, page }) => {
    return (page != 2 ?
        <div className='bg-[#f8f8f8] rounded-sm uppercase text-[#374957] p-4 font-semibold text-center hover:bg-primary hover:text-white transition duration-200 ease-in-out cursor-pointer text-sm'>
            <p>{category}</p>
        </div > :
        <div className='bg-[#f8f8f8] rounded-sm uppercase text-[#374957] p-3 font-semibold text-center hover:bg-primary hover:text-white transition duration-200 ease-in-out cursor-pointer text-xs' >
            <p className='line-clamp-1'>{category}</p>
        </div >
    )
}

export default Category