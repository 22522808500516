import React, { createContext, useReducer, useEffect } from "react";
import { cartReducer, ADD_ITEM_TO_CART, REMOVE_ITEM_FROM_CART, DELETE_CART, BALANCE_CART, DELETE_PRODUCT } from "./AppReducer";

const initialState = {
    cart: localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [],
    // addProductToCart: product => { },
    // removeProductFromCart: variant_id => { },
    // balanceCart: (variant_id, quantity) => { },
    // clearCart: () => { }
}

export const GlobalContext = createContext(initialState);

export const GlobalProvider = props => {
    const [state, dispatch] = useReducer(cartReducer, initialState)
    useEffect(() => {
        localStorage.setItem('cart', JSON.stringify(state.cart))
    }, [state]);

    const addProductToCart = (product, quantity) => {
        dispatch({ type: ADD_ITEM_TO_CART, product: product, quantity: quantity })
    }
    const removeProductFromCart = variant_id => {
        dispatch({ type: REMOVE_ITEM_FROM_CART, variant_id: variant_id })
    }
    const deleteProduct = variant_id => {
        dispatch({ type: DELETE_PRODUCT, variant_id: variant_id })
    }
    const clearCart = () => {
        dispatch({ type: DELETE_CART })
    }
    // const balanceCart = (variant_id, quantity) => {
    //     dispatch({ type: BALANCE_CART, variant_id: variant_id, quantity: quantity })
    // }

    return (
        <GlobalContext.Provider value={{ cart: state.cart, addProductToCart, removeProductFromCart, clearCart, deleteProduct }}>
            {props.children}
        </GlobalContext.Provider>
    )
}

