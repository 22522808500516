import React, { useEffect } from "react";
import Header from "../Header";
import SideCart from "../SideCart";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Footer from "../Footer";
import Sidebar from "../Sidebar";
import BottomBar from "../BottomBar";
import { Drawer } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLocation } from "react-router-dom";
import { Fab } from "@mui/material";
import { WhatsApp } from "@mui/icons-material";
export default function PageWrapper({
  children,
  toggleDrawer,
  state,
  setState,
}) {
  const [visible, setVisible] = React.useState("block");
  const location = useLocation();

  useEffect(() => {
    if (location.pathname == "/orders") {
      setVisible("hidden");
    } else {
      setVisible("block");
    }
  }, []);

  return (
    <div className="flex min-h-screen flex-col">
      <Header toggleDrawer={toggleDrawer} visibility={visible} />
      <Drawer
        anchor="right"
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        PaperProps={{
          // style: { width: { xs: '100%' } }
          sx: {
            width: {
              xs: "100%",
              sm: "100%",
              md: "40%",
              lg: "30%",
              xl: "20%",
            },
          },
        }}
      >
        <SideCart
          toggleDrawer={toggleDrawer}
          state={state}
          setState={setState}
        />
      </Drawer>
      <Drawer
        anchor="left"
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
        // onOpen={toggleDrawer('left', true)}
        PaperProps={{
          sx: { width: "100%" },
        }}
      >
        <Sidebar
          toggleDrawer={toggleDrawer}
          setState={setState}
          state={state}
        />
      </Drawer>
      <a
        target={"_blank"}
        href="https://api.whatsapp.com/send?phone=96181285872"
      >
        <div className="fixed bottom-4 right-4 z-10">
          <Fab
            size="small"
            sx={{ backgroundColor: "#25D366" }}
            aria-label="add"
          >
            <WhatsApp sx={{ color: "white" }}></WhatsApp>
          </Fab>
        </div>
      </a>
      <div className="grow mb-3">{children}</div>
      <Footer />
    </div>
  );
}
