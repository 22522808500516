import React from 'react'
import { Link } from 'react-router-dom'


const SearchResults = ({ searchResults, open, setOpen, setQuery, setSection, section, preventLoad }) => {
    const handleClick = () => {
        setOpen(false);
        setQuery('');
    }
    return (
        open &&
        <div className='shadow-sm rounded-md bg-white absolute w-full mt-1 z-20 max-h-screen overflow-auto'>
            {searchResults.map((data) => (
                <Link onClick={handleClick} to={`/product/${data.product_id}`}>
                    <div className='hover:bg-slate-50 transition duration-200 ease-in-out cursor-pointer p-3 flex border-b border-slate-200'>
                        <img className='w-[30%]' src={`${data.img_url}`}></img>
                        <div className='flex flex-col ml-3 justify-between'>
                            <div className=''>
                                <p className='text-xs font-medium uppercase text-primary line-clamp-2'>{data.product_name}</p>
                                <p className='text-[10px] font-light uppercase'>{data.category_name}</p>
                            </div>
                            <div className=''>
                                <p className='text-gray-800 text-sm'>{'$ ' + (Math.round(data.price * 100) / 100).toFixed(2)}</p>
                            </div>
                        </div>
                    </div>
                </Link>
            ))

            }
            {open && !preventLoad && <p onClick={() => setSection(section + 1)} className='uppercase text-sm text-slate-400 p-2 cursor-pointer text-center'>Load More</p>}
        </div>

    )
}

export default SearchResults