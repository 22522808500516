import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";

const useAuth = () => {
  const { isLoggedIn, token, login, logout, isLoadingProfile, admin } = useContext(AuthContext);

  return {
    isLoggedIn,
    token,
    admin,
    login,
    logout,
    isLoadingProfile

  };
};

export default useAuth;
