import { Pagination } from '@mui/material';
import React, { useEffect } from 'react'
import { getOrdersHistory } from '../services/OrdersService';
import usePagination from './usePagination';

const OrderHistory = () => {
    const [page, setPage] = React.useState(1);
    const [history, setHistory] = React.useState([]);
    const fetchHistory = async () => {
        let response = await getOrdersHistory(page);
        setHistory(response.order_history);
    }
    const PAGE_LENGTH = 24;
    let count = 1;
    let data = [];

    if (history?.length != 0) {
        count = Math.ceil(history.total / PAGE_LENGTH);
        data = history.data;
    }

    const HISTORY = usePagination(data, PAGE_LENGTH);

    const handlePage = (e, p) => {
        setPage(p);
        HISTORY.jump(p);
    };
    useEffect(() => {
        fetchHistory();
    }, [])
    return (
        <div className='xl:container xl:mx-auto mx-4 mt-4 xl:px-4'>
            <h1 className='text-2xl text-primary mt-2 lg:mt-0 mb-3 font-semibold uppercase'>Orders History</h1>
            <div className='grid 2xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-2'>
                {HISTORY.currentData().map((data, index) => (
                    <div key={'order-' + index} className='p-4 flex bg-secondary rounded-sm '>
                        <img src={`${data.thumbnail_url}`} className="w-[100px] h-[132px]"></img>
                        <div className='flex flex-col justify-between w-full ml-4'>
                            <div>
                                <p className='capitalize line-clamp-2'>{data.product_name}</p>
                                <p className='font-light text-sm'>{data.product_code}</p>
                            </div>
                            <div className='flex justify-between items-end'>
                                <div className='flex-col'>
                                    <p className='text-sm text-primary font-semibold uppercase'>Qty</p>
                                    <p className='text-md font-normal'>{data.quantity}</p>
                                </div>
                                <div className='flex-col'>
                                    <p className='text-sm text-primary font-semibold uppercase'>Amount</p>
                                    <p>{'$ ' + (Math.round(data.total_amount * 100) / 100).toFixed(2)}</p>
                                </div>
                            </div>
                        </div>
                    </div>))}
            </div>
            <Pagination count={count} page={page} onChange={handlePage} variant="outlined" shape="rounded" className='mb-4 mt-4 justify-center' />
        </div>
    )
}

export default OrderHistory