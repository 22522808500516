import React from 'react'
import Button from './Button'
import InputComponent from './InputComponent'
import { addShippingAddress } from '../services/OrdersService'
import { addressSchema } from '../validations/UserValidation'
import { getYupValidationErrors } from '../validations/ValidationHelper'

const AddAddress = ({ setShowAddAddress, getShipping }) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [city, setCity] = React.useState('');
    const [district, setDistrict] = React.useState('');
    const [addressLine, setAddressLine] = React.useState('');
    const [postalCode, setPostalCode] = React.useState('');
    const [result, setResult] = React.useState({})
    const [error, setError] = React.useState({})
    const resetForm = () => {
        setCity('');
        setAddressLine('');
        setDistrict('');
        setPostalCode('');
        setShowAddAddress(false);
    }
    const handleAddShipping = async () => {
        try {
            await addressSchema.validate(
                { city, addressLine, district, postalCode },
                { abortEarly: false }
            );
            setIsLoading(true);
            let result = await addShippingAddress({ city, district, addressLine, postalCode });
            setResult({
                messageType: "success",
                message: result.message,
                title: "Success!",
            });
            setError({});
            setShowAlert(true);
            getShipping();
            resetForm();
        }
        catch (e) {
            if (e.name === "ValidationError") {
                let errors = getYupValidationErrors(e);
                setError(errors);
            } else {
                setResult({
                    messageType: "error",
                    message: e.message,
                    title: "Something went wrong!",
                });

            }
            setShowAlert(true);
        }
        setIsLoading(false);
    }

    return (
        <div className='w-full flex flex-col justify-between h-full'>
            <div className=''>
                <h1 className='text-2xl text-primary mb-1 font-semibold uppercase'>Add Shipping Address</h1>
                <div className='mt-2 mb-4'>
                    <InputComponent placeholder={'City'} label={'City'} id={'city'} value={city} setValue={setCity} error={error.city}></InputComponent>
                </div>
                <div className='mt-2 mb-4'>
                    <InputComponent placeholder={'District'} label={'District'} id={'district'} value={district} setValue={setDistrict} error={error.district}></InputComponent>
                </div>
                <div className='mb-4'>
                    <InputComponent placeholder={'Address Line'} label={'Address Line'} id={'addressLine'} value={addressLine} setValue={setAddressLine} error={error.addressLine}></InputComponent>
                </div>
                <div className='mb-4'>
                    <InputComponent placeholder={'Postal Code'} label={'Postal Code'} id={'postalCode'} value={postalCode} setValue={setPostalCode} error={error.postalCode}></InputComponent>
                </div>
            </div>
            <div className='flex justify-between'>
                <div className='w-[49%]' onClick={() => setShowAddAddress(false)}>
                    <Button label={'Discard'} color={'#C84027'} bg_color={'white'}></Button>
                </div>
                <div className='w-[49%]' onClick={() => handleAddShipping()}>
                    <Button label={'Add'} color={'white'} bg_color={'#C84027'}></Button>
                </div>
            </div>
        </div >
    )
}

export default AddAddress