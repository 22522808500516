import React from "react";
import "./product.css";
const RadioButton = ({ name, value, setSelected, disabled, bg_color }) => {
  return (
    <div className="radio">
      <label
        htmlFor={name}
        className={`inline-block cursor-pointer border border-slate-300 rounded-sm p-2 text-center mr-2 transition duration-200 hover:bg-slate-300 ${bg_color}`}
      >
        {name}
      </label>
      <input
        type="radio"
        name={name}
        id={name}
        value={value}
        className="hidden"
        disabled={disabled}
        onClick={setSelected}
      />
    </div>
  );
};

export default RadioButton;
