import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  Parallax,
  Lazy,
  EffectFade,
} from "swiper";
import "swiper/css";
import "swiper/css/bundle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getBanners } from "../services/AdminService";

const SwiperComponent = () => {
  const matches = useMediaQuery("(min-width:768px)");
  const [banners, setBanners] = React.useState([]);
  const handleBanners = async () => {
    const response = await getBanners();
    setBanners(response);
  };
  useEffect(() => {
    handleBanners();
  }, []);
  return (
    <Swiper
      modules={[
        Navigation,
        Pagination,
        Scrollbar,
        A11y,
        Parallax,
        Autoplay,
        Lazy,
        EffectFade,
      ]}
      loop={true}
      lazy={false}
      spaceBetween={50}
      slidesPerView={1}
      allowTouchMove={false}
      parallax
      effect="fade"
      autoplay={{
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      }}
    >
      {banners.map((data, index) => (
        <SwiperSlide key={"banner-" + index}>
          <div
            className="flex flex-col bg-secondary h-[900px] text-left p-8 sm:p-16"
            style={
              matches
                ? {
                    backgroundImage: `url(${data.img_url})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }
                : {
                    backgroundImage: `url(${data.img_mobile_url})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }
            }
          >
            <h1
              className={`text-3xl font-bold uppercase mb-6`}
              style={{ color: `${data.color}` }}
            >
              {data.title}
            </h1>
            <p
              className={`text-xl sm:w-1/2 w-full`}
              style={{ color: `${data.color}` }}
            >
              {data.text}
            </p>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SwiperComponent;
