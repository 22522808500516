import React, { useEffect } from "react";
import AdminSidebar from "../Components/common/AdminSidebar";
import FormTextInput from "../Components/common/FormTextInput";
import { default as ButtonCustom } from "../Components/Button";
import { Link, useNavigate } from "react-router-dom";
import { Online } from "react-detect-offline";
import { Offline } from "react-detect-offline";
import { addBanner } from "../services/AdminService";
import { bannerSchema } from "../validations/AdminValidation";
import { getYupValidationErrors } from "../validations/ValidationHelper";
import TransitionAlert from "../Components/common/SnackbarAlert";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const AddBanner = () => {
  const [title, setTitle] = React.useState("");
  const [text, setText] = React.useState("");
  const [order, setOrder] = React.useState(0);
  const [desktopIMG, setDesktopIMG] = React.useState(undefined);
  const [mobileIMG, setMobileIMG] = React.useState(undefined);
  const [bannerCreationResult, setBannerCreationResult] = React.useState({});
  const [showAlert, setShowAlert] = React.useState(false);
  const [inputErrors, setInputErrors] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [color, setColor] = React.useState("#000000");
  const navigate = useNavigate();
  const handleUploadDesktop = (event) => {
    setDesktopIMG(event.target.files[0]);
  };
  const handleUploadMobile = (event) => {
    setMobileIMG(event.target.files[0]);
  };
  const handleColor = (event) => {
    setColor(event.target.value);
  };
  const handleAddBanner = async () => {
    setIsLoading(true);
    setShowAlert(false);
    setInputErrors({});

    try {
      await bannerSchema.validate({ order }, { abortEarly: false });
      let result = await addBanner({
        text,
        title,
        desktopIMG,
        mobileIMG,
        order,
        color,
      });
      setBannerCreationResult({
        messageType: "success",
        message: result.message,
        title: "Success!",
      });
      setInputErrors({});
      setShowAlert(true);
      navigate("/admin/banner");
    } catch (e) {
      if (e.name === "ValidationError") {
        console.log(e);
        let errors = getYupValidationErrors(e);
        setInputErrors(errors);
      } else {
        setBannerCreationResult({
          messageType: "error",
          message: e.message,
          title: "Something went wrong!",
        });
        setShowAlert(true);
      }
    }
    setIsLoading(false);
  };
  const closeAlert = () => {
    setShowAlert(false);
  };
  return (
    <div className="flex flex-col lg:flex-row w-[95%] lg:w-[98%] mx-auto">
      <div className="w-full lg:w-1/4">
        <AdminSidebar />
      </div>
      <TransitionAlert
        open={showAlert}
        message={bannerCreationResult.message}
        severity={bannerCreationResult.messageType}
        title={bannerCreationResult.title}
        handleClose={closeAlert}
      />
      <div className="w-full lg:w-3/4 lg:ml-6">
        <div className="w-full mx-auto mt-5">
          <div className="flex justify-between items-start">
            <h1 className="text-left uppercase text-2xl underline decoration-4 decoration-primary">
              Add Banner
            </h1>
          </div>
          <FormTextInput
            label="Title"
            placeholder="Title..."
            value={title}
            onChange={setTitle}
            error={inputErrors.title}
          />
          <FormTextInput
            label="Text"
            placeholder="Text..."
            value={text}
            onChange={setText}
            error={inputErrors.text}
          />
          <div className="w-[25%]">
            <FormTextInput
              label="Order"
              placeholder="Order"
              value={order}
              onChange={setOrder}
              type={"number"}
              error={inputErrors.order}
            />
          </div>
          <div className="w-[25%] mt-2 lg:mt-5">
            <p className="block mb-1 text-lg text-formLabel font-bold">
              Text Color
            </p>
            <RadioGroup value={color} onChange={handleColor}>
              <FormControlLabel
                value="#000000"
                control={<Radio />}
                label="Black"
              />
              <FormControlLabel
                value="#FFFFFF"
                control={<Radio />}
                label="White"
              />
            </RadioGroup>
          </div>
          <div className="flex flex-col mt-5">
            <p className="uppercase font-semibold">upload desktop banner:</p>
            <label htmlFor="desktop" className="mt-1">
              <input
                accept="image/*"
                id="desktop"
                type="file"
                onChange={handleUploadDesktop}
              />
            </label>
            <p className="mt-4 uppercase font-semibold">
              upload mobile banner:
            </p>
            <label htmlFor="mobile" className="mt-1">
              <input
                accept="image/*"
                id="mobile"
                type="file"
                onChange={handleUploadMobile}
              />
            </label>
          </div>
        </div>
        <div className="flex justify-end my-5">
          <div className="md:mr-2">
            <Link to={"/admin/banner"}>
              <ButtonCustom
                bg_color="#f8f8f8"
                label="discard"
                color="gray"
              ></ButtonCustom>
            </Link>
          </div>
          <div onClick={handleAddBanner}>
            <Online>
              <ButtonCustom
                bg_color="#C84027"
                label={"Save Changes"}
                color="white"
              ></ButtonCustom>
            </Online>
            <Offline>
              <ButtonCustom
                bg_color="#C84027"
                label={"No Internet"}
                color="white"
                disabled={true}
              ></ButtonCustom>
            </Offline>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBanner;
