import { FormControlLabel, IconButton, Radio, RadioGroup } from '@mui/material';
import React, { useContext, useEffect } from 'react'
import { GlobalContext } from '../context/GlobalContext';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Button from './Button';
import CachedIcon from '@mui/icons-material/Cached';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { deleteShippingAddress, getPaymentTypes, getShippingAddresses, order } from '../services/OrdersService';
import { getQuantityByID, calculateCartPrice } from '../services/ProductsService';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AddAddress from './AddAddress';
import LoadingPage from './LoadingPage';
import EmptyCart from './EmptyCart';
import SnackbarAlert from './common/SnackbarAlert';
import { orderSchema } from '../validations/UserValidation';
import { getYupValidationErrors } from '../validations/ValidationHelper';
import OrderConfirmation from './OrderConfirmation';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
const Orders = ({ toggleDrawer }) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [disabled, setDisabled] = React.useState(false);
    // const [disabledOrder, setDisabledOrdered] = React.useState(false);
    const [shipping, setShipping] = React.useState([]);
    const [showAddAddress, setShowAddAddress] = React.useState(false);
    const [selectedShipping, setSelectedShipping] = React.useState(0);
    const [cartPrice, setCartPrice] = React.useState(0);
    const [showAlert, setShowAlert] = React.useState(false);
    const [result, setResult] = React.useState({});
    const [error, setError] = React.useState({});
    const [editMode, setEditMode] = React.useState(false);
    const [confirmed, setConfirmed] = React.useState(false);
    const [orderDetails, setOrderDetails] = React.useState([]);
    const [orderAmount, setOrderAmount] = React.useState(0);
    const [paymentTypes, setPaymentTypes] = React.useState([]);
    const [paymentValue, setPaymentValue] = React.useState(1);

    const { removeProductFromCart, cart, clearCart, addProductToCart, deleteProduct } = useContext(GlobalContext);
    const navigate = useNavigate();

    const toggleEditMode = async () => {
        setEditMode(!editMode);
    }
    const handleDelete = async (shipping_id) => {
        try {
            setIsLoading(true);
            let result = await deleteShippingAddress(shipping_id);
            setResult({
                messageType: "success",
                message: result.message,
                title: "Success!",
            })
            setError({});
            setIsLoading(false);
            setShowAlert(true);
            getShipping();
            setEditMode(false);
        } catch (e) {
            setResult({
                messageType: "error",
                message: e.message,
                title: "Something went wrong!",
            });
            setShowAlert(true);
        }

        setIsLoading(false);
    }
    const handleOrder = async (cart, shipping_id, payment) => {
        try {
            await orderSchema.validate(
                { cart },
                { abortEarly: false }
            );
            setIsLoading(true);
            let result = await order(cart, shipping_id, payment);
            setOrderDetails(result.order_details);
            setOrderAmount(result.order_amount);
            setError({});
            clearCart(cart);
            setIsLoading(false);
            setShowAlert(true);
            setConfirmed(true);
        } catch (e) {
            if (e.name === "ValidationError") {
                let errors = getYupValidationErrors(e);
                setResult({
                    messageType: "error",
                    message: e.message,
                    title: "Please add at least one item to cart.",
                });

                setError(errors);
            } else {
                setResult({
                    messageType: "error",
                    message: e.message,
                    title: "Something went wrong!",
                });
                setShowAlert(true);
            }

        }
        setIsLoading(false);
    }
    const handleChange = (event) => {
        setSelectedShipping(event.target.value);
    };
    const handlePaymentChange = (event) => {
        setPaymentValue(event.target.value);
    }

    const getQuantity = async (data) => {
        // // setIsLoading(true);
        // let qty = await getQuantityByID(data.variant_id);
        // // setIsLoading(false);
        // // console.log('qty' + data.quantity);
        // if (cartQty == qty.quantity) {
        //     console.log(cartQty);
        //     setDisabled(true);
        // }
        // else if (cartQty > qty.quantity) {
        //     balanceCart(data.variant_id, qty.quantity)
        // }
        // else
        addProductToCart(data, 1);

    }

    const priceCart = async (cart) => {
        let response = await calculateCartPrice(cart);
        setCartPrice(response)
    }
    const handleInfo = async () => {
        setIsLoading(true);
        let shippings = await getShippingAddresses();
        let response = await getPaymentTypes();
        setIsLoading(false);
        setShipping(shippings.shipping_addresses);
        setPaymentTypes(response.payment_types);
    }
    const getShipping = async () => {
        setIsLoading(true);
        let response = await getShippingAddresses();
        setIsLoading(false);
        setShipping(response.shipping_addresses);

    }
    const handlePaymentTypes = async () => {
        let response = await getPaymentTypes();
        setPaymentTypes(response.payment_types);
    }

    useEffect(() => {
        handleInfo();
    }, []);

    useEffect(() => {
        priceCart(cart);
    }, [cart])

    const closeAlert = () => {
        setShowAlert(false);
    };

    return (!confirmed ?
        <div className='xl:container xl:mx-auto mx-2 md:mx-4 mt-4 xl:px-4 flex flex-col lg:flex-row justify-between'>
            <SnackbarAlert open={showAlert} message={result.message} severity={result.messageType} handleClose={closeAlert}></SnackbarAlert>
            {isLoading && <LoadingPage></LoadingPage>}
            <div className='cart bg-secondary p-2 lg:p-5 w-full lg:w-[69%] rounded-sm lg:min-h-[750px] max-h-[750px]'>
                <div className='flex flex-col  lg:ml-4 justify-between h-full'>
                    <div className='hidden sm:block'>
                        <h1 className='text-2xl text-primary mt-2 lg:mt-0 mb-3 font-semibold uppercase'>shopping cart</h1>
                        {/* <div className='bg-white uppercase rounded-sm flex p-2 px-4 items-center text-slate-500'>
                            <p className='w-[40%]'>Product</p>
                            <p className='w-[15%]'>Color</p>
                            <p className='w-[10%]'>Size</p>
                            <p className='w-[15%]'>Qty</p>
                            <p className='w-[15%]'>Price</p>
                        </div> */}
                        <div className='max-h-[600px] overflow-auto'>
                            {cart.length == 0 ? <EmptyCart></EmptyCart> : cart.map((data) => (
                                // <TransitionGroup>
                                <div key={data.variant_id} className='bg-white uppercase rounded-sm flex justify-between py-4 p-2 px-2 lg:px-4 mt-4 items-center relative'>
                                    <div className='w-[40%] flex items-center'>
                                        <img src={`${data.thumbnail_url}`} className="w-[100px] h-[132px] rounded-sm shadow-sm"></img>
                                        <div className='flex flex-col ml-4'>
                                            <p className='text-primary line-clamp-3 max-w-[250px] text-sm'>{data.product_name}</p>
                                            <p className='text-xs font-light text-slate-500'>{data.product_code}</p>
                                            <div className='flex items-stretch mt-4'>
                                                <div className='p-1 px-3 uppercase bg-secondary'>{data.colorLabel}</div>
                                                <div className='p-1 px-3 uppercase bg-secondary ml-2'>{data.sizeLabel}</div>
                                                <div className='flex items-stretch ml-2'>
                                                    <button className='left bg-primary rounded-sm cursor-pointer active:shadow-sm p-1 flex flex-col justify-center hover:bg-slate-300 transition duration-200 ease-in-out' onClick={() => removeProductFromCart(data.variant_id)} disabled={isLoading ? true : false}><RemoveIcon fontSize="small" sx={{ color: 'white' }}></RemoveIcon></button>
                                                    <div className='font-semibold text-primary mx-2 lg:mx-4 flex flex-col justify-center'>{data.cartQuantity}</div>
                                                    <button className='right bg-primary rounded-sm cursor-pointer active:shadow-sm p-1 flex flex-col justify-center hover:bg-slate-300 transition duration-200 ease-in-out' onClick={() => getQuantity(data)} disabled={isLoading ? true : false}><AddIcon sx={{ color: 'white' }} fontSize="small"></AddIcon></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='w-[15%] flex'>
                                        {data.colorLabel}
                                    </div> */}
                                    {/* <p className='w-[10%]'>{data.sizeLabel}</p> */}
                                    <div className='flex items-center'>
                                        <p className='mr-2'>{'$ ' + (Math.round(data.price * data.cartQuantity * 100) / 100).toFixed(2)}</p>
                                        <IconButton onClick={() => deleteProduct(data.variant_id)}><DeleteIcon sx={{ color: '#e63946' }}></DeleteIcon></IconButton>
                                    </div>
                                </div>
                                // </TransitionGroup>
                            ))}
                        </div>
                    </div>

                    <div className='block sm:hidden'>
                        <div className=''>
                            <h1 className='text-2xl text-primary mb-3 font-semibold uppercase'>shopping cart</h1>
                            <div className='max-h-[500px] overflow-auto'>
                                {cart.length == 0 ? <EmptyCart></EmptyCart> : cart.map((data) => (
                                    // <TransitionGroup>
                                    <div key={data.variant_id} className='bg-white uppercase rounded-sm flex justify-between px-2 py-2 items-center'>
                                        <div className='relative'>
                                            <IconButton sx={{ position: 'absolute', color: '#E5383B', top: '66px', left: '50px', transform: 'translate(-50%, -50%)', zIndex: 20 }} onClick={() => deleteProduct(data.variant_id)}><DeleteIcon fontSize='large'></DeleteIcon></IconButton>
                                            <img src={`${data.thumbnail_url}`} className="w-[100px] h-[132px] rounded-sm shadow-sm relative opacity-40"></img>
                                        </div>


                                        <div className='flex flex-col ml-2'>
                                            <div className='flex'>
                                                <div className='flex flex-col'>
                                                    <p className='text-primary text-sm line-clamp-3'>{data.product_name}</p>
                                                    <p className='text-xs font-light text-slate-500'>{data.product_code}</p>
                                                </div>
                                            </div>
                                            <div className='flex my-4'>
                                                <div className='p-1 px-3 uppercase bg-secondary text-sm'>{data.colorLabel}</div>
                                                <div className='p-1 px-3 uppercase bg-secondary ml-2 text-sm'>{data.sizeLabel}</div>
                                            </div>
                                            <div className='flex justify-between items-center'>
                                                <p className='text-sm'>{'$ ' + (Math.round(data.price * data.cartQuantity * 100) / 100).toFixed(2)}</p>

                                                <div className='flex items-center mr-2'>
                                                    <button className='left bg-primary rounded-sm cursor-pointer active:shadow-sm p-0.5 flex flex-col justify-center hover:bg-slate-300 transition duration-200 ease-in-out ' onClick={() => removeProductFromCart(data.variant_id)} disabled={isLoading ? true : false}><RemoveIcon fontSize="small" sx={{ color: 'white' }}></RemoveIcon></button>
                                                    <div className='font-semibold text-primary mx-4 text-sm'>{data.cartQuantity}</div>
                                                    <button className='right bg-primary rounded-sm cursor-pointer active:shadow-sm p-0.5 flex flex-col justify-center hover:bg-slate-300 transition duration-200 ease-in-out' onClick={() => getQuantity(data, data.cartQuantity)} disabled={isLoading ? true : false}><AddIcon fontSize="small" sx={{ color: 'white' }}></AddIcon></button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    // </TransitionGroup>
                                ))}</div>
                        </div>
                    </div>
                    <div className='flex flex-col lg:flex-row justify-between items-center mt-2'>
                        <div className='flex items-center lg:mb-0 mb-2'>
                            <Button color={'#C84027'} bg_color={'white'} label={
                                <div className='flex items-center ' onClick={() => navigate('/')}>
                                    <ArrowBackIcon></ArrowBackIcon>
                                    <p className='ml-2 text-md'>Continue Shopping</p>
                                </div>} ></Button>
                        </div>
                        <div className='flex items-center'>
                            <div className='mr-2' onClick={() => clearCart()}>
                                <Button color={'#6c757d'} bg_color={'white'} label={'Clear Cart'} ></Button>
                            </div>
                            <div className='' onClick={() => window.location.reload(false)}>
                                <Button color={'#C84027'} bg_color={'white'} label={
                                    <div className='flex items-center'>
                                        <CachedIcon></CachedIcon>
                                        <p className='ml-2'>Refresh Cart</p>
                                    </div>
                                } ></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='payment bg-secondary p-5 w-[100%] lg:w-[30%] rounded-sm lg:min-h-[750px] max-h-[750px] mt-4 lg:mt-0'>
                {showAddAddress ? <AddAddress getShipping={getShipping} setShowAddAddress={setShowAddAddress}></AddAddress> : <div className='flex flex-col justify-between h-full'>
                    <div className='shipping pb-2'>
                        <h1 className='text-2xl text-primary mb-1 font-semibold uppercase'>shipping and payment</h1>
                        <div className='flex flex-row justify-between items-center'>
                            <h1 className='uppercase text-md text-formLabel mb-2'>Shipping Address</h1>
                            <div onClick={toggleEditMode} style={editMode ? { display: 'none' } : { display: 'block' }}>
                                <IconButton><EditIcon></EditIcon></IconButton>
                            </div>
                        </div>
                        <div className='max-h-[160px] overflow-auto'>
                            {shipping.map((data, index) => (
                                <div className='rounded-sm bg-white p-3 uppercase text-slate-600 flex flex-row items-center mb-1' key={'shipping_address-' + index} >
                                    <Radio
                                        checked={selectedShipping == data.shipping_id}
                                        onChange={handleChange}
                                        value={data.shipping_id}
                                        name="radio-buttons"
                                        style={editMode ? { display: 'none' } : { display: 'block' }}
                                    />
                                    <IconButton style={!editMode ? { display: 'none' } : { display: 'flex' }} onClick={() => handleDelete(data.shipping_id)}><DeleteIcon></DeleteIcon></IconButton>
                                    <div className='flex flex-col ml-4'>
                                        <div className='md:text-lg text-md'>{data.address_line1}</div>
                                        <div className='text-sm font-light'>{data.city}</div>
                                    </div>
                                </div>

                            ))}
                        </div>
                        <div className='mt-2 rounded-sm bg-white border border-slate-300 border-dashed text-center p-3 uppercase text-slate-500 cursor-pointer transition duration-200 ease-in-out hover:bg-[#f8f8f8] active:bg-zinc-100' onClick={() => setShowAddAddress(true)} style={editMode ? { display: 'none' } : { display: 'block' }}>
                            + Add Shipping Address
                        </div>
                        <div className='mt-2 rounded-sm bg-white border border-slate-300 border-dashed text-center p-3 uppercase text-slate-500 cursor-pointer transition duration-200 ease-in-out hover:bg-[#f8f8f8] active:bg-zinc-100' onClick={toggleEditMode} style={!editMode ? { display: 'none' } : { display: 'block' }}>
                            Done
                        </div>

                    </div>

                    <div className='mt-2'>
                        <label htmlFor='notes' className='uppercase text-slate-500 mb-[2px]'>Notes</label>
                        <textarea className='bg-white rounded-sm border-slate-100 p-4 w-full' placeholder='Enter Special Instructions' style={{ resize: "none" }} id='notes' name="notes" rows="3"></textarea>
                    </div>

                    <div className='flex mt-2 items-center mb-2'>
                        <div className='bg-white rounded-sm p-4'>
                            <LocalShippingIcon fontSize='large' sx={{ color: '#3a3a3a' }}></LocalShippingIcon>
                        </div>
                        <p className='uppercase ml-4'>1-2 Weeks</p>
                    </div>
                    <div className=''>
                        <div className='flex flex-col'>
                            <div className='flex flex-col'>
                                {/* <div className='flex justify-between mb-2'>
                                    <p className='text-slate-500 uppercase text-sm'>Items Price</p>
                                </div>
                                <div className='flex justify-between'>
                                    <p className='text-slate-500 uppercase text-sm'>Shipping Fees</p>
                                </div> */}
                                <RadioGroup
                                    value={paymentValue}
                                    onChange={handlePaymentChange}
                                >
                                    {paymentTypes.map((data) => (
                                        <FormControlLabel value={data.payment_type_id} control={<Radio />} label={data.payment_name} />
                                    ))}
                                </RadioGroup>
                            </div>
                            <div className='flex justify-between my-3 items-end'>
                                <h1 className='uppercase text-xl text-black'>Total</h1>
                                <div className='flex items-end'>
                                    <p className='uppercase font-medium text-2xl text-primary'>{'$ ' + (Math.round(cartPrice * 100) / 100).toFixed(2)}</p>
                                </div>
                            </div>
                        </div>
                        <div className='' onClick={() => handleOrder(cart, selectedShipping, paymentValue)}>
                            <Button color={'white'} bg_color={'#C84027'} label={'submit order'} disabled={isLoading || cart.length == 0 ? true : false} ></Button>
                        </div>
                    </div>
                </div>}
            </div>
        </div > : <OrderConfirmation order_id={orderDetails.order_id} email={orderDetails.email} orderDate={orderDetails.created_at} amount={orderAmount} setConfirmed={setConfirmed}></OrderConfirmation>
    )
}

export default Orders