import React, { useContext, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import { Link, useParams } from "react-router-dom";
import {
  getAllVariantsByID,
  getQtyByVariant,
  getImgByVariant,
  getVariant,
} from "../services/ProductsService";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Button from "./Button";
import RadioButton from "./RadioButton";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import ReactGA from "react-ga4";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/lazy";
import "swiper/css/thumbs";
import { Lazy, FreeMode, Thumbs, EffectFade } from "swiper";
import { GlobalContext } from "../context/GlobalContext";
import { Skeleton } from "@mui/material";
import ProductSkeleton from "./ProductSkeleton";

const Product = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoadingImg, setIsLoadingImg] = React.useState(true);
  const [isLoadingVariant, setIsLoadingVariant] = React.useState(true);
  const [images, setImages] = React.useState([]);
  const [chosenVariant, setChosenVariant] = React.useState();
  const [variants, setVariants] = React.useState();
  const [information, setInformation] = React.useState();
  const [product, setProduct] = React.useState({});
  const [colors, setColors] = React.useState([]);
  const [sizes, setSizes] = React.useState([]);
  const [quantity, setQuantity] = React.useState(1);
  const [selectedQuantity, setSelectedQuantity] = React.useState(1);
  const [selectedSize, setSelectedSize] = React.useState(1);
  const [selectedColor, setSelectedColor] = React.useState(1);

  const [activeSizeButton, setActiveSizeButton] = React.useState(0);
  const [activeColorButton, setActiveColorButton] = React.useState(0);

  const [thumbsSwiper, setThumbsSwiper] = React.useState();

  const [swiper, setSwiper] = React.useState(null);
  const product_id = useParams();
  const { cart, addProductToCart } = useContext(GlobalContext);

  const fetchProduct = async (productID) => {
    setIsLoading(true);
    let variants = await getAllVariantsByID(productID);
    setIsLoading(false);
    setVariants(variants);
    setProduct(variants.product[0]);
    setColors(variants.colors);
    setSizes(variants.sizes);
    setSelectedSize(variants.sizes[0].size_id);
    setSelectedColor(variants.colors[0].color_id);
  };

  const fetchVariant = async (size_id, color_id, product_id) => {
    setIsLoadingVariant(true);
    let variant = await getVariant(size_id, color_id, product_id);
    setIsLoadingVariant(false);
    setChosenVariant(variant[0]);
    // setQuantity(0);
    // setQuantity(variant[0]?.quantity);
  };

  useEffect(() => {
    fetchProduct(product_id.product_id);
    // fetchInfo(product_id.product_id, selectedColor, selectedSize);
  }, []);

  // useEffect(() => {
  //     console.log(thumbsSwiper);
  //     // console.log(swiper?.activeIndex)
  // }, [thumbsSwiper, swiper]);

  useEffect(() => {
    // setIsLoading(true);
    fetchImg(product_id.product_id, selectedColor);
    // setIsLoading(false);
  }, [selectedColor]);

  useEffect(() => {
    // fetchQty(product_id.product_id, selectedSize, selectedColor);
    // if (!isLoading)fmail

    fetchVariant(selectedSize, selectedColor, product_id.product_id);
    // setIsLoading(false);
  }, [selectedColor, selectedSize]);

  // useEffect(() => {
  //     setThumbsSwiper(null)

  // }, [selectedColor])
  // const fetchQty = async (productID, color, size) => {
  //     let information = await getQtyByVariant(productID, size, color);
  //     // setInformation(information);

  //     setQuantity(information.quantity[0].quantity);
  // }

  const fetchImg = async (productID, color) => {
    setIsLoadingImg(true);
    let information = await getImgByVariant(productID, color);
    setIsLoadingImg(false);
    setImages(information.images);
  };
  const handleAddToCart = (chosenVariant, qty) => {
    if (!isLoading && !isLoadingVariant && !isLoadingImg)
      addProductToCart(chosenVariant, qty);
    // console.log(chosenVariant)
  };
  // console.log(chosenVariant);
  const handleSizeChange = (e, index) => {
    setSelectedSize(e.currentTarget.value);
    setSelectedQuantity(1);
    setActiveSizeButton(index);
  };

  const handleColorChange = (e, index) => {
    setSelectedColor(e.currentTarget.value);
    setSelectedQuantity(1);
    setActiveColorButton(index);
  };

  const addQuantity = (quantity, chosenQuantity) => {
    // if (chosenQuantity < quantity) {
    const newQty = chosenQuantity + 1;
    setSelectedQuantity(newQty);
    // }
  };

  const removeQuantity = (quantity, chosenQuantity) => {
    if (quantity != 0) {
      if (chosenQuantity > 1) {
        const newQty = chosenQuantity - 1;
        setSelectedQuantity(newQty);
      }
    }
  };
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Product",
    });
  }, []);
  return (
    <div className="xl:container xl:mx-auto mx-4 my-4 lg:my-10 xl:px-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-16">
        <div className="images">
          {/* <div> */}
          <Swiper
            // onSwiper={setSwiper}
            // onAfterInit={() => console.log(swiper)}
            // onInit={ }
            // autoHeight={true}
            // lazy={true}
            effect={"fade"}
            // loop={true}

            // initialSlide={0}
            // observer={true}
            // observeParents={true}
            spaceBetween={10}
            navigation={false}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[Lazy, FreeMode, Thumbs, EffectFade]}
            className="mySwiper2"
          >
            {images.map((data) => (
              <SwiperSlide>
                {/* {isLoading ? <Skeleton variant="rectangular" sx={{ bgcolor: "#FFF6EB" }} width={'100%'} height={'546px'} animation="wave"></Skeleton> : */}

                <img style={{ width: "inherit" }} src={`${data.img_url}`} />
                {/* } */}
              </SwiperSlide>
            ))}
          </Swiper>
          {/* </div> */}
          <div>
            <Swiper
              // autoHeight={true}
              // observer={true}
              // observeParents={true}
              onSwiper={setThumbsSwiper}
              spaceBetween={5}
              slidesPerView={5}
              // freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Thumbs]}
              className="mySwiper"
              initialSlide={0}
            >
              {!isLoading &&
                images.map((data) => (
                  <SwiperSlide>
                    <img style={{ width: "inherit" }} src={`${data.img_url}`} />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>

        {isLoading ? (
          <ProductSkeleton></ProductSkeleton>
        ) : (
          <div className="flex flex-col">
            <p className="text-3xl text-primary uppercase font-medium">
              {product.product_name}
            </p>
            <p className="text-md text-slate-500 uppercase">
              {product.product_code}
            </p>
            <p className="text-sm font-light text-green-600 uppercase">
              {product.is_available ? "IN STOCK" : ""}
            </p>
            <div className="text-2xl text-primary font-bold mt-4 lg:mt-8">
              {"$ " + (Math.round(product.price * 100) / 100).toFixed(2)}
            </div>

            <div className="mt-4 lg:mt-8">
              <p className="text-slate-600">Sizes</p>
              <div className="flex flex-row">
                {sizes.map((data, index) => (
                  <RadioButton
                    name={data.label}
                    value={data.size_id}
                    bg_color={
                      activeSizeButton === index
                        ? "bg-slate-300"
                        : "bg-secondary"
                    }
                    disabled={activeSizeButton === index ? true : false}
                    setSelected={(e) => handleSizeChange(e, index)}
                  ></RadioButton>
                ))}
              </div>
            </div>

            <div className="mt-6">
              <p className="text-slate-600">Colors</p>
              <div className="flex flex-row">
                {colors.map((data, index) => (
                  <RadioButton
                    name={data.label}
                    value={data.color_id}
                    bg_color={
                      activeColorButton === index
                        ? "bg-slate-300"
                        : "bg-secondary"
                    }
                    disabled={activeColorButton === index ? true : false}
                    setSelected={(e) => handleColorChange(e, index)}
                  ></RadioButton>
                ))}
              </div>
            </div>
            <div className="mt-6">
              <p className="text-slate-600">Quantity</p>
              <div className="quantity-selector mt-2 flex w-full lg:w-auto">
                <div
                  className="left bg-slate-200 rounded-sm cursor-pointer active:shadow-sm p-1 px-3 flex flex-col justify-center hover:bg-slate-300 transition duration-200 ease-in-out"
                  onClick={() => removeQuantity(quantity, selectedQuantity)}
                >
                  <RemoveIcon fontSize="small"></RemoveIcon>
                </div>
                <div className="quantity font-semibold text-primary mx-2 bg-secondary text-center w-full md:w-auto md:px-12 py-2 flex flex-col justify-center text-xl">
                  {quantity != 0 ? selectedQuantity : 0}
                </div>
                <div
                  className="right bg-slate-200 rounded-sm cursor-pointer active:shadow-sm p-1 px-3 flex flex-col justify-center hover:bg-slate-300 transition duration-200 ease-in-out"
                  onClick={() => addQuantity(quantity, selectedQuantity)}
                >
                  <AddIcon fontSize="small"></AddIcon>
                </div>
              </div>
            </div>

            <div className="mt-6 pt-4 border-t-2 border-slate-300 flex flex-row justify-between">
              {/* {quantity === 0 ? */}
              {/* // <div className='w-full'> */}

              {/* //     <Button bg_color={'#f8f8f8'} color={'gray'} label="OUT OF STOCK" ></Button> */}

              {/* // </div> : */}
              <div className="flex flex-row justify-between w-full">
                <div
                  className="w-[49%]"
                  onClick={() =>
                    handleAddToCart(chosenVariant, selectedQuantity)
                  }
                >
                  <Button
                    bg_color={"#f8f8f8"}
                    color={"gray"}
                    label="ADD TO CART"
                    disabled={isLoadingVariant}
                  ></Button>
                </div>

                <div
                  className="w-[49%]"
                  onClick={() =>
                    handleAddToCart(chosenVariant, selectedQuantity)
                  }
                >
                  <Link to={"/orders"}>
                    <Button
                      bg_color={"#C84027"}
                      color={"white"}
                      label="BUY NOW"
                      disabled={isLoadingVariant}
                    ></Button>
                  </Link>
                </div>
              </div>
              {/* } */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Product;
