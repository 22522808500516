import React, { useEffect, useState } from "react";
import AdminSidebar from "../Components/common/AdminSidebar";
import FormFileInput from "../Components/common/FormFileInput";
import FormSelectInput from "../Components/common/FormSelectInput";
import FormTextInput from "../Components/common/FormTextInput";
import { addProduct } from "../services/AdminService";
import { Link } from "react-router-dom";
// import Button from '../Components/Button'
import { default as ButtonCustom } from "../Components/Button";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import {
  getColors,
  getNecklines,
  getRootCategories,
  getSeasons,
  getSizes,
  getSleeveLengths,
  getSubCategories,
  getTypes,
} from "../services/CategoryService";
import { productCreationSchema } from "../validations/AdminValidation";
import { getYupValidationErrors } from "../validations/ValidationHelper";
import TransitionAlert from "../Components/common/SnackbarAlert";
import {
  getColorsDropdownList,
  getNecklinesDropdownList,
  getRootCategoriesDropdownList,
  getSeasonsDropdownList,
  getSizesDropdownList,
  getSleeveLengthsDropdownList,
  getSubCategoriesDropdownList,
  getTypesDropdownList,
} from "../helpers/CategoryHelpers";
import { Online } from "react-detect-offline";
import { Offline } from "react-detect-offline";
export default function AddProductAdminPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [hasDropdownError, setHasDropdownError] = useState(false);

  const [productName, setProductName] = useState("");
  const [productCode, setProductCode] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [productSubCategory, setProductSubCategory] = useState("");
  const [productSeason, setProductSeason] = useState("");
  const [productStyle, setProductStyle] = useState("");
  const [productSize, setProductSize] = useState([]);
  const [productColor, setProductColor] = useState([]);
  const [productType, setProductType] = useState(0);
  const [productPrice, setProductPrice] = useState(0);
  const [productWeight, setProductWeight] = useState(0);
  const [productAvailability, setProductAvailability] = useState(0);
  const [productPattern, setProductPattern] = useState("");
  const [productDecoration, setProductDecoration] = useState("");
  const [productSilhouette, setProductSilhouette] = useState("");
  const [fabricType, setFabricType] = useState("");
  const [productNeckline, setProductNeckline] = useState("");
  const [productSleeveLength, setProductSleeveLength] = useState("");
  const [activationStatus, setActivationStatus] = useState(1);
  const [files, setFiles] = useState({});
  const [images, setImages] = useState({});
  const [thumbnailImageColor, setThumbnailImageColor] = useState("");
  const [thumbnailImageIndex, setThumbnailImageIndex] = useState(-1);

  const [types, setTypes] = useState([]);
  const [rootCategories, setRootCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [necklines, setNecklines] = useState([]);
  const [sleeveLengths, setSleeveLengths] = useState([]);
  const [seasons, setSeasons] = useState(["winter"]);
  const [dailyStatus, setDailyStatus] = useState(0);

  const [productCreationResult, setProductCreationResult] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [inputErrors, setInputErrors] = useState({});

  const resetForm = () => {
    setProductName("");
    setProductCode("");
    setProductCategory("");
    setProductSubCategory("");
    setProductSeason("");
    setProductSize([]);
    setProductColor([]);
    setProductType("");
    setProductStyle("");
    setProductPrice(0);
    setProductWeight(0);
    setProductAvailability(0);
    setProductPattern("");
    setProductDecoration("");
    setProductSilhouette("");
    setFabricType("");
    setProductNeckline("");
    setProductSleeveLength("");
    setActivationStatus(1);
    setFiles({});
    setImages({});
    setThumbnailImageColor(0);
    setThumbnailImageIndex(-1);
  };

  useEffect(() => {
    const loadDropdownValues = async () => {
      try {
        let resultTypes = await getTypes();
        let resultColors = await getColors();
        let resultSizes = await getSizes();
        let resultNecklines = await getNecklines();
        let resultSleeveLengths = await getSleeveLengths();
        let resultSeasons = await getSeasons();
        setTypes(getTypesDropdownList(resultTypes));
        setColors(getColorsDropdownList(resultColors.data));
        setSizes(getSizesDropdownList(resultSizes.data));
        setNecklines(getNecklinesDropdownList(resultNecklines.data));
        setSleeveLengths(
          getSleeveLengthsDropdownList(resultSleeveLengths.data)
        );
        setSeasons(getSeasonsDropdownList(resultSeasons.data));
      } catch (e) {
        console.log(e);
        setHasDropdownError(true);
      }
    };
    loadDropdownValues();
  }, []);

  useEffect(() => {
    const loadCategoryDropdownValues = async () => {
      try {
        setProductCategory("");
        let resultCategories = await getRootCategories(productType);
        setRootCategories(getRootCategoriesDropdownList(resultCategories.data));
      } catch (e) {
        setHasDropdownError(true);
      }
    };
    if (productType) {
      loadCategoryDropdownValues();
    }
  }, [productType]);

  useEffect(() => {
    const loadSubCategoryDropdownValues = async () => {
      try {
        setProductSubCategory("");
        let resultSubCategories = await getSubCategories(productCategory);
        setSubCategories(
          getSubCategoriesDropdownList(resultSubCategories.data)
        );
      } catch (e) {
        setHasDropdownError(true);
      }
    };
    productCategory && loadSubCategoryDropdownValues();
  }, [productCategory]);

  const validateProductImages = () => {
    if (productColor.length > 0) {
      let productImagesError;
      productColor.forEach((color) => {
        let productImage = files[color];
        if (productImage.length === 0) {
          productImagesError = {
            ...productImagesError,
            [color]: "Please add images to this product color.",
          };
        }
      });
      if (productImagesError) {
        throw { name: "ImageValidationError", errors: productImagesError };
      } else if (thumbnailImageColor === "" || thumbnailImageIndex === -1) {
        throw {
          message:
            "Please choose one image as thumbnail image for the product.",
        };
      }
    }
  };

  const handleCreateProduct = async () => {
    setIsLoading(true);
    setShowAlert(false);
    setInputErrors({});
    try {
      await productCreationSchema.validate(
        {
          productName,
          productCode,
          productSize,
          productPrice,
          productCategory,
          productSubCategory,
          productColor,
        },
        { abortEarly: false }
      );
      validateProductImages();
      let result = await addProduct({
        files,
        productName,
        productCode,
        productCategory,
        productSubCategory,
        productSeason,
        productSize,
        productStyle,
        productColor,
        productType,
        productPrice,
        productWeight,
        productPattern,
        productDecoration,
        productSilhouette,
        fabricType,
        productNeckline,
        productSleeveLength,
        thumbnailImageColor,
        thumbnailImageIndex,
        dailyStatus,
        activationStatus,
        productAvailability,
      });
      setProductCreationResult({
        messageType: "success",
        message: result.message,
        title: "Success!",
      });
      setInputErrors({});
      setShowAlert(true);
      resetForm();
    } catch (e) {
      if (e.name === "ValidationError") {
        console.log(e);
        let errors = getYupValidationErrors(e);
        setInputErrors(errors);
      } else if (e.name === "ImageValidationError") {
        console.log(e);
        setInputErrors({ productImages: e.errors });
      } else {
        setProductCreationResult({
          messageType: "error",
          message: e.message,
          title: "Something went wrong!",
        });
        setShowAlert(true);
      }
    }
    setIsLoading(false);
  };

  const addToArray = (array, value) => {
    return array.includes(value) ? array : [...array, value];
  };

  const removeFromArray = (array, index) => {
    return array.filter((_, idx) => index !== idx);
  };

  const removeFromObject = (object, key) => {
    return Object.keys(object)
      .filter((k) => k !== key)
      .reduce((obj, k) => {
        obj[k] = object[k];
        return obj;
      }, {});
  };

  const closeAlert = () => {
    setShowAlert(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    console.log(thumbnailImageIndex);
  }, [thumbnailImageIndex]);
  return (
    <div className="flex flex-col lg:flex-row w-[95%] lg:w-[98%] mx-auto">
      <div className="w-full lg:w-1/4">
        <AdminSidebar />
      </div>

      <div className="w-full lg:w-3/4 lg:ml-6">
        <div className="w-full mt-5">
          <div className="flex flex-col mb-2">
            <h1 className="text-left uppercase text-2xl underline decoration-4 underline-offset-0 decoration-primary">
              Add Product
            </h1>
            <TransitionAlert
              open={showAlert}
              message={productCreationResult.message}
              severity={productCreationResult.messageType}
              title={productCreationResult.title}
              handleClose={closeAlert}
            />
          </div>
          <div className="">
            {hasDropdownError ? (
              <p>Failed to connect to server</p>
            ) : (
              <>
                <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 w-full">
                  <FormTextInput
                    // className="w-[30%]"
                    id="productName"
                    label="Product Name"
                    placeholder="Product Name..."
                    value={productName}
                    onChange={setProductName}
                    error={inputErrors.productName}
                  />
                  <FormTextInput
                    // className="w-[30%]"
                    id="productCode"
                    label="Product Code"
                    placeholder="Product Code..."
                    value={productCode}
                    onChange={setProductCode}
                    error={inputErrors.productCode}
                  />
                  <FormSelectInput
                    // className="w-[30%]"
                    id="productSeason"
                    label="Product Season"
                    placeholder="Product Season..."
                    options={seasons}
                    value={productSeason}
                    onChange={setProductSeason}
                    error={""}
                  />

                  <FormSelectInput
                    // className="w-[30%]"
                    id="productType"
                    label="Product Type"
                    placeholder="Product Type..."
                    options={types}
                    value={productType}
                    onChange={setProductType}
                    error={""}
                  />
                  <FormSelectInput
                    // className="w-[30%]"
                    id="productCategory"
                    label="Product Category"
                    placeholder="Product Category..."
                    options={rootCategories}
                    value={productCategory}
                    onChange={setProductCategory}
                    error={inputErrors.productCategory}
                  />
                  <FormSelectInput
                    // className="w-[30%]"
                    id="productSubCategory"
                    label="Product Sub Category"
                    placeholder="Product Sub Category..."
                    options={subCategories}
                    value={productSubCategory}
                    onChange={setProductSubCategory}
                    error={inputErrors.productSubCategory}
                  />
                  <FormSelectInput
                    multiple={true}
                    selectedValues={productSize}
                    id="productSize"
                    label="Product Size"
                    placeholder="Product Size..."
                    options={sizes}
                    value=""
                    onChange={(value) => {
                      const newProductSize = addToArray(productSize, value);
                      setProductSize(newProductSize);
                    }}
                    removeValue={(index) => {
                      const newProductSize = removeFromArray(
                        productSize,
                        index
                      );
                      setProductSize(newProductSize);
                    }}
                    error={inputErrors.productSize}
                  />
                  <FormSelectInput
                    multiple={true}
                    selectedValues={productColor}
                    id="productColor"
                    label="Product Color"
                    placeholder="Product Color..."
                    options={colors}
                    value={""}
                    onChange={(value) => {
                      const newProductColor = addToArray(productColor, value);
                      setImages({ ...images, [value]: [] });
                      setFiles({ ...files, [value]: [] });
                      setProductColor(newProductColor);
                    }}
                    removeValue={(index) => {
                      const newImages = removeFromObject(
                        images,
                        productColor[index]
                      );
                      const newFiles = removeFromObject(
                        files,
                        productColor[index]
                      );
                      const newProductColor = removeFromArray(
                        productColor,
                        index
                      );
                      setImages(newImages);
                      setFiles(newFiles);
                      setProductColor(newProductColor);
                    }}
                    error={inputErrors.productColor}
                  />

                  <FormTextInput
                    type="number"
                    id="productPrice"
                    label="Product Price"
                    placeholder="Product Price..."
                    value={productPrice}
                    onChange={setProductPrice}
                    error={inputErrors.productPrice}
                  />
                  <FormTextInput
                    type="number"
                    id="productWeight"
                    label="Product Weight"
                    placeholder="Product Weight..."
                    value={productWeight}
                    onChange={setProductWeight}
                    error={""}
                  />
                  <FormSelectInput
                    id="productNeckline"
                    label="Product Neckline"
                    placeholder="Product Neckline..."
                    options={necklines}
                    value={productNeckline}
                    onChange={setProductNeckline}
                    error={""}
                  />
                  <FormSelectInput
                    id="productSleeveLength"
                    label="Product Sleeve Length"
                    placeholder="Product Sleeve Length..."
                    options={sleeveLengths}
                    value={productSleeveLength}
                    onChange={setProductSleeveLength}
                    error={""}
                  />
                  <FormTextInput
                    id="productPattern"
                    label="Product Pattern"
                    placeholder="Product Pattern..."
                    value={productPattern}
                    onChange={setProductPattern}
                    error={""}
                  />
                  <FormTextInput
                    id="fabricType"
                    label="Fabric Type"
                    placeholder="Fabric Type..."
                    value={fabricType}
                    onChange={setFabricType}
                    error={""}
                  />
                  <FormTextInput
                    id="productDecoration"
                    label="Product Decoration"
                    placeholder="Product Decoration..."
                    value={productDecoration}
                    onChange={setProductDecoration}
                    error={""}
                  />
                  <FormTextInput
                    id="productSilhouette"
                    label="Product Silhouette"
                    placeholder="Product Silhouette..."
                    value={productSilhouette}
                    onChange={setProductSilhouette}
                    error={""}
                  />

                  <FormControl>
                    <FormLabel sx={{ fontFamily: "Lato" }}>
                      <div className="text-lg text-formLabel font-bold lg:mt-5 mt-2">
                        Activation Status
                      </div>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={activationStatus}
                      onChange={(event) =>
                        setActivationStatus(event.target.value)
                      }
                    >
                      <FormControlLabel
                        sx={{ fontFamily: "Lato" }}
                        value="1"
                        control={<Radio />}
                        label="Active"
                      />
                      <FormControlLabel
                        sx={{ fontFamily: "Lato" }}
                        value="0"
                        control={<Radio />}
                        label="Not Active"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel sx={{ fontFamily: "Lato" }}>
                      <div className="text-lg text-formLabel font-bold lg:mt-5 mt-2">
                        In Stock
                      </div>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={productAvailability}
                      onChange={(event) =>
                        setProductAvailability(event.target.value)
                      }
                    >
                      <FormControlLabel
                        sx={{ fontFamily: "Lato" }}
                        value="1"
                        control={<Radio />}
                        label="In Stock"
                      />
                      <FormControlLabel
                        sx={{ fontFamily: "Lato" }}
                        value="0"
                        control={<Radio />}
                        label="Not In Stock"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel sx={{ fontFamily: "Lato" }}>
                      <div className="text-lg text-formLabel font-bold lg:mt-5 mt-2">
                        Daily
                      </div>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={dailyStatus}
                      onChange={(event) => setDailyStatus(event.target.value)}
                    >
                      <FormControlLabel
                        sx={{ fontFamily: "Lato" }}
                        value="1"
                        control={<Radio />}
                        label="Daily"
                      />
                      <FormControlLabel
                        sx={{ fontFamily: "Lato" }}
                        value="0"
                        control={<Radio />}
                        label="Not Daily"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                {productColor.map((color, index) => {
                  let colorLabel;
                  colors.forEach((c) => {
                    if (c.value == color) {
                      colorLabel = c.label;
                    }
                  });
                  return (
                    <FormFileInput
                      key={index}
                      id={`productImages${color}`}
                      label={`${colorLabel} Color Product Images`}
                      error={
                        inputErrors.productImages
                          ? inputErrors.productImages[color]
                          : ""
                      }
                      files={images[color]}
                      onChange={(selectedFiles) => {
                        const initialFiles = files[color] ? files[color] : [];
                        const newFiles = [...initialFiles, ...selectedFiles];
                        setFiles({ ...files, [color]: [...newFiles] });
                        const selectedImages = selectedFiles.map((file) =>
                          URL.createObjectURL(file)
                        );
                        const initialImages = images[color]
                          ? images[color]
                          : [];
                        const newImages = [...initialImages, ...selectedImages];
                        setImages({ ...images, [color]: [...newImages] });
                      }}
                      removeFile={(index) => {
                        if (
                          thumbnailImageColor === color &&
                          thumbnailImageIndex === index
                        ) {
                          setThumbnailImageColor("");
                          setThumbnailImageIndex(-1);
                        }
                        const newImages = removeFromArray(images[color], index);
                        setImages({ ...images, [color]: [...newImages] });
                        const newFiles = removeFromArray(files[color], index);
                        setFiles({ ...files, [color]: [...newFiles] });
                      }}
                      handleFavorite={(index) => {
                        if (
                          thumbnailImageColor === color &&
                          thumbnailImageIndex === index
                        ) {
                          console.log(thumbnailImageIndex);
                          console.log("hi");
                          setThumbnailImageColor("");
                          setThumbnailImageIndex(-1);
                        } else {
                          console.log(thumbnailImageIndex);
                          setThumbnailImageColor(color);
                          setThumbnailImageIndex(index);
                        }
                      }}
                      favoriteIndex={
                        color === thumbnailImageColor ? thumbnailImageIndex : -1
                      }
                    />
                  );
                })}

                <div className="flex justify-between md:justify-end my-5">
                  <Link to="/admin/products">
                    <div className="md:mr-2">
                      <ButtonCustom
                        bg_color="#f8f8f8"
                        label="discard product"
                        color="gray"
                        disabled={isLoading}
                      ></ButtonCustom>
                    </div>
                  </Link>

                  <div onClick={handleCreateProduct}>
                    <Online>
                      <ButtonCustom
                        bg_color="#C84027"
                        label={isLoading ? "Loading ..." : "Create Product"}
                        color="white"
                        disabled={isLoading}
                      ></ButtonCustom>
                    </Online>
                    <Offline>
                      <ButtonCustom
                        bg_color="#C84027"
                        label={"No Internet"}
                        color="white"
                        disabled={true}
                      ></ButtonCustom>
                    </Offline>
                    {/* <ButtonCustom bg_color='#C84027' label={isLoading ? "Loading ..." : "Create Product"} color="white" disabled={isLoading}></ButtonCustom> */}
                  </div>

                  {/* <button
                    className="bg-primary text-white py-2 px-5 rounded-md flex items-center "
                    disabled={isLoading}

                  >
                    {isLoading ? "Loading ..." : "Create Product"}
                  </button> */}
                </div>
              </>
            )}{" "}
          </div>
        </div>
      </div>
    </div>
  );
}
