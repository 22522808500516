import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Switch,
} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import RegistrationPage from "./pages/RegistrationPage";
import PasswordResetPage from "./pages/PasswordResetPage";
import AuthContextProvider from "./contexts/AuthContext";
import useAuth from "./hooks/useAuth";
import AccountVerificationPage from "./pages/AccountVerificationPage";
import "./App.css";
import Products from "./Components/Products";
import { GlobalProvider } from "./context/GlobalContext";
import PageWrapper from "./Components/common/PageWrapper";
import ScrollToTop from "./Components/common/ScrollToTop";
import ProductAdminPage from "./pages/ProductAdminPage";
import AddProductAdminPage from "./pages/AddProductAdminPage";
import EditProductAdminPage from "./pages/EditProductAdminPage";
import CategoryAdminPage from "./pages/CategoryAdminPage";

import "./App.css";
import Home from "./Components/Home";
import EditVariant from "./pages/EditVariant";
import EditThumbnail from "./pages/EditThumbnail";
import ProtectedRoute from "./routes/ProtectedRoute";
import Product from "./Components/Product";
import TransitionPage from "./pages/TransitionPage";
import Orders from "./Components/Orders";
import AdminRoute from "./routes/AdminRoute";
import LoadingPage from "./Components/LoadingPage";
import WishListProvider from "./context/WishListContext";
import useWishList from "./hooks/useWishList";
import WishList from "./Components/WishList";
import Banner from "./pages/Banner";
import EditBanner from "./pages/EditBanner";
import AddBanner from "./pages/AddBanner";
import AdminOrders from "./pages/AdminOrders";
import Newest from "./Components/Newest";
import Terms from "./Components/Terms";
import Privacy from "./Components/Privacy";
import OrderHistory from "./Components/OrderHistory";
import SubProducts from "./Components/SubProducts";
import EditImages from "./pages/EditImages";
import ReactGA from "react-ga4";
const App = () => {
  const [state, setState] = React.useState({
    right: false,
    left: false,
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  return (
    <GlobalProvider>
      <AuthContextProvider>
        <WishListProvider>
          <BrowserRouter>
            <div className="App">
              <ScrollToTop>
                <AppRoutes
                  toggleDrawer={toggleDrawer}
                  state={state}
                  setState={setState}
                />
              </ScrollToTop>
            </div>
          </BrowserRouter>
        </WishListProvider>
      </AuthContextProvider>
    </GlobalProvider>
  );
};

function AppRoutes({ toggleDrawer, state, setState }) {
  const { isLoggedIn, isLoadingProfile, admin } = useAuth();
  const { wishList, setWishList } = useWishList();
  useEffect(() => {
    ReactGA.initialize("G-P4G1PWE6E3");
  }, []);
  if (isLoadingProfile) return <LoadingPage></LoadingPage>;

  return (
    <Routes>
      <Route element={<AdminRoute isLoggedIn={isLoggedIn} isAdmin={admin} />}>
        <Route path="/admin/categoriesAdmin" element={<CategoryAdminPage />} />
        <Route
          path="/admin/products"
          element={<ProductAdminPage></ProductAdminPage>}
        />
        <Route
          path="/admin/add/product"
          element={<AddProductAdminPage></AddProductAdminPage>}
        />
        <Route
          path="/admin/editThumbnail/:product_id"
          element={<EditThumbnail></EditThumbnail>}
        />
        <Route
          path="/admin/editProduct/:product_id"
          element={<EditProductAdminPage />}
        />
        <Route path="/admin/editImages/:variant_id" element={<EditImages />} />
        <Route path="/admin/addVariant/:variant_id" element={<EditVariant />} />
        <Route path="/admin/banner" element={<Banner />} />
        <Route path="/admin/addBanner" element={<AddBanner />} />
        <Route path="/admin/editBanner/:banner_id" element={<EditBanner />} />
        <Route path="/admin/adminOrders" element={<AdminOrders />} />
      </Route>

      <Route element={<ProtectedRoute isLoggedIn={isLoggedIn} />}>
        <Route
          path="/orders"
          element={
            admin ? (
              <Navigate to={"/admin/products"}></Navigate>
            ) : (
              <PageWrapper toggleDrawer={toggleDrawer} state={state}>
                <Orders toggleDrawer={toggleDrawer} state={state} />
              </PageWrapper>
            )
          }
        />
        <Route
          path="/wishList"
          element={
            admin ? (
              <Navigate to={"/admin/products"}></Navigate>
            ) : (
              <PageWrapper toggleDrawer={toggleDrawer} state={state}>
                <WishList
                  toggleDrawer={toggleDrawer}
                  state={state}
                  wishList={wishList}
                  setWishlist={setWishList}
                />
              </PageWrapper>
            )
          }
        />
        <Route
          path="/ordersHistory"
          element={
            <PageWrapper
              toggleDrawer={toggleDrawer}
              state={state}
              setState={setState}
              wishList={wishList}
            >
              <OrderHistory />
            </PageWrapper>
          }
        ></Route>
      </Route>

      <Route
        path="/auth/login"
        element={
          !isLoggedIn && !admin ? (
            <LoginPage />
          ) : (
            <Navigate to={"/admin/products"}></Navigate>
          )
        }
      />
      <Route
        path="/auth/register"
        element={
          !isLoggedIn && !admin ? (
            <RegistrationPage />
          ) : (
            <Navigate to={"/admin/products"}></Navigate>
          )
        }
      ></Route>
      <Route
        path="/auth/verify"
        element={
          !isLoggedIn && !admin ? (
            <AccountVerificationPage />
          ) : (
            <Navigate to={"/admin/products"}></Navigate>
          )
        }
      ></Route>
      <Route
        path="/auth/transition"
        element={
          !isLoggedIn && !admin ? (
            <TransitionPage />
          ) : (
            <Navigate to={"/admin/products"}></Navigate>
          )
        }
      ></Route>
      <Route
        path="/auth/resetPassword"
        element={
          !isLoggedIn && !admin ? (
            <PasswordResetPage />
          ) : (
            <Navigate to={"/admin/products"}></Navigate>
          )
        }
      />

      <Route
        path="/auth/login"
        element={
          !isLoggedIn ? <LoginPage /> : <Navigate to={"/orders"}></Navigate>
        }
      />
      <Route
        path="/auth/register"
        element={
          !isLoggedIn ? (
            <RegistrationPage />
          ) : (
            <Navigate to={"/orders"}></Navigate>
          )
        }
      ></Route>
      <Route
        path="/auth/verify"
        element={
          !isLoggedIn ? (
            <AccountVerificationPage />
          ) : (
            <Navigate to={"/orders"}></Navigate>
          )
        }
      ></Route>
      <Route
        path="/auth/transition"
        element={
          !isLoggedIn ? (
            <TransitionPage />
          ) : (
            <Navigate to={"/orders"}></Navigate>
          )
        }
      ></Route>
      <Route
        path="/auth/resetPassword"
        element={
          !isLoggedIn ? (
            <PasswordResetPage />
          ) : (
            <Navigate to={"/orders"}></Navigate>
          )
        }
      />

      <Route path="*" element={<Navigate to={"/"}></Navigate>}></Route>
      <Route
        path="/"
        element={
          <PageWrapper
            toggleDrawer={toggleDrawer}
            state={state}
            setState={setState}
            wishList={wishList}
          >
            <Home wishList={wishList} setWishList={setWishList}></Home>
          </PageWrapper>
        }
      ></Route>
      <Route
        path="/new/:date"
        element={
          <PageWrapper
            toggleDrawer={toggleDrawer}
            state={state}
            setState={setState}
            wishList={wishList}
          >
            <Newest wishList={wishList} setWishList={setWishList}></Newest>
          </PageWrapper>
        }
      ></Route>
      <Route
        path="/products/sub/:category_id/:page_nb"
        element={
          <PageWrapper
            toggleDrawer={toggleDrawer}
            state={state}
            setState={setState}
            wishList={wishList}
          >
            <SubProducts
              bottomState={state}
              toggleDrawer={toggleDrawer}
              wishList={wishList}
              setWishList={setWishList}
            ></SubProducts>
          </PageWrapper>
        }
      ></Route>
      <Route
        path="/terms"
        element={
          <PageWrapper
            toggleDrawer={toggleDrawer}
            state={state}
            setState={setState}
            wishList={wishList}
          >
            <Terms></Terms>
          </PageWrapper>
        }
      ></Route>
      <Route
        path="/privacy"
        element={
          <PageWrapper
            toggleDrawer={toggleDrawer}
            state={state}
            setState={setState}
            wishList={wishList}
          >
            <Privacy></Privacy>
          </PageWrapper>
        }
      ></Route>
      <Route path="/PageNotFound" element={"404"}></Route>
      <Route
        path="/products/:category_id/:page_nb"
        element={
          <PageWrapper
            toggleDrawer={toggleDrawer}
            state={state}
            setState={setState}
          >
            <Products
              toggleDrawer={toggleDrawer}
              bottomState={state}
              wishList={wishList}
              setWishList={setWishList}
            ></Products>
          </PageWrapper>
        }
      ></Route>
      <Route
        path="/product/:product_id"
        element={
          <PageWrapper
            toggleDrawer={toggleDrawer}
            state={state}
            setState={setState}
          >
            <Product />
          </PageWrapper>
        }
      ></Route>
    </Routes>
  );
}

export default App;
