import React, { useEffect } from 'react'
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { getOrderDetails, setDelivered, setProcessing } from '../services/AdminService';
import List from '@mui/material/List';
import CheckIcon from '@mui/icons-material/Check';
import { Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material';
import TransitionAlert from "../Components/common/SnackbarAlert";
import LoadingButton from '@mui/lab/LoadingButton';
const OrderDetails = ({ data, handleHeaders }) => {
    const [open, setOpen] = React.useState(false);
    const [details, setDetails] = React.useState([]);
    const [showAlert, setShowAlert] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [result, setResult] = React.useState({});
    const handleClick = () => {
        setOpen(!open);
    };
    const handleDetails = async () => {
        let response = await getOrderDetails(data.order_header_id);
        setDetails(response);
        console.log(response);
    };
    const theme = createTheme({
        palette: {
            checked: {
                main: '#52b788',
            },
            unchecked: {
                main: '#6c757d'
            }
        },
    });
    const handleDelivered = async (order_id) => {
        setIsLoading(true);
        setShowAlert(false);

        try {
            let result = await setDelivered(order_id);
            setResult({
                messageType: "success",
                message: result.message,
                title: "Success!",
            });
            setShowAlert(true);
            handleHeaders();
        } catch (e) {
            setResult({
                messageType: "error",
                message: e.message,
                title: "Something went wrong!",
            });
            setShowAlert(true);
        }
        setIsLoading(false);
    };

    const handleProcessing = async (order_id) => {
        setIsLoading(true);
        setShowAlert(false);

        try {
            let result = await setProcessing(order_id);
            setResult({
                messageType: "success",
                message: result.message,
                title: "Success!",
            });
            setShowAlert(true);
            handleHeaders();
        } catch (e) {
            setResult({
                messageType: "error",
                message: e.message,
                title: "Something went wrong!",
            });
            setShowAlert(true);
        }
        setIsLoading(false);
    };
    const closeAlert = () => {
        setShowAlert(false);
    };
    useEffect(() => {
        handleDetails();
    }, [])
    return (
        <div>
            <TransitionAlert
                open={showAlert}
                message={result.message}
                severity={result.messageType}
                title={result.title}
                handleClose={closeAlert}
            />
            <div className='flex flex-col'>
                <ListItemButton sx={{ my: '10px' }}>
                    <div className='flex flex-col w-full' onClick={handleClick}>
                        <div className='flex w-full justify-between items-center'>
                            <div className='flex items-center '>
                                <p className='uppercase text-lg text-primary font-semibold mr-4'>{`Order #${data.order_header_id}`}</p>
                                {data.order_status == 2 && <p className='uppercase text-[#40916c] text-sm font-medium'>DELIVERED</p>}
                            </div>
                            <p className='uppercase text-sm text-slate-700 font-light'>{data.created_at}</p>
                        </div>
                        <div className='flex w-full justify-between items-center'>
                            <div className='flex flex-col'>
                                <p className='uppercase text-sm font-semibold'>{data.first_name + ' ' + data.last_name}</p>
                                <p className='uppercase text-sm'>{data.phone_number}</p>
                            </div>
                            <div className='flex flex-col items-end'>
                                <p className='uppercase text-sm text-slate-500 font-medium text-right'>{data.city}</p>
                                <p className='uppercase text-sm text-slate-500 font-medium text-right'>{data.district}</p>
                                <p className='uppercase text-sm text-slate-500 font-medium text-right'>{data.address_line1}</p>
                                <p className='uppercase text-sm text-slate-500 font-medium text-right'>{data.postal_code}</p>
                            </div>
                        </div>

                    </div>
                </ListItemButton>
                <div className='text-right'>
                    <ThemeProvider theme={theme}>
                        {
                            data.order_status == 1 && <LoadingButton loading={isLoading} onClick={() => handleDelivered(data.order_header_id)} disableElevation color='checked' variant="contained" sx={{ color: 'white' }}>Mark as Delivered</LoadingButton>
                        }
                        {
                            data.order_status == 2 && <LoadingButton loading={isLoading} onClick={() => handleProcessing(data.order_header_id)} disableElevation color='unchecked' variant="outlined">Mark as Processing</LoadingButton>
                        }
                    </ThemeProvider>
                </div>
            </div>
            <Collapse in={open} timeout="auto" unmountOnExit sx={{ mt: '10px' }}>
                {details.map((data) => (
                    <List sx={{ padding: '8px 16px' }} component="div" disablePadding>
                        <div className='md:flex-row flex-col flex justify-between'>
                            <div className='flex flex-col'>
                                <p className='uppercase font-medium text-primary'>Product NAME</p>
                                <p>{data.product_name}</p>
                            </div>
                            <div className='flex flex-col'>
                                <p className='uppercase font-medium text-primary'>Color</p>
                                <p>{data.colorName}</p>
                            </div>
                            <div className='flex flex-col'>
                                <p className='uppercase font-medium text-primary'>Size</p>
                                <p>{data.sizeLabel}</p>
                            </div>
                            <div className='flex flex-col'>
                                <p className='uppercase font-medium text-primary'>Quantity</p>
                                <p>{data.quantity}</p>
                            </div>
                            <div className='flex flex-col justify-end items-end'>
                                <p className='uppercase font-medium text-primary'>amount</p>
                                <p>{'$' + data.total_amount}</p>
                            </div>
                        </div>
                    </List>
                ))}
            </Collapse>


        </div>
    )
}

export default OrderDetails