import React, { useState, useContext, useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import HeaderLogo from "../assets/logos/Header_Logo.svg";
import useAuth from "../hooks/useAuth";
import FormTextInput from "../Components/common/FormTextInput";
import TransitionAlert from "../Components/common/SnackbarAlert";
import {
  loginUser,
  sendPasswordResetLink,
  setCookie,
} from "../services/AuthService";
import {
  loginFormSchema,
  resetPasswordEmailFormSchema,
} from "../validations/AuthValidation";
import { getYupValidationErrors } from "../validations/ValidationHelper";
import Button from "../Components/Button";
export default function LoginPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordResetEmail, setPasswordResetEmail] = useState("");
  const [loginResult, setLoginResult] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  // const [isValidating, errors, validate] = useValidation(loginFormSchema);
  const { login } = useAuth();
  const navigate = useNavigate();
  const [inputErrors, setInputErrors] = useState({});
  const [sendPasswordResetLinkResult, setSendPasswordResetLinkResult] =
    useState({});
  const [passwordResetForm, setPasswordResetForm] = useState(false);

  const handleForgotPassword = async () => {
    setIsLoading(true);
    try {
      await resetPasswordEmailFormSchema.validate(
        { passwordResetEmail },
        { abortEarly: false }
      );
      let result = await sendPasswordResetLink(passwordResetEmail);
      setSendPasswordResetLinkResult({
        messageType: "success",
        message: result.message,
        title: "Success!",
      });
      setInputErrors({});
      setShowAlert(true);
    } catch (e) {
      if (e.name === "ValidationError") {
        let errors = getYupValidationErrors(e);
        setInputErrors(errors);
      } else {
        setSendPasswordResetLinkResult({
          messageType: "error",
          message: e.message,
          title: "Something went wrong!",
        });
        setShowAlert(true);
      }
    }
    setIsLoading(false);
  };

  const handleLogin = async () => {
    setIsLoading(true);
    setShowAlert(false);
    try {
      await loginFormSchema.validate(
        { email, password },
        { abortEarly: false }
      );

      let result = await loginUser(email, password);
      // await setCookie();
      login(result.data.token, result.data.user_type);
      // console.error(result.data.token);
      // if (result.data.user_type == 'admin')
      //   navigate("/admin/products", { replace: true });
      // else navigate("/orders", { replace: true });
    } catch (e) {
      if (e.name === "ValidationError") {
        let errors = getYupValidationErrors(e);
        setInputErrors(errors);
      } else {
        setLoginResult({
          messageType: "error",
          message: e.message,
          title: "Something went wrong!",
        });
        setShowAlert(true);
      }
    }
    setIsLoading(false);
  };

  const closeAlert = () => {
    setShowAlert(false);
  };

  return (
    <div className="flex bg-backgroundColor">
      <div className="flex flex-1 flex-col items-center">
        <Link to={"/"}>
          <img
            className="w-52 h-auto mt-4"
            src={HeaderLogo}
            alt="Vacosta Style Logo"
          />
        </Link>
        <div className="mt-8 md:mt-12 lg:mt-20 w-10/12 sm:w-9/12 md:w-5/12">
          {passwordResetForm ? (
            <>
              <h1 className="text-primary text-4xl font-semibold border-b-4 border-b-gray-50 pb-4">
                SIGN IN
              </h1>
              <TransitionAlert
                open={showAlert}
                message={sendPasswordResetLinkResult.message}
                severity={sendPasswordResetLinkResult.messageType}
                title={sendPasswordResetLinkResult.title}
                handleClose={closeAlert}
              />

              <FormTextInput
                id="passwordResetEmail"
                label="Email"
                placeholder="Email..."
                value={passwordResetEmail}
                onChange={setPasswordResetEmail}
                error={inputErrors.passwordResetEmail}
              />
              <div className="mt-7" onClick={handleForgotPassword}>
                <Button
                  disabled={isLoading}
                  bg_color={"#C84027"}
                  color={"white"}
                  label={isLoading ? "Loading ..." : "SEND EMAIL"}
                ></Button>
              </div>
              <p
                className="mt-5 text-gray-500 font-light text-right hover:cursor-pointer"
                onClick={() => {
                  setPasswordResetForm(false);
                  setPasswordResetEmail("");
                }}
              >
                Switch to Login
              </p>
            </>
          ) : (
            <>
              <h1 className="text-primary text-4xl font-semibold border-b-4 border-b-gray-50 pb-4">
                SIGN IN
              </h1>
              <p className="mt-5 mb-5 font-light tracking-wide text-right">
                Don't have an account?{" "}
                <NavLink to="/auth/register">
                  <span className="font-semibold text-primary hover:cursor-pointer">
                    SIGN UP
                  </span>
                </NavLink>
              </p>
              <TransitionAlert
                open={showAlert}
                message={loginResult.message}
                severity={loginResult.messageType}
                title={loginResult.title}
                handleClose={closeAlert}
              />
              <FormTextInput
                id="email"
                label="Email"
                placeholder="Email..."
                value={email}
                onChange={setEmail}
                error={inputErrors.email}
              />
              <FormTextInput
                id="password"
                label="Password"
                placeholder="Password..."
                value={password}
                onChange={setPassword}
                error={inputErrors.password}
              />
              <div className="mt-7" onClick={handleLogin}>
                <Button
                  disabled={isLoading}
                  bg_color={"#C84027"}
                  color={"white"}
                  label={isLoading ? "Loading ..." : "SIGN IN"}
                ></Button>
              </div>
              <p
                className="mt-5 text-gray-500 font-light text-right hover:cursor-pointer"
                onClick={() => {
                  setPasswordResetForm(true);
                }}
              >
                Forgot Password?
              </p>
            </>
          )}
        </div>
      </div>
      {/* <img
        src={require("../assets/images/login-picture.jpg")}
        className="w-0 lg:w-auto h-screen"
        alt=""
      /> */}
    </div>
  );
}
