import React, { useEffect } from "react";
import AdminSidebar from "../Components/common/AdminSidebar";
import { Link } from "react-router-dom";
import { Pagination, TextField } from "@mui/material";
import usePagination from "../Components/usePagination";
import { getVariants, searchProduct } from "../services/AdminService";
import ItemAdmin from "../Components/ItemAdmin";
import CustomButton from "../Components/Button";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";
export default function ProductAdminPage() {
  const [page, setPage] = React.useState(1);
  const [variants, setVariants] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  // const [empty, setEmpty] = React.useState(false);
  const params = useParams();
  const fetch = async () => {
    let variant = await getVariants(page);
    setVariants(variant);
  };
  const handleSearch = async (value) => {
    setSearchValue(value);
    let variants = await searchProduct(value, page);
    setVariants(variants);
  };
  // useEffect(() => {
  //   if (variants.length == 0 || variants.total == 0) setEmpty(true);
  //   else setEmpty(false);
  // }, [variants]);
  const PAGE_LENGTH = 24;
  let count = 1;
  let data = [];

  if (variants?.length != 0) {
    count = Math.ceil(variants.total / PAGE_LENGTH);
    data = variants.data;
  }

  const VARIANTS = usePagination(data, PAGE_LENGTH);

  const handlePage = (e, p) => {
    setPage(p);
    VARIANTS.jump(p);
  };
  useEffect(() => {
    fetch();
  }, [page]);

  return (
    <div className="flex flex-col lg:flex-row w-[95%] lg:w-[98%] mx-auto">
      <div className="w-full lg:w-1/4">
        <AdminSidebar />
      </div>
      <div className="w-full lg:w-3/4 lg:ml-6">
        <div className="w-full mx-auto mt-5">
          <div className="flex justify-between items-start">
            <h1 className="text-left uppercase text-2xl underline decoration-4 decoration-primary">
              Product Management
            </h1>
            <Link to="/admin/add/product">
              <CustomButton
                bg_color="#C84027"
                color="white"
                label={"Add Product"}
              >
                {" "}
              </CustomButton>
            </Link>
          </div>
          <div className="mt-5">
            <div className="flex items-center mb-4">
              <div className="2xl:w-[30%] w-[100%]">
                <TextField
                  fullWidth
                  label="Search by product name or code"
                  variant="filled"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.currentTarget.value)}
                />
              </div>
              <div className="ml-2 w-[20%]">
                <Button
                  variant="contained"
                  disableElevation
                  onClick={() => handleSearch(searchValue)}
                >
                  Search
                </Button>
              </div>
            </div>
            <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 w-full mb-6">
              {VARIANTS.currentData().map((data, index) => (
                <ItemAdmin
                  key={"variant-" + index}
                  item_name={data.product_name}
                  item_price={data.price}
                  thumbnail={data.thumbnail_url}
                  currency={data.currency}
                  quantity={data.quantity}
                  content={data}
                  sizeName={data.sizeName}
                  img_url={data.img_url}
                  colorName={data.colorName}
                  variant_id={data.variant_id}
                  img_data={data.img_data}
                  product_id={data.product_id}
                  is_available={data.is_available}
                ></ItemAdmin>
              ))}
            </div>
            <Pagination
              count={count}
              page={page}
              onChange={handlePage}
              variant="outlined"
              shape="rounded"
              className="mb-4 justify-center"
              sx={{ margin: "auto" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
