import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';

const AdminRoute = ({ isLoggedIn, isAdmin }) => {
    if (isLoggedIn && isAdmin)
        return <Outlet />
    else return <Navigate replace to="/orders" />;

}

export default AdminRoute