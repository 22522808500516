import React, { useEffect, useContext } from 'react'
import ITEM from './Item'
import Dropdown from './Dropdown'
import { Button, Checkbox, createTheme, Drawer, FormControlLabel, FormGroup, Input, Pagination, Skeleton, Slider, TextField, ThemeProvider } from '@mui/material'
import '../Components/Slider.css'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import '../Components/Item.css';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import { getFilteredProducts, getSubCategories } from '../services/ProductsService';
import usePagination from './usePagination'
import NoData from './NoData'
import priceIMG from '../assets/SVG/price.svg'
import BottomBar from './BottomBar'
import { GlobalContext } from '../context/GlobalContext'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Category from './Category'


// import { Button } from '../Components/Button';
const Products = ({ toggleDrawer, bottomState, wishList, setWishList }) => {
    const { cart } = useContext(GlobalContext);
    const theme = createTheme({
        palette: {
            primary: {
                // Purple and green play nicely together.
                main: '#C84027',
            },
        },
    });
    const [run, setRun] = React.useState(false);
    const [checked, setChecked] = React.useState([]);
    const [tempFilter, setFilterTemp] = React.useState([]);
    const [empty, setEmpty] = React.useState(null);
    const [zero, setZero] = React.useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    // const [checkSize, setCheckSize] = React.useState(initialState);
    const sortContent = ['Newest', 'Oldest', 'Lowest to Highest price', 'Highest to Lowest price'];
    const [sort, setSorted] = React.useState(sortContent[0]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [anchorPrice, setAnchorPrice] = React.useState(null);
    const openPrice = Boolean(anchorPrice);
    const [products, setProducts] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [subCategory, setSubCategory] = React.useState([]);
    const [min, setMin] = React.useState(0);
    const [max, setMax] = React.useState(0);
    const [price, setPrice] = React.useState([0, 0]);
    const [categoryName, setCategoryName] = React.useState('');
    const [checkedStock, setCheckedStock] = React.useState(false);
    const [priceHandler, setPriceHandler] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);

    const [scrollPosition, setScrollPosition] = React.useState(0);

    const params = useParams();
    const navigate = useNavigate();
    const fetch = async () => {
        setIsLoading(true);
        let product = await getFilteredProducts(checked, checkedStock, sort, price, parseInt(params.page_nb, 10), params.category_id);
        setIsLoading(false);
        setProducts(product.products);
        setCategoryName(product.category);

    }
    const { search } = useLocation();
    const fetchCategories = async () => {
        setIsLoading(true);
        let subcategories = await getSubCategories(params.category_id);
        setIsLoading(false);
        setSubCategory(subcategories);
    }
    // useEffect(() => {
    //     setRun(true);
    // }, []);
    useEffect(() => {
        fetchCategories();
    }, [params]);


    // useEffect(() => {
    //     var updatedList = [...checked];
    //     subCategory.map((data) => {
    //         updatedList = [...updatedList, data.category_id];
    //         setChecked(updatedList);
    //         setFilterTemp(updatedList);
    //     });
    // }, [subCategory]);

    useEffect(() => {
        if (products.length == 0 || products.total == 0) setEmpty(true);
        else setEmpty(false);
    }, [products]);

    useEffect(() => {
        fetch();
        console.log(params);
    }, [sort, page, params]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePriceClick = (event) => {
        setAnchorPrice(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClosePrice = () => {
        setAnchorPrice(null);
    };

    useEffect(() => {
        setPrice([parseInt(min, 10), parseInt(max, 10)]);
        if (priceHandler) fetch();
        setPriceHandler(false);
    }, [min, max]);

    // useEffect(() => {
    //     console.log(price);
    // }, [price]);

    const clearFilters = async () => {
        setChecked(tempFilter);
        setCheckedStock(false);
        setSorted(sortContent[0]);
        setMin(0);
        setMax(0);
        setPrice([0, 0]);
        setPriceHandler(true);
    }


    function handleSort(event, newValue) {
        setSorted(newValue);
    }
    const handleFilter = (event, value) => {
        var updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, value];
        } else {
            updatedList.splice(checked.indexOf(value), 1);
        }
        setChecked(updatedList);
    };

    const PAGE_LENGTH = 24;
    let count = 1;
    let data = [];

    if (products?.length != 0) {
        count = Math.ceil(products.total / PAGE_LENGTH);
        data = products.data
    }

    const PRODUCTS = usePagination(data, PAGE_LENGTH);

    const handlePage = (e, p) => {
        // window.scrollTo({
        //     top: 0,
        //     left: 0,
        //     behavior: 'smooth'
        // })
        navigate(`/products/${params.category_id}/${p}`)
        setPage(p);
        PRODUCTS.jump(p);
    };

    useEffect(() => {
        setPage(parseInt(params.page_nb, 10));
    }, [params.page_nb])
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, [scrollPosition])

    useEffect(() => {
        if (!isLoading)
            setTimeout(() => {
                window.scrollTo({
                    top: localStorage.getItem('scroll_p'),
                    behavior: 'smooth'
                });
                localStorage.setItem('scroll_p', 0)
            }, 100);

    }, [isLoading]);
    return (
        <div className='xl:container xl:mx-auto mx-4 mt-4 xl:px-4'>
            <div className='filters'>
                <div className='flex justify-between items-center'>
                    <h1 className='text-left uppercase text-2xl underline decoration-4 underline-offset-1 decoration-primary'>{categoryName}</h1>
                    <p className='text-gray-400 block lg:hidden' onClick={toggleDrawer('bottom', true)}>Filters</p>
                </div>
                <div className='grid xl:grid-cols-7 lg:grid-cols-6 md:grid-cols-5 sm:grid-cols-3 grid-cols-2 gap-1 flex-wrap w-full mb-2 mt-2'>
                    {subCategory.map((data, index) => (
                        <Link to={`/products/sub/${data.category_id}/1`} key={"subC-" + index}>
                            <Category page={2} category={data.category_name}></Category>
                        </Link>
                    ))}
                </div>
                <div className='filter-content py-4 flex-row justify-between items-center hidden lg:flex'>
                    <div className='flex'>
                        {/* <Dropdown chosenFilters={checked} title={'Category'} arrayContent={subCategory} id="category_id" name="category_name" handleFilter={handleFilter} /> */}
                        {/* <Dropdown chosenFilters={checkSize} title={'Size'} arrayContent={['XS', 'S', 'M', 'L', 'XL']} id="Size" handleFilter={handleFilterSizes} /> */}

                        <Button
                            sx={{ backgroundColor: "#F5F8FB", color: "#C84027", paddingX: "16px", paddingY: "12px", marginRight: '12px', fontWeight: "bold", fontFamily: "Lato" }}
                            onClick={handleClick}
                        >
                            {sort} <KeyboardArrowDownRoundedIcon></KeyboardArrowDownRoundedIcon>
                        </Button>
                        <FormControl>
                            <Menu
                                sx={{ fontFamily: "Lato" }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                TransitionComponent={Fade}
                            >
                                {sortContent.map((data) => (
                                    <MenuItem
                                        sx={{ fontFamily: "Lato", textTransform: "uppercase" }}
                                        key={data}
                                        value={data}
                                        onClick={(e) => handleSort(e, data)}
                                    >
                                        <div className='my-2'>{data}</div>

                                    </MenuItem>
                                ))}

                            </Menu>
                        </FormControl>

                        <Button
                            sx={{ backgroundColor: "#F5F8FB", color: "#C84027", paddingX: "16px", paddingY: "12px", fontWeight: "bold", fontFamily: "Lato" }}
                            onClick={handlePriceClick}
                        >
                            Price <KeyboardArrowDownRoundedIcon></KeyboardArrowDownRoundedIcon>
                        </Button>
                        <FormControl>
                            <Menu
                                sx={{ fontFamily: "Lato" }}
                                anchorEl={anchorPrice}
                                open={openPrice}
                                onClose={handleClosePrice}
                                TransitionComponent={Fade}
                            >

                                <div className='m-4'>
                                    <ThemeProvider theme={theme}>
                                        <div className='flex justify-between'>
                                            <TextField
                                                // autoComplete={false}
                                                value={min}
                                                id="min"
                                                color='primary'
                                                label="Min."
                                                type="number"
                                                onChange={(e) => setMin(e.target.value)}
                                            />
                                            <img src={priceIMG} className="rotate-90 mx-2"></img>
                                            <TextField
                                                value={max}
                                                id="max"
                                                color='primary'
                                                label="Max."
                                                type="number"
                                                onChange={(e) => setMax(e.target.value)}
                                            />
                                        </div>

                                        <Button disableElevation color='primary' onClick={fetch} variant='contained' fullWidth sx={{ marginTop: '16px' }}>Apply Filters</Button>
                                    </ThemeProvider>
                                </div>

                            </Menu>
                        </FormControl>
                        {/* <FormControlLabel sx={{ fontFamily: "Lato", textTransform: 'uppercase', color: '#C84027', ml: '16px' }} control={<Checkbox checked={checkedStock} onChange={() => setCheckedStock(!checkedStock)} />} label="In Stock Only" /> */}
                    </div>


                    <p className='cursor-pointer hover:underline text-gray-500 font-normal' onClick={clearFilters}>Clear Filters</p>


                </div>
            </div>

            {isLoading &&
                <div className='grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 flex-wrap w-full mb-4'>
                    <Skeleton variant="rectangular" sx={{ bgcolor: "#FFF6EB", mt: '16px' }} width={'100%'} height={'550px'} animation="wave"></Skeleton>
                    <Skeleton variant="rectangular" sx={{ bgcolor: "#FFF6EB", mt: '16px' }} width={'100%'} height={'550px'} animation="wave"></Skeleton>
                    <Skeleton variant="rectangular" sx={{ bgcolor: "#FFF6EB", mt: '16px' }} width={'100%'} height={'550px'} animation="wave"></Skeleton>
                    <Skeleton variant="rectangular" sx={{ bgcolor: "#FFF6EB", mt: '16px' }} width={'100%'} height={'550px'} animation="wave"></Skeleton>
                </div>
            }
            {
                !isLoading && empty ? <NoData /> : <div className='w-full mt-4 lg:mt-0'>
                    <div className='grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 flex-wrap w-full mb-4'>
                        {PRODUCTS.currentData().map((data, index) => (
                            <ITEM link_type={0} scrollPosition={scrollPosition} isLoading={isLoading} key={"item-" + index} item_name={data.product_name} item_price={data.price} thumbnail={data.img_url} currency={data.currency} content={data} img_data={data.img_data} overall_quantity={data.overall_quantity} wishList={wishList} setWishList={setWishList}></ITEM>

                        ))}
                    </div>

                    <Pagination count={count} page={page} onChange={handlePage} variant="outlined" shape="rounded" className='mb-4 justify-center' />
                </div>
            }
            <Drawer
                anchor="bottom"
                open={bottomState["bottom"]}
                onClose={toggleDrawer('bottom', false)}
                onOpen={toggleDrawer('bottom', true)}
                PaperProps={{
                    sx: { width: "100%" },
                }}
            >
                <BottomBar toggleDrawer={toggleDrawer} checked={checked} sort={sort} clearFilters={clearFilters} sortContent={sortContent} arrayContent={subCategory} chosenFilters={checked} handleFilter={handleFilter} theme={theme} setMin={setMin} setMax={setMax} min={min} max={max} priceIMG={priceIMG} fetch={fetch} handleSort={handleSort} />
            </Drawer>
        </div >
    )
}

export default Products