import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AdminSidebar from "../Components/common/AdminSidebar";
import { default as CustomButton } from "../Components/Button";
import { Avatar, Button, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormTextInput from "../Components/common/FormTextInput";
import {
  addType,
  addRootCategory,
  addSubCategory,
  editType,
  editCategory,
  deleteCategory,
  deleteType,
} from "../services/AdminService";
import {
  getRootCategories,
  getSubCategories,
  getTypes,
  getAllCategories,
} from "../services/CategoryService";
import {
  productCreationSchema,
  categoriesSchema,
  rootSchema,
  subSchema,
} from "../validations/AdminValidation";
import { getYupValidationErrors } from "../validations/ValidationHelper";
import EasyEdit, { Types } from "react-easy-edit";
import TransitionAlert from "../Components/common/SnackbarAlert";
import styles from "../Components/category.css";
export default function CategoryAdminPage() {
  const [rootCategories, setRootCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [chosen, setChosen] = useState(0);
  const [chosenT, setChosenT] = useState(0);
  const [chosenType, setChosenType] = useState({});
  const [chosenRootCategory, setChosenRootCategory] = useState({});

  const [openRoot, setOpenRoot] = React.useState(false);
  const [openCategory, setOpenCategory] = React.useState(false);
  const [openSub, setOpenSub] = React.useState(false);

  const [open, setOpen] = useState(false);
  const [openType, setOpenType] = useState(false);
  const handleClickOpen = (e) => {
    setChosen(e);
    setOpen(true);
  };
  const handleTypeClick = (e) => {
    setChosenT(e);
    setOpenType(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenType(false);
  };
  const handleConfirm = () => {
    handleDelete();
    handleClose();
  };
  const handleConfirmType = () => {
    handleDeleteType();
    handleClose();
  };
  const [type, setType] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [sub, setSub] = React.useState("");

  const [allCategories, setAllCategories] = React.useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [productCreationResult, setProductCreationResult] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [inputErrors, setInputErrors] = useState({});
  const fetchTypes = async () => {
    let types = await getTypes();
    setTypes(types);
  };

  const handleEditType = async (type_id, type_name) => {
    await editType(type_id, type_name);
  };
  const handleEditCategory = async (category_id, category_name) => {
    await editCategory(category_id, category_name);
  };

  const getAll = async () => {
    let categories = await getAllCategories();
    setAllCategories(categories);
  };

  const handleChosenType = (event) => {
    setChosenType(event.target.value);
  };
  const handleChosenRoot = (event) => {
    setChosenRootCategory(event.target.value);
    // console.log(chosenRootCategory);
  };
  // useEffect(() => {
  const fetchCategories = async (type_id) => {
    let categories = await getRootCategories(type_id);
    setRootCategories(categories.data);
    console.log(categories.data);
  };
  const handleDelete = async () => {
    setIsLoading(true);
    setShowAlert(false);
    setInputErrors({});
    try {
      let result = await deleteCategory(chosen);
      console.log(result);
      setProductCreationResult({
        messageType: "success",
        message: result.message,
        title: "Success!",
      });
      // getTypes();
      getAll();
      setInputErrors({});
      setShowAlert(true);
    } catch (e) {
      setProductCreationResult({
        messageType: "error",
        message: e.message,
        title: "Something went wrong!",
      });
      setShowAlert(true);
    }
    setIsLoading(false);
  };

  const handleDeleteType = async () => {
    setIsLoading(true);
    setShowAlert(false);
    setInputErrors({});
    try {
      let result = await deleteType(chosenT);
      setProductCreationResult({
        messageType: "success",
        message: result.message,
        title: "Success!",
      });
      fetchTypes();
      // getAll();
      setInputErrors({});
      setShowAlert(true);
    } catch (e) {
      setProductCreationResult({
        messageType: "error",
        message: e.message,
        title: "Something went wrong!",
      });
      setShowAlert(true);
    }
    setIsLoading(false);
  };

  const handleClickOpenRoot = () => {
    setOpenRoot(true);
  };
  const handleAddRoot = async (type) => {
    await addType(type);
  };

  const handleClickOpenCategory = () => {
    setOpenCategory(true);
  };
  const handleClickOpenSub = () => {
    setOpenSub(true);
  };
  const handleCloseRoot = () => {
    setOpenRoot(false);
    setType("");
  };

  const handleCloseCategory = () => {
    setOpenCategory(false);
    setCategory("");
  };
  const handleCloseSub = () => {
    setOpenSub(false);
    setSub("");
  };
  useEffect(() => {
    fetchTypes();
    getAll();
    // fetchAllCategories();
  }, []);
  useEffect(() => {
    fetchCategories(chosenType);
  }, [chosenType]);

  const handleAddType = async () => {
    setIsLoading(true);
    setShowAlert(false);
    setInputErrors({});
    try {
      await categoriesSchema.validate({ type }, { abortEarly: false });

      let result = await addType(type);
      setProductCreationResult({
        messageType: "success",
        message: result.message,
        title: "Success!",
      });
      fetchTypes();
      setInputErrors({});
      setShowAlert(true);
      handleCloseRoot();
    } catch (e) {
      if (e.name === "ValidationError") {
        console.log(e);
        let errors = getYupValidationErrors(e);
        setInputErrors(errors);
      } else if (e.name === "ImageValidationError") {
        console.log(e);
        setInputErrors({ productImages: e.errors });
      } else {
        console.log(e);
        setProductCreationResult({
          messageType: "error",
          message: e.message,
          title: "Something went wrong!",
        });
        setShowAlert(true);
      }
    }
    setIsLoading(false);
  };
  const handleAddCategory = async () => {
    setIsLoading(true);
    setShowAlert(false);
    setInputErrors({});
    try {
      await rootSchema.validate({ category }, { abortEarly: false });

      let result = await addRootCategory(chosenType, category);
      setProductCreationResult({
        messageType: "success",
        message: result.message,
        title: "Success!",
      });
      getAll();
      setInputErrors({});
      setShowAlert(true);
      handleCloseCategory();
    } catch (e) {
      if (e.name === "ValidationError") {
        console.log(e);
        let errors = getYupValidationErrors(e);
        setInputErrors(errors);
      } else if (e.name === "ImageValidationError") {
        console.log(e);
        setInputErrors({ productImages: e.errors });
      } else {
        console.log(e);
        setProductCreationResult({
          messageType: "error",
          message: e.message,
          title: "Something went wrong!",
        });
        setShowAlert(true);
      }
    }
    setIsLoading(false);
  };

  const handleAddSub = async () => {
    setIsLoading(true);
    setShowAlert(false);
    setInputErrors({});
    try {
      await subSchema.validate({ sub }, { abortEarly: false });

      let result = await addSubCategory(chosenRootCategory, sub);
      setProductCreationResult({
        messageType: "success",
        message: result.message,
        title: "Success!",
      });

      getAll();
      setInputErrors({});
      setShowAlert(true);
      handleCloseSub();
    } catch (e) {
      if (e.name === "ValidationError") {
        console.log(e);
        let errors = getYupValidationErrors(e);
        setInputErrors(errors);
      } else if (e.name === "ImageValidationError") {
        console.log(e);
        setInputErrors({ productImages: e.errors });
      } else {
        console.log(e);
        setProductCreationResult({
          messageType: "error",
          message: e.message,
          title: "Something went wrong!",
        });
        setShowAlert(true);
      }
    }
    setIsLoading(false);
  };
  const closeAlert = () => {
    setShowAlert(false);
  };
  return (
    <div className="flex flex-col lg:flex-row w-[95%] lg:w-[98%] mx-auto">
      <div className="w-full lg:w-1/4">
        <AdminSidebar />
      </div>

      <div className="w-full lg:w-3/4 lg:ml-6">
        <TransitionAlert
          open={showAlert}
          message={productCreationResult.message}
          severity={productCreationResult.messageType}
          title={productCreationResult.title}
          handleClose={closeAlert}
        />
        <div className="w-full">
          <div className="flex flex-col md:flex-row justify-between items-center mt-5">
            <h1 className="text-left uppercase text-2xl underline decoration-4 underline-offset-0 decoration-primary">
              Category Management
            </h1>
            <div className="flex flex-row items-center">
              <div className="mr-2" onClick={handleClickOpenRoot}>
                <CustomButton
                  bg_color="#C84027"
                  label="Add Type"
                  color="white"
                ></CustomButton>
              </div>
              {/* <AddCategory handleClose={handleCloseRoot} open={openRoot} category="Type"></AddCategory> */}
              <Dialog
                open={openRoot}
                onClose={handleCloseRoot}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle>
                  <p className="uppercase text-primary text-3xl"> Add type</p>
                </DialogTitle>
                <DialogContent>
                  <FormTextInput
                    placeholder="Type"
                    value={type}
                    onChange={setType}
                    error={inputErrors.type}
                  ></FormTextInput>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseRoot}>Dismiss</Button>
                  <Button
                    onClick={() => handleAddType()}
                    autoFocus
                    variant="contained"
                    disableElevation
                  >
                    SUBMIT
                  </Button>
                </DialogActions>
              </Dialog>
              <div className="mr-2" onClick={handleClickOpenCategory}>
                <CustomButton
                  bg_color="#C84027"
                  label="Add Category"
                  color="white"
                ></CustomButton>
              </div>
              <Dialog
                open={openCategory}
                onClose={handleCloseCategory}
                disableAutoFocus={true}
                fullWidth
                maxWidth={"sm"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle>
                  <p className="uppercase text-primary text-3xl">
                    {" "}
                    Add category
                  </p>
                </DialogTitle>
                <DialogContent>
                  <FormControl fullWidth sx={{ minWidth: 120, mt: 2 }}>
                    <InputLabel>Type</InputLabel>
                    <Select
                      defaultValue={""}
                      label="Choose type"
                      onChange={(e) => handleChosenType(e)}
                    >
                      {types.map((data) => (
                        <MenuItem value={data.type_id}>
                          {data.type_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* <TextField id="outlined-basic" label="Category" variant="outlined" value={category} onChange={setCategory} sx={{ mt: 2 }} /> */}
                  <FormTextInput
                    placeholder="Category"
                    value={category}
                    onChange={setCategory}
                  ></FormTextInput>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseCategory}>Dismiss</Button>
                  <Button
                    onClick={() => handleAddCategory(chosenType, category)}
                    variant="contained"
                    disableElevation
                  >
                    SUBMIT
                  </Button>
                </DialogActions>
              </Dialog>
              <div className="mr-2" onClick={handleClickOpenSub}>
                <CustomButton
                  bg_color="#C84027"
                  label="Add Sub Category"
                  color="white"
                ></CustomButton>
              </div>
              <Dialog
                open={openSub}
                onClose={handleCloseSub}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle>
                  <p className="uppercase text-primary text-3xl">
                    {" "}
                    Add sub-category
                  </p>
                </DialogTitle>
                <DialogContent>
                  <FormControl fullWidth sx={{ minWidth: 120, mt: 2 }}>
                    <InputLabel>Type</InputLabel>
                    <Select
                      defaultValue={""}
                      label="Choose type"
                      onChange={(e) => handleChosenType(e)}
                    >
                      {types.map((data) => (
                        <MenuItem value={data.type_id}>
                          {data.type_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth sx={{ minWidth: 120, mt: 2 }}>
                    <InputLabel>Category</InputLabel>
                    <Select
                      defaultValue={""}
                      label="Choose type"
                      onChange={(e) => handleChosenRoot(e)}
                    >
                      {rootCategories.map((data) => (
                        <MenuItem value={data.category_id}>
                          {data.category_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormTextInput
                    placeholder="Subcategory"
                    value={sub}
                    onChange={setSub}
                  ></FormTextInput>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseSub}>Dismiss</Button>
                  <Button
                    onClick={() => handleAddSub(chosenRootCategory, sub)}
                    variant="contained"
                    disableElevation
                  >
                    SUBMIT
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
          <div className="mt-5">
            <TableContainer component={Paper} sx={{ maxHeight: "400px" }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontFamily: "Lato" }}>
                      <p className="uppercase font-bold text-primary text-xl">
                        Type ID
                      </p>
                    </TableCell>
                    <TableCell sx={{ fontFamily: "Lato" }}>
                      <p className="uppercase font-bold text-primary text-xl">
                        Type Name
                      </p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {types.map((row, index) => (
                    <TableRow
                      key={"types_categories-" + index}
                      // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" sx={{}}>
                        <p className="text-slate-500 text-lg font-semibold">
                          {row.type_id}
                        </p>
                      </TableCell>
                      <TableCell component="th" scope="row" sx={{}}>
                        <EasyEdit
                          className={styles}
                          type={Types.TEXT}
                          value={row.type_name}
                          onSave={(val) => handleEditType(row.type_id, val)}
                        />
                        <div
                          className="text-xs text-red-500 cursor-pointer"
                          onClick={() => handleTypeClick(row.type_id)}
                        >
                          Delete
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer
              component={Paper}
              sx={{ mt: "20px", maxHeight: "400px" }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontFamily: "Lato" }}>
                      <p className="uppercase font-bold text-primary text-xl">
                        Category ID
                      </p>
                    </TableCell>
                    <TableCell sx={{ fontFamily: "Lato" }}>
                      <p className="uppercase font-bold text-primary text-xl">
                        Parent ID
                      </p>
                    </TableCell>
                    <TableCell sx={{ fontFamily: "Lato" }}>
                      <p className="uppercase font-bold text-primary text-xl">
                        Category Name
                      </p>
                    </TableCell>
                    <TableCell sx={{ fontFamily: "Lato" }}>
                      <p className="uppercase font-bold text-primary text-xl">
                        Type ID
                      </p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Confirm Delete</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Are you sure you want to delete this category?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Cancel</Button>
                      <Button onClick={handleConfirm} color="error">
                        Delete
                      </Button>
                    </DialogActions>
                  </Dialog>

                  <Dialog open={openType} onClose={handleClose}>
                    <DialogTitle>Confirm Delete</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Are you sure you want to delete this category?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Cancel</Button>
                      <Button onClick={handleConfirmType} color="error">
                        Delete
                      </Button>
                    </DialogActions>
                  </Dialog>
                  {allCategories.map((row, index) => (
                    <>
                      <TableRow
                        key={"types_categories-" + index}
                        // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row" sx={{}}>
                          <p className="text-slate-500 text-lg font-semibold">
                            {row.category_id}
                          </p>
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{}}>
                          <p className="text-slate-500 text-lg font-semibold">
                            {row.parent_id}
                          </p>
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{}}>
                          <EasyEdit
                            type={Types.TEXT}
                            value={row.category_name}
                            onSave={(val) =>
                              handleEditCategory(row.category_id, val)
                            }
                          />
                          <div
                            className="text-xs text-red-500 cursor-pointer"
                            onClick={() => handleClickOpen(row.category_id)}
                          >
                            Delete
                          </div>
                        </TableCell>
                        <TableCell component="th" scope="row" sx={{}}>
                          <p className="text-slate-500 text-lg font-semibold">
                            {row.gender_or_type_id != "null"
                              ? row.gender_or_type_id
                              : ""}
                          </p>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
