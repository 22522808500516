const getYupValidationErrors = (e) => {
  return e.inner
    .map((e) => ({ [e.path]: e.errors }))
    .reduce(
      (obj, error) => ({
        [Object.keys(error)[0]]: error[Object.keys(error)[0]],
        ...obj,
      }),
      {}
    );
};


export {getYupValidationErrors}