import * as yup from "yup";

const productCreationSchema = yup.object().shape({
  productName: yup.string().required("The product name field is required."),
  productSize: yup.array().min(1, "The product should have atleast one size."),
  productCategory: yup
    .string()
    .required("The product category field is required."),
  productPrice: yup
    .number()
    .positive("The product price should be positive.")
    .required("The product price field is required."),
  productColor: yup
    .array()
    .min(1, "The product should have atleast one color."),

  productSubCategory: yup
    .string()
    .required("The product sub category field is required."),
  productCode: yup.string().required("The product code field is required."),
});

const productEditSchema = yup.object().shape({
  productName: yup.string().required("The product name field is required."),
  productPrice: yup
    .number()
    .positive("The product price should be positive.")
    .required("The product price field is required."),
  productCategory: yup
    .string()
    .required("The product category field is required."),
  productSubCategory: yup
    .string()
    .required("The product sub category field is required."),
  productCode: yup.string().required("The product code field is required."),
});
const variantCreationSchema = yup.object().shape({
  productSize: yup.array().min(1, "The product should have atleast one size."),
  productColor: yup
    .array()
    .min(1, "The product should have atleast one color."),
});

const categoriesSchema = yup.object().shape({
  type: yup.string().required("The product type field is required."),
});

const rootSchema = yup.object().shape({
  category: yup.string().required("The category field is required."),
});

const subSchema = yup.object().shape({
  sub: yup.string().required("The sub category field is required."),
});

const bannerSchema = yup.object().shape({
  // title: yup
  //   .string()
  //   .required("The title field is required."),
  // text: yup
  //   .string()
  //   .required("The text field is required."),
  order: yup
    .number()
    .positive("The order field should be positive.")
    .required("The order field is required."),
});
export {
  productCreationSchema,
  variantCreationSchema,
  productEditSchema,
  categoriesSchema,
  rootSchema,
  subSchema,
  bannerSchema,
};
