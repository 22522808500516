import React, { createContext, useReducer, useEffect } from "react";
import LoadingPage from "../Components/LoadingPage";
import useAuth from "../hooks/useAuth";
import { getWishlist } from "../services/OrdersService";

export const WishListContext = createContext([]);

const WishListProvider = ({ children }) => {
    const [wishList, setWishList] = React.useState([]);
    const { isLoggedIn, isLoadingProfile, admin } = useAuth();

    const handleGetWishlist = async () => {

        let response = await getWishlist();
        setWishList(response.wishlist);

    }
    useEffect(() => {
        if (!isLoadingProfile)
            if (isLoggedIn && !admin) {
                handleGetWishlist();
            }

    }, [isLoggedIn])
    // console.warn(wishList);

    return (
        <WishListContext.Provider value={{ wishList, setWishList }}>
            {children}
        </WishListContext.Provider>
    )
}

export default WishListProvider

