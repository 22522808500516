import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import AdminSidebar from '../Components/common/AdminSidebar';
import FormSelectInput from '../Components/common/FormSelectInput';
import FormTextInput from '../Components/common/FormTextInput';
import { getSizesById, getColorsById, addVariant } from '../services/AdminService'
import { getColors, getSizes } from '../services/CategoryService';
import { Link } from 'react-router-dom';
import { default as ButtonCustom } from '../Components/Button';

import {
    getColorsDropdownList,
    getNecklinesDropdownList,
    getRootCategoriesDropdownList,
    getSeasonsDropdownList,
    getSizesDropdownList,
    getSleeveLengthsDropdownList,
    getSubCategoriesDropdownList,
    getTypesDropdownList,
} from "../helpers/CategoryHelpers";
import FormFileInput from '../Components/common/FormFileInput';
import { productCreationSchema, variantCreationSchema } from "../validations/AdminValidation";
import { getYupValidationErrors } from "../validations/ValidationHelper";
import { Online } from 'react-detect-offline';
import { Offline } from 'react-detect-offline';
import SnackbarAlert from '../Components/common/SnackbarAlert';

const EditVariant = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [hasDropdownError, setHasDropdownError] = useState(false);

    const [productCreationResult, setProductCreationResult] = useState({});
    const [showAlert, setShowAlert] = useState(false);


    const [colors, setColors] = useState([]);
    const [sizes, setSizes] = useState([]);

    const [productSize, setProductSize] = useState([]);
    const [productColor, setProductColor] = useState([]);

    const [files, setFiles] = useState({});
    const [images, setImages] = useState({});
    const [thumbnailImageColor, setThumbnailImageColor] = useState("");
    const [thumbnailImageIndex, setThumbnailImageIndex] = useState(-1);

    const [inputErrors, setInputErrors] = useState({});

    const variant_id = useParams();

    useEffect(() => {
        const loadDropdownValues = async () => {
            try {

                let resultColors = await getColors();
                let resultSizes = await getSizes();


                setColors(getColorsDropdownList(resultColors.data));
                setSizes(getSizesDropdownList(resultSizes.data));

            } catch (e) {
                console.log(e);
                // setHasDropdownError(true);
            }
        };
        loadDropdownValues();
    }, []);
    // const fetchAll = async () => {
    //     let resultColors = await getColors();
    //     let resultSizes = await getSizes();
    //     setColors(getColorsDropdownList(resultColors.data));
    //     setSizes(getSizesDropdownList(resultSizes.data));
    //     setColors(colors);
    //     setSizes(sizes);
    // }
    // useEffect(() => {
    //     fetchAll();
    // }, [])
    const addToArray = (array, value) => {
        return array.includes(value) ? array : [...array, value];
    };

    const removeFromArray = (array, index) => {
        return array.filter((_, idx) => index !== idx);
    };

    const removeFromObject = (object, key) => {
        return Object.keys(object)
            .filter((k) => k !== key)
            .reduce((obj, k) => {
                obj[k] = object[k];
                return obj;
            }, {});
    };

    const validateProductImages = () => {
        if (productColor.length > 0) {
            let productImagesError;
            productColor.forEach((color) => {
                let productImage = files[color];
                if (productImage.length === 0) {
                    productImagesError = {
                        ...productImagesError,
                        [color]: "Please add images to this product color.",
                    };
                }
            });
            if (productImagesError) {
                throw { name: "ImageValidationError", errors: productImagesError };
            }
        }
    };
    const handleAddVariant = async () => {
        setIsLoading(true);
        setShowAlert(false);
        setInputErrors({});
        try {
            await variantCreationSchema.validate(
                { productSize, productColor },
                { abortEarly: false }
            );
            validateProductImages();
            let result = await addVariant({
                variant_id,
                files,
                productSize,
                productColor,
            });
            console.log(result);
            setProductCreationResult({
                messageType: "success",
                message: result.message,
                title: "Success!",
            });
            setInputErrors({});
            setShowAlert(true);

        } catch (e) {

            if (e.name === "ValidationError") {
                let errors = getYupValidationErrors(e);
                setInputErrors(errors);
                console.log(errors);
            } else if (e.name === "ImageValidationError") {
                setInputErrors({ productImages: e.errors });
            } else {
                setProductCreationResult({
                    messageType: "error",
                    message: e.message,
                    title: "Something went wrong!",
                });
                setShowAlert(true);
            }
        }
        setIsLoading(false);
    };
    const closeAlert = () => {
        setShowAlert(false);
    };
    return (
        <div className="flex flex-col lg:flex-row w-[95%] lg:w-[98%] mx-auto">
            <div className="w-full lg:w-1/4">
                <AdminSidebar />
            </div>
            <SnackbarAlert
                open={showAlert}
                message={productCreationResult.message}
                severity={productCreationResult.messageType}
                title={productCreationResult.title}
                handleClose={closeAlert}
            />
            <div className="w-full lg:w-3/4 lg:ml-6">
                <div className="flex justify-between items-center mt-5">
                    <h1 className="text-left uppercase text-2xl underline decoration-4 underline-offset-0 decoration-primary">
                        Add Variant
                    </h1>
                </div>
                <FormSelectInput
                    multiple={true}
                    selectedValues={productSize}
                    id="productSize"
                    label="Product Size"
                    placeholder="Product Size..."
                    options={sizes}
                    value=""
                    onChange={(value) => {
                        const newProductSize = addToArray(productSize, value);
                        setProductSize(newProductSize);
                    }}
                    removeValue={(index) => {
                        const newProductSize = removeFromArray(
                            productSize,
                            index
                        );
                        setProductSize(newProductSize);
                    }}
                    error={inputErrors.productSize}
                />
                <FormSelectInput
                    multiple={true}
                    selectedValues={productColor}
                    id="productColor"
                    label="Product Color"
                    placeholder="Product Color..."
                    options={colors}
                    value={""}
                    onChange={(value) => {
                        const newProductColor = addToArray(productColor, value);
                        setImages({ ...images, [value]: [] });
                        setFiles({ ...files, [value]: [] });
                        setProductColor(newProductColor);
                    }}
                    removeValue={(index) => {
                        const newImages = removeFromObject(
                            images,
                            productColor[index]
                        );
                        const newFiles = removeFromObject(
                            files,
                            productColor[index]
                        );
                        const newProductColor = removeFromArray(
                            productColor,
                            index
                        );
                        setImages(newImages);
                        setFiles(newFiles);
                        setProductColor(newProductColor);
                    }}
                    error={inputErrors.productColor}
                />
                {productColor.map((color, index) => {
                    let colorLabel;
                    colors.forEach((c) => {
                        if (c.value == color) {
                            colorLabel = c.label;
                        }
                    });
                    return (
                        <FormFileInput
                            key={index}
                            id={`productImages${color}`}
                            label={`${colorLabel} Color Product Images`}
                            error={
                                inputErrors.productImages
                                    ? inputErrors.productImages[color]
                                    : ""
                            }
                            files={images[color]}
                            onChange={(selectedFiles) => {
                                const initialFiles = files[color] ? files[color] : [];
                                const newFiles = [...initialFiles, ...selectedFiles];
                                setFiles({ ...files, [color]: [...newFiles] });
                                const selectedImages = selectedFiles.map((file) =>
                                    URL.createObjectURL(file)
                                );
                                const initialImages = images[color]
                                    ? images[color]
                                    : [];
                                const newImages = [...initialImages, ...selectedImages];
                                setImages({ ...images, [color]: [...newImages] });

                            }}
                            removeFile={(index) => {
                                if (
                                    thumbnailImageColor === color &&
                                    thumbnailImageIndex === index
                                ) {
                                    setThumbnailImageColor("");
                                    setThumbnailImageIndex(-1);
                                }
                                const newImages = removeFromArray(images[color], index);
                                setImages({ ...images, [color]: [...newImages] });
                                const newFiles = removeFromArray(files[color], index);
                                setFiles({ ...files, [color]: [...newFiles] });
                            }}
                            handleFavorite={(index) => {
                                if (
                                    thumbnailImageColor === color &&
                                    thumbnailImageIndex === index
                                ) {
                                    setThumbnailImageColor("");
                                    setThumbnailImageIndex(-1);
                                } else {
                                    setThumbnailImageColor(color);
                                    setThumbnailImageIndex(index);
                                }
                            }}
                            favoriteIndex={
                                color === thumbnailImageColor ? thumbnailImageIndex : -1
                            }
                        />
                    );
                })}
                <div className="flex justify-between md:justify-end my-5">
                    <Link to="/admin/products">
                        <div className="md:mr-2">
                            <ButtonCustom bg_color='#f8f8f8' label="discard product" color="gray" disabled={isLoading} ></ButtonCustom>
                        </div>

                    </Link>
                    <div onClick={handleAddVariant}>
                        <Online>
                            <ButtonCustom bg_color='#C84027' label={isLoading ? "Loading ..." : "Add variant"} color="white" disabled={isLoading}></ButtonCustom>
                        </Online>
                        <Offline>
                            <ButtonCustom bg_color='#C84027' label={'No Internet'} color="white" disabled={true}></ButtonCustom>
                        </Offline>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default EditVariant