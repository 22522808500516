import React, { useEffect, useState, useRef } from 'react'
import Button from './Button';
import { useContext } from 'react';
import { GlobalContext } from '../context/GlobalContext';
import './Drawer.css'
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { getQuantityByID, calculateCartPrice, getCartQuantities } from '../services/ProductsService';

import EmptyCart from './EmptyCart';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const SideCart = ({ toggleDrawer, state, setState }) => {
    const { removeProductFromCart, cart, clearCart, addProductToCart } = useContext(GlobalContext);
    const [isLoading, setIsLoading] = React.useState(false);
    const [disabled, setDisabled] = React.useState(false);
    const [cartPrice, setCartPrice] = React.useState(0);
    const button = useRef(null);
    const priceCart = async (cart) => {
        let response = await calculateCartPrice(cart);
        setCartPrice(response)
    }
    const getQuantity = async (data) => {
        // setIsLoading(true);
        // let qty = await getQuantityByID(data.variant_id);
        // setIsLoading(false);

        // if (cartQty == qty.quantity) {
        //     setDisabled(true);
        // }
        // else if (cartQty > qty.quantity) {
        //     balanceCart(data.variant_id, qty.quantity)
        // }
        // else
        addProductToCart(data, 1);

    }

    // const handleCartQuantity = async (cart) => {
    //     let quantities = await getCartQuantities(cart);
    //     cart.map((data) => {
    //         quantities.forEach(element => {
    //             if (data.variant_id == element.variant_id)
    //                 if (data.cartQuantity > element.quantity)
    //                     balanceCart(data.variant_id, element.quantity);
    //         });
    //     })

    // }
    const navigate = useNavigate();
    const checkout = () => {
        setState({ ...state, ["right"]: false });
        // toggleDrawer('right', false);
        navigate('/orders');
        console.log(state);
    }
    useEffect(() => {
        priceCart(cart);

    }, [cart])

    // useEffect(() => {
    //     // handleCartQuantity(cart);
    //     // toggleDrawer('right', false);
    // }, [])


    return (
        <div className='bg-secondary p-6 md:p-10 h-screen overflow-scroll w-full flex flex-col justify-between'>

            <div className='flex flex-col mb-6'>
                <div className='flex items-center mb-4'>
                    <div className='mr-4 lg:hidden' onClick={toggleDrawer('right', false)}><ArrowBackIcon></ArrowBackIcon></div>
                    <h1 className='text-2xl uppercase text-primary font-semibold'>Shopping cart</h1>
                </div>
                <div className='max-h-[450px] xl:max-h-[450px] overflow-auto'>
                    {cart.length == 0 ? <EmptyCart /> : cart.map((data, index) =>
                        <div className='bg-white p-3 mb-4 flex relative' key={'cart-item-' + index} >
                            <img className='rounded-sm' width={"75px"} src={`${data.thumbnail_url}`}></img>
                            <div className='ml-4 flex flex-col mr-4 justify-between'>
                                <div className=''>
                                    <div className='text-md capitalize max-w-[150px] whitespace-nowrap text-ellipsis overflow-hidden'>
                                        {data.product_name}
                                    </div>
                                    <div className='flex'>
                                        <div className='size flex items-baseline'>
                                            <p className='text-xs text-gray-500 uppercase'>Size</p> <p className='ml-1 text-md font-semibold text-primary'>{data.sizeLabel}</p>
                                        </div>

                                        <div className='size flex items-baseline ml-4'>
                                            <p className='text-xs text-gray-500 uppercase'>Color</p> <p className='ml-1 text-md font-semibold text-primary'>{data.colorLabel}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='quantity-selector flex items-center'>
                                    <button className='left bg-secondary rounded-sm cursor-pointer active:shadow-sm p-1 flex flex-col justify-center hover:bg-slate-300 transition duration-200 ease-in-out' onClick={() => removeProductFromCart(data.variant_id)} disabled={isLoading ? true : false}><RemoveIcon fontSize="small"></RemoveIcon></button>
                                    <div className='quantity font-semibold text-primary mx-4'>{data.cartQuantity}</div>
                                    <button className='right bg-secondary rounded-sm cursor-pointer active:shadow-sm p-1 flex flex-col justify-center hover:bg-slate-300 transition duration-200 ease-in-out' onClick={() => getQuantity(data)} disabled={isLoading ? true : false}><AddIcon fontSize="small"></AddIcon></button>
                                </div>

                            </div>
                            {/* <div className='absolute text-sm bg-primary text-white -bottom-3 p-1 px-2 right-2 rounded-sm cursor-pointer uppercase hover:brightness-95 active:brightness-90' >Remove</div> */}
                        </div>

                    )}
                </div>
            </div>
            <div>
                <div className='flex justify-between items-end'>
                    <h1 className='uppercase font-semibold text-lg'>Total</h1>
                    <div className='flex items-end'>

                        <p className='uppercase font-medium text-2xl text-primary'>{'$ ' + (Math.round(cartPrice * 100) / 100).toFixed(2)}</p>
                    </div>
                </div>

                <div className='mt-2' onClick={() => clearCart()}>
                    <Button color={'#C84027'} bg_color={'white'} label={'Clear Cart'} ></Button>
                </div>
                {/* <Link to='/orders'><Button color={'white'} bg_color={'#C84027'} label={"checkout"}></Button></Link> */}
                <div onClick={checkout
                }><Button color={'white'} bg_color={'#C84027'} label={"checkout"}></Button></div>
            </div>
        </div >
    )
}
// 
export default SideCart