import React from 'react'
import empty from '../assets/SVG/empty-cart.svg';
const EmptyCart = () => {
    return (
        <div className='w-[85%] lg:w-2/5 mx-auto flex flex-col mt-8'>
            {/* <img src={empty} className='md:w-[75%] lg:w-full mx-auto' /> */}
            <h2 className='uppercase md:text-2xl sm:text-xl text-center text-primary mt-4 mb-4'>Cart is empty!</h2>
        </div>
    )
}

export default EmptyCart