import React, { useEffect, useState } from "react";
import AdminSidebar from "../Components/common/AdminSidebar";
import FormFileInput from "../Components/common/FormFileInput";
import FormSelectInput from "../Components/common/FormSelectInput";
import FormTextInput from "../Components/common/FormTextInput";

import { default as ButtonCustom } from "../Components/Button";
import { getProductById, updateProduct } from "../services/AdminService";
import { Link, useParams } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import {
  getColors,
  getNecklines,
  getRootCategories,
  getSeasons,
  getSizes,
  getSleeveLengths,
  getSubCategories,
  getTypes,
} from "../services/CategoryService";

import {
  getColorsDropdownList,
  getNecklinesDropdownList,
  getRootCategoriesDropdownList,
  getSeasonsDropdownList,
  getSizesDropdownList,
  getSleeveLengthsDropdownList,
  getSubCategoriesDropdownList,
  getTypesDropdownList,
} from "../helpers/CategoryHelpers";
import { productEditSchema } from "../validations/AdminValidation";
import { getYupValidationErrors } from "../validations/ValidationHelper";
import TransitionAlert from "../Components/common/SnackbarAlert";
import { Online } from "react-detect-offline";
import { Offline } from "react-detect-offline";

export default function EditProductAdminPage(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasDropdownError, setHasDropdownError] = useState(false);
  const [productName, setProductName] = useState("");
  const [productCategory, setProductCategory] = useState(0);
  const [productStyle, setProductStyle] = useState("");
  const [discountRate, setDiscountRate] = useState(0);
  const [productCode, setProductCode] = useState("");
  const [productID, setProductID] = useState(0);

  const [productColor, setProductColor] = useState(["Test1"]);
  const [productType, setProductType] = useState(0);
  const [productPattern, setProductPattern] = useState("");
  const [productDecoration, setProductDecoration] = useState("");
  const [productSilhouette, setProductSilhouette] = useState("");
  const [fabricType, setFabricType] = useState("");
  const [productNeckline, setProductNeckline] = useState("");
  const [productSleeveLength, setProductSleeveLength] = useState("");
  // const [productTags, setProductTags] = useState(['']);
  const [productPrice, setProductPrice] = useState(0);
  const [productAvailability, setProductAvailability] = useState(1);
  const [activationStatus, setActivationStatus] = useState(-1);
  const [dailyStatus, setDailyStatus] = useState(-1);
  const [productSeason, setProductSeason] = useState();
  const [inputErrors, setInputErrors] = useState({});
  const [productSubCategory, setProductSubCategory] = useState(0);
  const [types, setTypes] = useState([]);
  const [rootCategories, setRootCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [necklines, setNecklines] = useState([]);
  const [sleeveLengths, setSleeveLengths] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const product_id = useParams();

  const [productWeight, setProductWeight] = useState(0);

  const [productCreationResult, setProductCreationResult] = useState({});
  const [showAlert, setShowAlert] = useState(false);

  const closeAlert = () => {
    setShowAlert(false);
  };

  const fetchAll = async (id) => {
    let product = await getProductById(id);
    setProductName(product[0].product_name);
    setProductStyle(product[0].style);
    setProductCode(product[0].product_code);
    setProductSubCategory(product[0].category_id);
    setProductPrice(product[0].price);
    setProductSeason(product[0].season);
    setProductAvailability(product[0].is_available);
    setActivationStatus(product[0].active);
    setDailyStatus(product[0].is_daily);
    setDiscountRate(product[0].discount_rate);
  };
  useEffect(() => {
    fetchAll(product_id.product_id);
    setProductID(product_id.product_id);
  }, []);
  // useEffect(async () => {

  // }, [productSubCategory])
  useEffect(() => {
    const loadDropdownValues = async () => {
      try {
        let resultTypes = await getTypes();
        let resultColors = await getColors();
        let resultSizes = await getSizes();
        let resultNecklines = await getNecklines();
        let resultSleeveLengths = await getSleeveLengths();
        let resultSeasons = await getSeasons();
        setTypes(getTypesDropdownList(resultTypes));
        setColors(getColorsDropdownList(resultColors.data));
        setSizes(getSizesDropdownList(resultSizes.data));
        setNecklines(getNecklinesDropdownList(resultNecklines.data));
        setSleeveLengths(
          getSleeveLengthsDropdownList(resultSleeveLengths.data)
        );
        setSeasons(getSeasonsDropdownList(resultSeasons.data));
      } catch (e) {
        setHasDropdownError(true);
      }
    };
    loadDropdownValues();
  }, []);
  useEffect(() => {
    const loadCategoryDropdownValues = async () => {
      try {
        setProductCategory("");
        let resultCategories = await getRootCategories(productType);
        setRootCategories(getRootCategoriesDropdownList(resultCategories.data));
      } catch (e) {
        setHasDropdownError(true);
      }
    };
    if (productType) {
      loadCategoryDropdownValues();
    }
  }, [productType]);

  useEffect(() => {
    const loadSubCategoryDropdownValues = async () => {
      try {
        setProductSubCategory("");
        let resultSubCategories = await getSubCategories(productCategory);
        setSubCategories(
          getSubCategoriesDropdownList(resultSubCategories.data)
        );
      } catch (e) {
        setHasDropdownError(true);
      }
    };
    productCategory && loadSubCategoryDropdownValues();
  }, [productCategory]);

  const handleUpdateProduct = async () => {
    setIsLoading(true);
    setShowAlert(false);
    setInputErrors({});
    try {
      await productEditSchema.validate(
        {
          productName,
          productPrice,
          productCategory,
          productSubCategory,
          productCode,
        },
        { abortEarly: false }
      );

      let result = await updateProduct({
        productID,
        productName,
        productCode,
        productSubCategory,
        productSeason,
        productStyle,
        productColor,
        productPrice,
        productWeight,
        productPattern,
        productDecoration,
        productSilhouette,
        fabricType,
        discountRate,
        productNeckline,
        productSleeveLength,
        activationStatus,
        productAvailability,
        dailyStatus,
      });
      setProductCreationResult({
        messageType: "success",
        message: result.message,
        title: "Success!",
      });
      setInputErrors({});
      setShowAlert(true);
    } catch (e) {
      if (e.name === "ValidationError") {
        console.log(e);
        let errors = getYupValidationErrors(e);
        setInputErrors(errors);
      } else if (e.name === "ImageValidationError") {
        console.log(e);
        setInputErrors({ productImages: e.errors });
      } else {
        setProductCreationResult({
          messageType: "error",
          message: e.message,
          title: "Something went wrong!",
        });
        setShowAlert(true);
      }
    }
    setIsLoading(false);
  };

  return (
    <div className="flex flex-col lg:flex-row w-[95%] lg:w-[98%] mx-auto">
      <div className="w-full lg:w-1/4">
        <AdminSidebar />
      </div>

      <div className="w-full lg:w-3/4 lg:ml-6">
        <div className="flex justify-between items-center mt-5">
          <h1 className="text-left uppercase text-2xl underline decoration-4 underline-offset-0 decoration-primary">
            Edit Product
          </h1>
          <TransitionAlert
            open={showAlert}
            message={productCreationResult.message}
            severity={productCreationResult.messageType}
            title={productCreationResult.title}
            handleClose={closeAlert}
          />
        </div>
        <div className="w-full mt-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
          <FormTextInput
            id="productLabel"
            label="Product Name"
            placeholder="Product Name..."
            value={productName}
            onChange={setProductName}
            error={inputErrors.productName}
          />

          <FormTextInput
            className="w-3/6 mr-5"
            type="text"
            id="productCode"
            label="Product Code"
            placeholder="Product Code..."
            value={productCode}
            onChange={setProductCode}
            error={inputErrors.productCode}
          />
          <FormSelectInput
            // className="w-[30%]"
            id="productType"
            label="Product Type"
            placeholder="Product Type..."
            options={types}
            value={productType}
            onChange={setProductType}
            error={""}
          />
          <FormSelectInput
            // className="w-[30%]"
            id="productCategory"
            label="Product Category"
            placeholder="Product Category..."
            options={rootCategories}
            value={productCategory}
            onChange={setProductCategory}
            error={inputErrors.productCategory}
          />

          <FormSelectInput
            // className="w-[30%]"
            id="productSubCategory"
            label="Product Sub Category"
            placeholder="Product Sub Category..."
            options={subCategories}
            value={productSubCategory}
            onChange={setProductSubCategory}
            error={inputErrors.productSubCategory}
          />

          <FormTextInput
            className="w-3/6 mr-5"
            type="number"
            id="productPrice"
            label="Product Price"
            placeholder="Product Price..."
            value={productPrice}
            onChange={setProductPrice}
            error={inputErrors.productPrice}
          />

          <FormTextInput
            className="w-3/6 mr-5"
            type="number"
            id="discount_rate"
            label="Discount Rate"
            placeholder="Discount Rate"
            value={discountRate}
            onChange={setDiscountRate}
            error={""}
          />

          <FormSelectInput
            // className="w-[30%]"
            id="productSeason"
            label="Product Season"
            placeholder="Product Season..."
            options={seasons}
            value={productSeason}
            onChange={setProductSeason}
            error={""}
          />

          <FormTextInput
            className="w-3/6"
            type="text"
            id="productStyle"
            label="Product Style"
            placeholder="Product Style..."
            value={productStyle}
            onChange={setProductStyle}
            error={""}
          />

          <FormTextInput
            className="w-3/6 mr-5"
            type="text"
            id="pattern"
            label="Product Pattern"
            placeholder="Product Pattern"
            value={productPattern}
            onChange={setProductPattern}
            error={""}
          />

          <FormTextInput
            className="w-3/6 mr-5"
            type="text"
            id="fabric_type"
            label="Product Fabric type"
            placeholder="Product Fabric type"
            value={fabricType}
            onChange={setFabricType}
            error={""}
          />

          <FormTextInput
            className="w-3/6 mr-5"
            type="text"
            id="product_decoration"
            label="Product Decoration"
            placeholder="Product Decoration"
            value={productDecoration}
            onChange={setProductDecoration}
            error={""}
          />

          <FormTextInput
            className="w-3/6 mr-5"
            type="text"
            id="product_silhouette"
            label="Product Silhouette"
            placeholder="Product Silhouette"
            value={productSilhouette}
            onChange={setProductSilhouette}
            error={""}
          />
          {/* <FormSelectInput
            className="w-3/6"
            multiple={true}
            selectedValues={productTags}
            id="productTags"
            label="Product Tags"
            placeholder="Product Tags..."
            options={["Test1", "Test2", "Test3"]}
            value={""}
            onChange={(value) => {
              const newProductTags = addToArray(productTags, value);
              setProductTags(newProductTags);
            }}
            removeValue={(index) => {
              const newProductTags = removeFromArray(productTags, index);
              setProductTags(newProductTags);
            }}
            error={""}
          /> */}

          <FormControl>
            <FormLabel sx={{ fontFamily: "Lato" }}>
              <div className="text-lg text-formLabel font-bold lg:mt-5 mt-2">
                Activation Status
              </div>
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={activationStatus}
              onChange={(event) => setActivationStatus(event.target.value)}
            >
              <FormControlLabel
                sx={{ fontFamily: "Lato" }}
                value="1"
                control={<Radio />}
                label="Active"
              />
              <FormControlLabel
                sx={{ fontFamily: "Lato" }}
                value="0"
                control={<Radio />}
                label="Not Active"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel sx={{ fontFamily: "Lato" }}>
              <div className="text-lg text-formLabel font-bold lg:mt-5 mt-2">
                In Stock
              </div>
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={productAvailability}
              onChange={(event) => setProductAvailability(event.target.value)}
            >
              <FormControlLabel
                sx={{ fontFamily: "Lato" }}
                value="1"
                control={<Radio />}
                label="In Stock"
              />
              <FormControlLabel
                sx={{ fontFamily: "Lato" }}
                value="0"
                control={<Radio />}
                label="Not In Stock"
              />
            </RadioGroup>
          </FormControl>

          <FormControl>
            <FormLabel sx={{ fontFamily: "Lato" }}>
              <div className="text-lg text-formLabel font-bold lg:mt-5 mt-2">
                Daily
              </div>
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={dailyStatus}
              onChange={(event) => setDailyStatus(event.target.value)}
            >
              <FormControlLabel
                sx={{ fontFamily: "Lato" }}
                value="1"
                control={<Radio />}
                label="Daily"
              />
              <FormControlLabel
                sx={{ fontFamily: "Lato" }}
                value="0"
                control={<Radio />}
                label="Not Daily"
              />
            </RadioGroup>
          </FormControl>
        </div>

        <div className="flex justify-end my-5">
          <div className="md:mr-2">
            <Link to={"/admin/products"}>
              <ButtonCustom
                bg_color="#f8f8f8"
                label="discard product"
                color="gray"
              ></ButtonCustom>
            </Link>
          </div>
          <div onClick={handleUpdateProduct}>
            <Online>
              <ButtonCustom
                bg_color="#C84027"
                label={"Save Changes"}
                color="white"
              ></ButtonCustom>
            </Online>
            <Offline>
              <ButtonCustom
                bg_color="#C84027"
                label={"No Internet"}
                color="white"
                disabled={true}
              ></ButtonCustom>
            </Offline>
          </div>
        </div>
      </div>
    </div>
  );
}
