import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteWishlistItem } from '../services/OrdersService';
import usePagination from './usePagination';
import { Pagination } from '@mui/material';

const WishList = ({ wishList, setWishlist }) => {
    const [page, setPage] = React.useState(1);
    const handleDelete = async (product_id) => {
        let response = await deleteWishlistItem(product_id);
        setWishlist(response.data);
    }
    const PAGE_LENGTH = 24;
    let count = Math.ceil(wishList.length / PAGE_LENGTH);;

    const WISHLIST = usePagination(wishList, PAGE_LENGTH);

    const handlePage = (e, p) => {
        setPage(p);
        WISHLIST.jump(p);
    };
    return (
        <div className='xl:container xl:mx-auto mx-4 mt-4 xl:px-4'>
            <h1 className='text-2xl text-primary mt-2 lg:mt-0 mb-3 font-semibold uppercase'>Wish List</h1>
            <div className='grid 2xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-2'>
                {wishList.map((data, index) => (
                    <div key={'wish-' + index} className='p-4 flex bg-secondary rounded-sm '>
                        <img src={`${data.img_url}`} className="w-[100px] h-[132px]"></img>
                        <div className='flex flex-col justify-between w-full ml-4'>
                            <div>
                                <Link to={`product/${data.product_id}`}><p className='capitalize line-clamp-2'>{data.product_name}</p></Link>
                                <p className='font-light text-sm'>{data.product_code}</p>
                            </div>
                            <div className='flex justify-between items-end'>
                                <p className='text-md'>{'$ ' + (Math.round(data.price * 100) / 100).toFixed(2)}</p>
                                <div className='flex'>
                                    <button onClick={() => handleDelete(data.product_id)} className='bg-[#E63946] rounded-sm p-2 mr-2 flex flex-col justify-center hover:brightness-90 transition duration-200 ease-in-out'><DeleteIcon fontSize='small' sx={{ color: '#FFFFFF' }}></DeleteIcon></button>
                                    <Link to={`/product/${data.product_id}`}><button className='p-2 px-3 text-primary bg-white text-sm uppercase rounded-sm hover:brightness-90 transition duration-200 ease-in-out'>view item</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>))}
            </div>
            <Pagination count={count} page={page} onChange={handlePage} variant="outlined" shape="rounded" className='mb-4 mt-4 justify-center' />
        </div>
    )
}

export default WishList