import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from "axios"
import { useNavigate, } from 'react-router-dom';
import goToLogin from './Components/NavigationHelper'
import history from "./Components/history";
import LoadingPage from './Components/LoadingPage';
// const navigate = useNavigate();
axios.interceptors.response.use((res) => {
  const token = res.data.token;
  // console.log(res.data);
  if (token != null) {
    localStorage.setItem("vacosta_user_token", token);
  }
  return res;
},
  (err) => {

    if (err.response.status == 401) {
      sessionStorage.removeItem("admin_token");
      localStorage.removeItem("vacosta_user_token");
      sessionStorage.removeItem("admin");
    }
    if (err.response.status == 429) {
      return Promise.reject(err);
      // return (<LoadingPage></LoadingPage>)
    }
    return Promise.reject(err);
  }
)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
