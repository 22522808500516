
const getTypesDropdownList =  (types) => {
    return types.map((type) => ({
      value: type.type_id,
      label: type.type_name,
    }));
};

const getRootCategoriesDropdownList =  (categories) => {
    return categories.map((category) => ({
      value: category.category_id,
      label: category.category_name,
    }));
};

const getSubCategoriesDropdownList =  (categories) => {
    return categories.map((category) => ({
      value: category.category_id,
      label: category.category_name,
    }));
};

const getSizesDropdownList =  (sizes) => {
    return sizes.map((size) => ({
      value: size.size_id,
      label: size.label,
    }));
};

const getColorsDropdownList =  (colors) => {
    return colors.map((color) => ({
      value: color.color_id,
      label: color.name,
    }));
};

const getNecklinesDropdownList = (necklines) => {
  return necklines.map((neckline) => ({
    value: neckline.neckline_id,
    label: neckline.neckline_name,
  }));
};

const getSleeveLengthsDropdownList = (sleeves) => {
  return sleeves.map((sleeve) => ({
    value: sleeve.sleeve_id,
    label: sleeve.sleeve_name,
  }));
};

const getSeasonsDropdownList = (seasons) => {
  return seasons.map((season) => ({
    value: season.season_id,
    label: season.season_name,
  }));
};
export {
  getTypesDropdownList,
  getRootCategoriesDropdownList,
  getSubCategoriesDropdownList,
  getSizesDropdownList,
  getColorsDropdownList,
  getSleeveLengthsDropdownList,
  getNecklinesDropdownList,
  getSeasonsDropdownList,
};
