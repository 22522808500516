import React from 'react'

const Terms = () => {
    return (
        <div className='xl:container xl:mx-auto xl:px-4 mx-4'>
            <h1 className='text-left uppercase text-2xl lg:text-3xl underline decoration-4 underline-offset-1 decoration-primary my-4 lg:mt-8 lg:my-4 md:mr-16 !leading-relaxed'>Terms & Conditions</h1>
            <ol>
                <li>
                    <p className='font-semibold uppercase'>1. General</p>
                    <div className='text-justify leading-relaxed'>
                        These Terms of Service govern your use of our website located at vacosta-style.com and form a binding contractual agreement between you, the user of the Site and us. For that reason, these Terms are important and you should read them carefully and contact us with any questions before you use the Site.
                        By viewing or browsing the Site, you acknowledge that you have had sufficient chance to read and understand these Terms, and that you agree to be bound by them. If you do not agree to these Terms, you may not access or otherwise use the Site and the Services offered on the Site.

                        Under this agreement, the payment processing services for goods and/or services purchased on this website are provided by Vacosta Style, depending on the type of payment method used for the purchase of the goods and/or services.
                    </div>
                </li>
                <li className='mt-6'>
                    <p className='font-semibold uppercase'>2. Definitions</p>
                    <div className='text-justify leading-relaxed'>
                        In these Terms: Content means any and all data, text, software, images, audio or video material and other content, in any medium, provided vacosta-style.com  to you. Intellectual Property means all intellectual property rights, including all copyright, patents, trade marks, design rights, trade secrets, domain names, know-how and other rights of a similar nature, whether registrable or not and whether registered or not, and any applications for registration or rights to make such an application.
                        Site means vacosta-style.com.
                        Terms means these Terms of Use and the documents referred to in it.
                        User means a person who gains access to the Site.
                        You means you, the person using the Site.
                        Us, We, Our means vacosta-style.com.
                    </div>
                </li>
                <li className='mt-6'>
                    <p className='font-semibold uppercase'>3. ACCESSING THE SITE</p>
                    <div className='text-justify leading-relaxed'>
                        You acknowledge and agree that although we will use all reasonable efforts to ensure that the Site is available, temporary interruptions of the Site may occur. We shall not be liable to any person or entity for loss or damage incurred by such downtimes.
                        All Content and services provided on or through this site are provided "as is" and "as available" for your use. The Content is provided without warranties or conditions of any kind either express or implied, including but not limited to implied warranties and conditions of merchantability, fitness for a particular purpose or non-infringement. Your use of this site is solely at your risk.
                        We reserve the right to withdraw or amend the Services and Content we provide on the Site without notice. We may restrict access to some parts of or the entire Site, from time to time.
                    </div>
                </li>
                <li className='mt-6'>
                    <p className='font-semibold uppercase'>4. Privacy</p>
                    <div className='text-justify leading-relaxed'>
                        The terms on which we collect, use and disclose your personal information are set out in our Privacy Policy .
                    </div>
                </li>
                <li className='mt-6'>
                    <p className='font-semibold uppercase'>5. Sale Terms</p>
                    <div className='text-justify leading-relaxed'>
                        At the moment of purchase, you are entering into an agreement with vacosta style
                        You acknowledge and agree that any purchases of products made through the Site are covered by our Delivery and Returns and Exchanges terms and conditions and clause 6 below.
                    </div>
                </li>
                <li className='mt-6'>
                    <p className='font-semibold uppercase'>6. Force Majeure</p>
                    <div className='text-justify leading-relaxed'>
                        We shall be under no liability to you in respect of anything that, if not for this provision, would or might constitute a breach of these Terms, where this arises out of circumstances beyond our control, including but not limited to:
                        <ul className='indent-4 font-medium'>
                            <li>Acts of God;</li>
                            <li>Natural disasters;</li>
                            <li>Sabotage;</li>
                            <li>Accident;</li>
                            <li>Riot;</li>
                            <li>Shortage of supplies, equipment, and materials;</li>
                            <li>Strikes and lockouts;</li>
                            <li>Civil unrest;</li>
                            <li>Computer hacking; or</li>
                            <li>Malicious damage.</li>
                        </ul>
                    </div>
                </li>
            </ol>
        </div>
    )
}

export default Terms