import React, { useEffect } from "react";
import "./Item.css";
import favorite from "../assets/SVG/heart.svg";
import { Link, useNavigate } from "react-router-dom";
import { addToWishlist } from "../services/OrdersService";
import useAuth from "../hooks/useAuth";
import mark from "../assets/SVG/mark.svg";
import { Skeleton } from "@mui/material";
import SnackbarAlert from "./common/SnackbarAlert";

const Item = ({
  link_type,
  item_name,
  addToCart,
  currency,
  item_price,
  content,
  thumbnail,
  img_data,
  overall_quantity,
  wishList,
  setWishList,
  isLoading,
  scrollPosition,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [result, setResult] = React.useState({});
  const [error, setError] = React.useState({});
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  const isNew = (date) => {
    const today = new Date();
    const createdAt = new Date(date);
    const diffTime = Math.abs(today - createdAt);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 28;
  };

  const addWishlist = (e, isLoggedIn, content) => {
    e.preventDefault();
    if (isLoggedIn) handleWishlist(content.product_id);
    else navigate("/auth/login");
  };
  const handleWishlist = async (product_id) => {
    try {
      setLoading(true);
      let result = await addToWishlist(product_id);
      setWishList(result.data);
      setError({});
      setLoading(false);
      setResult({
        messageType: "success",
        message: result.message,
        title: "Product added to wishlist!",
      });
      setShowAlert(true);
    } catch (e) {
      setResult({
        messageType: "error",
        message: e.message,
        title: "Something went wrong!",
      });
      setShowAlert(true);
    }
    setLoading(false);
  };

  const closeAlert = () => {
    setShowAlert(false);
  };

  let storedItem = wishList.find((o) => o.product_id === content.product_id);
  const bg_color = storedItem ? "#e5383b" : "#FFFFFF";
  const img = storedItem ? mark : favorite;

  return isLoading ? (
    <Skeleton
      variant="rectangular"
      sx={{ bgcolor: "#FFF6EB" }}
      width={"100%"}
      height={"550px"}
      animation="wave"
    ></Skeleton>
  ) : (
    <Link
      onClick={() =>
        link_type == 0
          ? localStorage.setItem("scroll_p", scrollPosition)
          : localStorage.setItem("scroll_s", scrollPosition)
      }
      to={`/product/${content.product_id}`}
    >
      <div
        className="item bg-white shadow-sm rounded-sm w-[100%] h-[550px] sm:h-[550px] md:h-[550px] relative transition duration-200 ease-in-out border-0"
        style={{ backgroundImage: `url(${thumbnail})` }}
      >
        {content.discount_rate > 0 && (
          <div className="bg-[#CE4257] text-white uppercase font-medium p-2 left-2 top-2 absolute z-10 rounded-sm">
            SALE
          </div>
        )}
        {isNew(content.created_at) && (
          <div className="bg-[#FFB703] text-white uppercase font-medium p-2 right-2 top-2 absolute z-10 rounded-sm">
            New
          </div>
        )}
        {content.is_available === 1 && (
          <div className="bg-[#CE4257] text-white uppercase text-center absolute w-full text-sm bottom-[112px] font-normal py-1 bg-opacity-95 z-10">
            IN STOCK
          </div>
        )}
        <div className="bg-secondary p-3 px-4 md:p-3 flex flex-row items-center justify-between absolute inset-x-0 bottom-0 min-h-[112px]">
          <div className="min-h-[80px] flex flex-col justify-between relative">
            <p className="text-sm mb-1 font-medium md:text-sm overflow-hidden max-w-[200px] sm:max-w-[150px] md:max-w-[150px] lg:max-w-[220px] xl:max-w-[200px] capitalize line-clamp-3">
              {item_name}
            </p>
            {content.discount_rate > 0 ? (
              <div className="flex items-end justify-start">
                <p className="font-bold text-sm md:text-sm line-through mr-2">
                  {" "}
                  {"$ " + (Math.round(item_price * 100) / 100).toFixed(2)}
                </p>
                <p className="font-bold text-md md:text-md text-primary">
                  {" "}
                  {"$ " +
                    (
                      Math.round(
                        ((item_price * (100 - content.discount_rate)) / 100) *
                          100
                      ) / 100
                    ).toFixed(2)}
                </p>
              </div>
            ) : (
              <p className="font-bold text-md md:text-md">
                {" "}
                {"$ " + (Math.round(item_price * 100) / 100).toFixed(2)}
              </p>
            )}
          </div>
          <div className="flex items-center">
            <button
              type="button"
              style={{ backgroundColor: bg_color }}
              className={`rounded-sm p-3 hover:brightness-95 active:brightness-90 cursor-pointer transition duration-200 ease-in-out`}
              onClick={(e) => addWishlist(e, isLoggedIn, content)}
              disabled={storedItem}
            >
              <img src={img} className="w-[20px] sm:w-[25px]"></img>
            </button>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Item;
