import React from 'react'

const Privacy = () => {
    return (
        <div className='xl:container xl:mx-auto xl:px-4 mx-4'>
            <h1 className='text-left uppercase text-2xl lg:text-3xl underline decoration-4 underline-offset-1 decoration-primary my-4 lg:mt-8 lg:my-4 md:mr-16 !leading-relaxed'>Privacy Policy</h1>
            <ol>
                <li>
                    <p className='font-bold uppercase'>General</p>
                    <div className='text-justify leading-relaxed'>
                        Vacosta Style ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how your personal information
                        is collected, used, and disclosed by Vacosta Style.
                        This Privacy Policy applies to our website, vacosta-style.com, and its associated subdomains (collectively, our "Service"). By
                        accessing or using our Service, you signify that you have read, understood, and agree to our collection, storage, use, and disclosure
                        of your personal information as described in this Privacy Policy and our Terms of Service.
                    </div>
                </li>
                <li className='mt-6'>
                    <p className='font-bold uppercase'>Definitions & Key Terms</p>
                    <div className='text-justify leading-relaxed'>
                        To help explain things as Clearly as possible in this Privacy Policy, every time any or these terms are referenced, are strictly defined
                        as: <br></br>
                        <ul className='list-disc'>
                            <li><span className='font-bold'>Cookie:</span> small amount of data generated by a website and saved by your web browser. It Is used to Identify your browser,
                                provide analytics, remember information about you such as your lanquage preference or login information.</li>
                            <li><span className='font-bold'>Company:</span> when this policy mentions "Company." "we." "us." or "our." it refers to Vacosta Stvle. Jounieh that is responsible for
                                your information under this Privacy Policy.</li>
                            <li><span className='font-bold'>Country:</span> where Vacosta Style or the owners/founders or Vacosta Sive are based. In this case is Lebanon.</li>
                            <li><span className='font-bold'>Customer:</span> refers to the company. organization or person that signs up to use the Vacosta Stvle Service to manage the
                                relationships with our consumers or service users.</li>
                            <li><span className='font-bold'>Device:</span> any internet connected device such as a phone, tablet, computer or any other device that can be used to visit Vacosta
                                Style and use the services.</li>
                            <li><span className='font-bold'>IP address:</span> Every device connected to the Internet is assigned a number known as an Internet protocol (IP) address. These
                                numbers are usually assigned in geographic blocks. An IP address can often be used to identify the location from which a
                                device is connecting to the Internet.</li>
                            <li><span className='font-bold'>Personnel:</span> refers to those individuals who are employed by Vacosta Style or are under contract to perform a service on behalf
                                or one or the parties.</li>
                            <li><span className='font-bold'>Personal Data:</span> any information that directly, indirectly, or in connection with other information - including a personal
                                identification number - allows for the identification or identifiability of a natural person.</li>
                            <li><span className='font-bold'>Service:</span> refers to the service provided by Vacosta Style as described in the relative terms (if available) and on this plattorm.</li>
                            <li><span className='font-bold'>Third-party service:</span> refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide our
                                content or whose products or services we think may interest you.</li>
                            <li><span className='font-bold'>Website:</span> Vacosta Style's site, which can be accessed via this URL: vacosta-style.com.</li>
                            <li> <span className='font-bold'>You:</span> a person or entity that is registered with Vacosta Style to use the Services.</li>
                        </ul>
                    </div>
                </li>
                <li className='mt-6'>
                    <p className='font-bold uppercase'>What Information Do We Collect?</p>
                    <div className='text-justify leading-relaxed'>
                        We collect information from you when you visit our service, register, place an order, subscribe to our newsletter, respond to a survey
                        or fill out a form:
                        <ul className='list-disc'>
                            <li>Name / Username</li>
                            <li>Phone Number</li>
                            <li>Email Addresse</li>
                            <li>Password</li>
                        </ul>
                    </div>
                </li>
                <li className='mt-6'>
                    <p className='font-bold uppercase'>How Do We Use The Information We Collect?</p>
                    <div className='text-justify leading-relaxed'>
                        <ul className='list-disc'>
                            Any of the information we collect from you may be used in one of the following ways:
                            <li>To personalize your experience (your information helps us to better respond to your individual needs).</li>
                            <li>To improve our service (we continually strive to improve our service offerings based on the information and feedback we receive
                                from you).</li>
                            <li>To improve customer service (your information helps us to more effectively respond to your customer service requests and
                                support needs).</li>
                            <li>To process transactions.</li>
                            <li>To administer a contest, promotion, survey or other site feature.</li>
                            <li>To send periodic emails.</li>
                        </ul>
                    </div>
                </li>
                <li className='mt-6'>
                    <p className='font-bold uppercase'>Do we share the information we collect with third
                        parties?</p>
                    <div className='text-justify leading-relaxed'>
                        We may share the information that we collect, both personal and non-personal, with third parties such as advertisers, contest
                        sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may
                        interest you. We may engage trusted third party service providers to perform functions and provide services to us, such as hosting and
                        maintaining our servers and our service, database storage and management, e-mail management, storage marketing, credit card
                        processing. customer service and fulfilling orders for products and services you may purchase through our service. We will ikely
                        share your personal information, and possibly some non-personal information with these third parties to enable them to perform
                        these services for us and for you.
                        <br></br><br></br>
                        We may share portions of our log file data, including IP addresses, for analytics purposes with third parties such as web analytics
                        partners, application developers, and ad networks. If your IP address is shared, it may be used to estimate general location and other
                        technographics such as connection speed, whether you have visited the service in a shared location, and type of device used to visit
                        the service. They may aggregate information about our advertising and what you see on the service and then provide auditing
                        research and reporting for us and our advertisers.
                        <br></br><br></br>
                        We may also disclose personal and non-personal information about you to government or law enforcement officials or private parties
                        as we, in our sole discretion, believe necessary or appropriate in order to respond to claims, legal process (including subpoenas), to
                        protect our rights and interests or those of a third party, the safety of the public or any person, to prevent or stop any illegal, unethical
                        or legally actionable activity, or to otherwise comply with applicable court orders, laws, rules and regulations.
                    </div>
                </li>
                <li className='mt-6'>
                    <p className='font-bold uppercase'>How Do We Use Your Email Address?</p>
                    <div className='text-justify leading-relaxed'>
                        By submitting your email address on our service, you agree to receive emails from us. We
                        only send emails to people who have authorized us to contact them, either directly, or through a third party. We do not send
                        unsolicited commercial emails, because we hate spam as much as you do. By submitting your emall address, you also agree to allow
                        us to use your email address for customer audience targeting on sites like Facebook. where we display custom advertising to specific
                        people who have opted-in to receive communications from us. Email addresses submitted only through the order processing page
                        will be used for the sole purpose of sending you information and updates pertaining to your order. If, however, you have provided the
                        same email to us through another method, we may use it for any of the purposes stated in this Policy.
                    </div>
                </li>

                <li className='mt-6'>
                    <p className='font-bold uppercase'>Is the information collected through our service
                        secure?</p>
                    <div className='text-justify leading-relaxed'>
                        We take precautions to protect the security of your information. We have physical, electronic, and managerial procedures to help
                        safeguard, prevent unauthorized access, maintain data security, and correctly use your information. However, neither people nor
                        security systems are toolproof, including encryption systems. In addition, people can commit intentional crimes, make mistakes or fail
                        to follow policies. Therefore, while we use reasonable efforts to protect your personal information, we cannot guarantee its absolute
                        security. If applicable law imposes any non-disclaimable duty to protect your personal information, you agree that intentional
                        misconduct will be the standards used to measure our compliance with that duty.
                    </div>
                </li>

                <li className='mt-6'>
                    <p className='font-bold uppercase'>Sale of Business</p>
                    <div className='text-justify leading-relaxed'>
                        We reserve the right to transter information to a third party in the event or a sale, merger or other transier or all or substantially all or
                        the assets of us or any of its Corporate Affiliates (as defined herein), or that portion of us or any of its Corporate Affiliates to which the
                        Service relates, or in the event that we discontinue our business or file a petition or have filed against us a petition in bankruptcy,
                        reorganization or similar proceeding, provided that the third party agrees to adhere to the terms of this Privacy Policy.
                    </div>
                </li>
                <li className='mt-6'>
                    <p className='font-bold uppercase'>Governing Law</p>
                    <div className='text-justify leading-relaxed'>
                        The laws of Lebanon, excluding its conflicts of law rules, shall govern this Agreement and your use of our service. Your use of our
                        service may also be subject to other local, state, national, or international laws.
                    </div>
                </li>
                <li className='mt-6'>
                    <p className='font-bold uppercase'>Your Consent</p>
                    <div className='text-justify leading-relaxed'>
                        By using our service, registering an account, or making a purchase, you consent to this Privacy Policy.
                    </div>
                </li>
                <li className='mt-6'>
                    <p className='font-bold uppercase'>Third-Party Services</p>
                    <div className='text-justify leading-relaxed'>
                        We may display, include or make available third-party content including data,information, applications and other products services
                        or provide links to third-party websites or services ("Third- Party Services").
                        You acknowledge and agree that we shall not be responsible for any Third-Party Services, including their accuracy, completeness,
                        timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. We do not assume and shall not have
                        any liability or responsibility to you or any other person or entity for any Third-Party Services.
                        Third-Party Services and links thereto are provided solely as a convenience to you and you access and use them entirely at your own
                        risk and subiect to such third parties' terms and conditions
                    </div>
                </li>


            </ol>
        </div>
    )
}

export default Privacy