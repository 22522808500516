import React from 'react'

const ProductSkeleton = () => {
    return (
        <div className='flex flex-col animate-pulse'>
            <div className='w-full h-10 bg-slate-300 rounded-sm mb-2'></div>
            <div className='w-1/3 h-6 bg-slate-300 rounded-sm'></div>
            <div className='w-1/4 h-8 mt-4 lg:mt-8 bg-slate-300'>

            </div>

            <div className='mt-4 lg:mt-8 w-3/4 h-10 bg-slate-300 rounded-sm'>

            </div>

            <div className='mt-6 w-3/4 h-10 bg-slate-300 rounded-sm'>


            </div>
            <div className='mt-6 h-10 bg-slate-300 rounded-sm'>

            </div>
        </div>
    )
}

export default ProductSkeleton