import { Button } from '@mui/material'
import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material';
import { Link } from 'react-router-dom';
const BannerItem = ({ banner_id, title, text, img_url, order, remove }) => {
    const theme = createTheme({
        palette: {
            edit: {
                main: '#C84027',
            },
            delete: {
                main: '#e63946'
            }
        },
    });
    return (
        <div className='rounded-sm bg-secondary p-4 uppercase text-formLabel flex flex-col justify-between h-full'>
            <img src={`${img_url}`} className="w-full mb-2"></img>
            <div>
                <p className='text-xl mb-2 font-bold'>Banner {banner_id}</p>
                <p className='text-lg mb-2 font-medium'>{title}</p>
                <p className='text-sm mb-4 text-justify'>{text}</p>
                <p className='text-xs mb-4 text-justify'>{order}</p>
            </div>
            <div className='flex justify-between'>
                <ThemeProvider theme={theme}>
                    <Button onClick={remove} color='delete' variant="outlined">Delete</Button>
                    <Link to={`/admin/editBanner/${banner_id}`}>
                        <Button color='edit' disableElevation variant="contained" sx={{ color: 'white' }}>Edit</Button>
                    </Link>
                </ThemeProvider>
            </div>
        </div>
    )
}

export default BannerItem