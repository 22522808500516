import React from 'react'
import { useNavigate, useSearchParams, useParams, Link } from "react-router-dom";
import HeaderLogo from "../assets/logos/Header_Logo.svg";
import Button from '../Components/Button';
import TransitionAlert from "../Components/common/SnackbarAlert";
import { verifyAccount, sendVerificationLink } from "../services/AuthService";

const TransitionPage = () => {
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [accountVerificationResult, setAccountVerificationResult] = React.useState(
        {}
    );
    const handleSendLink = async () => {
        setIsLoading(true);
        setShowAlert(false);
        const email = searchParams.get("email");
        try {
            if (email) {
                let result = await sendVerificationLink(email);
                setAccountVerificationResult({
                    messageType: "success",
                    message: result.message,
                    title: "Success!",
                });
                setShowAlert(true);
            } else {
                throw Error("Something went wrong.");
            }
        } catch (e) {
            setAccountVerificationResult({
                messageType: "error",
                message: e.message,
                title: "Something went wrong!",
            });
            setShowAlert(true);
        }
        setIsLoading(false);
    };

    const closeAlert = () => {
        setShowAlert(false);
    };
    return (
        <div className="flex bg-backgroundColor">
            <TransitionAlert
                open={showAlert}
                message={accountVerificationResult.message}
                severity={accountVerificationResult.messageType}
                title={accountVerificationResult.title}
                handleClose={closeAlert}
            />
            <div className="flex flex-1 flex-col items-center">
                <img
                    className="w-52 h-auto mt-4"
                    src={HeaderLogo}
                    alt="Vacosta Style Logo"
                />
                <div className="mt-20 sm:w-9/12 md:w-5/12">
                    <h1 className="text-primary text-4xl font-semibold border-b-4 border-b-gray-50 pb-4">
                        ACCOUNT VERIFICATION
                    </h1>

                    <p className="my-5 text-gray-500">
                        Please check your email to <b>verify your account</b> and start shopping at Vacosta Style!
                    </p>

                    <div className="mt-5" onClick={handleSendLink}>
                        <Button
                            bg_color={'#C84027'}
                            color="white"
                            label={'Resend verification link'}>
                        </Button>
                    </div>
                    <p className="my-5 text-gray-500">
                        Go back to &nbsp;
                        <Link to={'/auth/login'}>
                            <span className='uppercase text-primary font-semibold cursor-pointer'>LOGIN</span>
                        </Link>
                        &nbsp; page.
                    </p>
                </div>
            </div>
            <img
                src={require("../assets/images/registration-picture.jpg")}
                className="w-0 lg:w-auto h-screen"
                alt=""
            />
        </div>
    )
}

export default TransitionPage