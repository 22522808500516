import React, { useEffect } from 'react'
import { Link, useParams, } from 'react-router-dom';
import { getNewest } from '../services/ProductsService';
import Item from './Item';
import NoData from './NoData';

const Newest = ({ wishList, setWishList, toggleDrawer }) => {
    const today = new Date();
    const params = useParams();
    const [newest, setNewest] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    const dates = [];
    for (let i = 0; i < 28; i++) {
        const date = new Date();
        date.setDate(date.getDate() - i);
        dates.push(date);
    }
    const fetchNewest = async (dateString) => {
        setIsLoading(true);
        const [day, month, year] = dateString.split('-');
        const date = new Date(year, month - 1, day);
        console.log(date);
        const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));// month is zero-indexed, so we subtract 1
        const formattedDate = localDate.toISOString().substring(0, 10);
        console.log(formattedDate);
        const newest = await getNewest(formattedDate);
        setIsLoading(false);
        setNewest(newest);
    }
    useEffect(() => {
        fetchNewest(params.date);
    }, [params.date])


    return (
        <div className='xl:container xl:mx-auto mx-4 mt-4 xl:px-4'>
            <div className='filters'>
                <div className='flex justify-between items-center'>
                    <h1 className='text-left uppercase text-2xl underline decoration-4 underline-offset-1 decoration-primary'>Daily New</h1>
                </div>
            </div>
            <div className='grid xl:grid-cols-7 lg:grid-cols-6 md:grid-cols-5 sm:grid-cols-3 grid-cols-2 gap-1 flex-wrap w-full mb-2 mt-2'>
                {dates.map((date) => (
                    <Link to={'/new/' + date.toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-')}>
                        <div
                            key={date.toISOString()}
                            className='bg-[#f8f8f8] rounded-sm uppercase text-[#374957] p-4 font-semibold text-center hover:bg-primary hover:text-white transition duration-200 ease-in-out cursor-pointer text-sm'
                        >
                            <p>{date.toLocaleDateString('en-GB')}</p>
                        </div>
                    </Link>
                ))}
            </div>
            {
                <div className='w-full mt-4'>
                    <div className='grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 flex-wrap w-full mb-4'>
                        {newest.map((data, index) => (
                            <Item isLoading={isLoading} key={"item-" + index} item_name={data.product_name} item_price={data.price} thumbnail={data.img_url} currency={data.currency} content={data} img_data={data.img_data} overall_quantity={data.overall_quantity} wishList={wishList} setWishList={setWishList}></Item>

                        ))}
                    </div>

                </div>
            }

        </div >
    )
}

export default Newest