import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import AdminSidebar from '../Components/common/AdminSidebar'
import { getImagesByVariant, editImages } from '../services/AdminService';
import { Link } from 'react-router-dom';
import { default as ButtonCustom } from '../Components/Button';
import { Online, Offline } from 'react-detect-offline';
import ImageComponent from '../Components/ImageComponent';
import TransitionAlert from "../Components/common/SnackbarAlert";
const EditImages = () => {
    const [uploaded, setUploaded] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [deleted, setDeleted] = React.useState([]);
    const [imageCount, setImageCount] = React.useState(0);
    const [thumbnail, setThumbnail] = React.useState(0);
    const [thumbnailType, setThumbnailType] = React.useState(null);
    const [prevThumbnailAvailable, setPrevThumbnailAvailable] = React.useState(false);
    const [images, setImages] = useState([]);
    const [files, setFiles] = useState([]);
    const [productCreationResult, setProductCreationResult] = useState({});
    const [showAlert, setShowAlert] = useState(false);
    const [inputErrors, setInputErrors] = useState({});
    const navigate = useNavigate()
    const product_id = useParams();
    const onChangeUploaded = e => {
        let newUploaded = [...uploaded];
        let newFiles = [...files];
        for (var i = 0; i < e.target.files.length; i++) {
            newUploaded.push({
                url: URL.createObjectURL(e.target.files[i]),
                // file: e.target.files[i],
                order: 0,
                is_thumbnail: 0
            })
            newFiles.push(
                e.target.files[i]
            )
        }

        setFiles(newFiles);
        setUploaded(newUploaded);

    }

    const fetchAll = async () => {
        let images = await getImagesByVariant(product_id.variant_id);
        setImages(images);
        images.map((data) => {
            if (data.is_thumbnail == 1) setPrevThumbnailAvailable(true);
        })
    }



    const removeFromUI = (i, image_id) => {
        let newImages = [...images];
        newImages.splice(i, 1);
        setImages(newImages);
        setDeleted([...deleted, image_id]);
        images.map((data, index) => {
            if (index == i) {
                if (data.is_thumbnail == 1) {
                    setThumbnailType(null);
                }
            }
        })
    }

    const removeUploaded = (i) => {
        let newImages = [...uploaded];
        let newFiles = [...files];
        newImages.splice(i, 1);
        newFiles.splice(i, 1);
        setUploaded(newImages);
        setFiles(newFiles);
        uploaded.map((data, index) => {
            if (index == i) {
                if (data.is_thumbnail == 1) {
                    setThumbnailType(null);
                }
            }
        })
    }

    const setUploadedThumbnail = (i) => {
        images.map((item, index) => {
            if (item.is_thumbnail === 1) {
                item.is_thumbnail = 0;
            }
        })
        uploaded.map((item, index) => {
            if (index === i) {
                item.is_thumbnail = 1;
            }
            if (index != i) {
                if (item.is_thumbnail === 1) {
                    item.is_thumbnail = 0;

                }
            }
        })
        setThumbnailType('uploaded');
        setThumbnail(i);
    }

    const setPreviousThumbnail = (image_id, i) => {
        images.map((item, index) => {
            if (index === i) {
                item.is_thumbnail = 1;
            }
            if (index != i)
                if (item.is_thumbnail === 1) {
                    item.is_thumbnail = 0;
                }
        })
        uploaded.map((item, index) => {
            if (item.is_thumbnail === 1) {
                item.is_thumbnail = 0;
            }
        })
        setThumbnailType('images');
        setThumbnail(image_id);
    }

    const submit = async () => {
        setImageCount(images.length);
    }

    const reset = async () => {
        fetchAll();
        setUploaded([]);
        setFiles([]);
        setDeleted([]);
        setImageCount(images.length);
    }

    // const submitChanges = async (files, uploaded, images, deleted, variant_id, thumbnail_type, thumbnail) => {
    //     let response = await editImages(files, uploaded, images, deleted, variant_id, thumbnail_type, thumbnail);
    //     console.log(response);
    // }

    const submitChanges = async (files, uploaded, images, deleted, variant_id, thumbnail_type, thumbnail) => {
        setIsLoading(true);
        setShowAlert(false);
        setInputErrors({});
        try {
            if (thumbnailType == null && prevThumbnailAvailable == true) {
                setProductCreationResult({
                    messageType: "error",
                    message: 'Please set a thumbnail.',
                    title: "Something went wrong!",
                });
                setShowAlert(true);
                setIsLoading(false);
                return;
            }
            if (images.length == 0 && uploaded.length == 0) {
                setProductCreationResult({
                    messageType: "error",
                    message: 'Please upload at least one picture.',
                    title: "Something went wrong!",
                });
                setShowAlert(true);
                setIsLoading(false);
                return;
            }
            let result = await editImages(files, uploaded, images, deleted, variant_id, thumbnail_type, thumbnail);
            setProductCreationResult({
                messageType: "success",
                message: result.message,
                title: "Success!",
            });
            setInputErrors({});

            setShowAlert(true);
            // navigate('/admin/products')
        } catch (e) {
            setProductCreationResult({
                messageType: "error",
                message: e.message,
                title: "Something went wrong!",
            });
            setShowAlert(true);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchAll();

    }, [])

    useEffect(() => {
        console.log(prevThumbnailAvailable)
    }, [prevThumbnailAvailable])
    useEffect(() => {
        if (images.length != 0) {
            images.map((data, index) => {
                if (data.is_thumbnail == 1) {
                    setThumbnailType('images');
                    setThumbnail(data.image_id);
                }
            })
        }
        if (images.length == 0 && uploaded.length == 0 && prevThumbnailAvailable == false) setThumbnailType(null);

        // else if (prevThumbnailAvailable == false) setThumbnailType(null);
    }, [images])

    useEffect(() => {
        if (uploaded.length != 0) {
            uploaded.map((data, index) => {
                if (data.is_thumbnail == 1) {
                    setThumbnailType('uploaded');
                    setThumbnail(index);
                }
            })
        }
        if (images.length == 0 && uploaded.length == 0 && prevThumbnailAvailable == false) setThumbnailType(null);
        else if (prevThumbnailAvailable == false) setThumbnailType(null);
    }, [uploaded])

    const closeAlert = () => {
        setShowAlert(false);
    };
    return (
        <div className="flex flex-col lg:flex-row w-[95%] lg:w-[98%] mx-auto">
            <div className="w-full lg:w-1/4">
                <AdminSidebar />
            </div>
            <TransitionAlert
                open={showAlert}
                message={productCreationResult.message}
                severity={productCreationResult.messageType}
                title={productCreationResult.title}
                handleClose={closeAlert}
            />
            <div className="w-full lg:w-3/4 lg:ml-6">

                <div className="flex justify-between items-center mt-5">
                    <h1 className="text-left uppercase text-2xl underline decoration-4 underline-offset-0 decoration-primary">
                        Edit Images
                    </h1>
                </div>

                <div className='grid gap-3 grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 w-full mt-4 mb-6'>
                    {images.map((item, index) => (
                        <ImageComponent type='images' item={item} setPreviousThumbnail={setPreviousThumbnail} removeFromUI={removeFromUI} index={index}></ImageComponent>
                        // <div className='w-full h-[250px] border border-primary text-center relative overflow-hidden'>
                        //     <img src={`${item.img_url}`} className="mx-auto max-h-[250px]"></img>
                        //     <div className='absolute top-2 right-2 cursor-pointer flex' >
                        //         {item.is_thumbnail == 0 ? <StarBorder onClick={() => setPreviousThumbnail(item.image_id, index)} className='text-primary' /> : <Star className='text-primary'></Star>}
                        //         <div className='mx-1' />
                        //         <Delete className='text-primary' onClick={() => removeFromUI(index)}></Delete>
                        //     </div>
                        //     <div className='absolute bottom-4 right-4'>
                        //         <input defaultValue={item.order} min={0} type='number' className='max-w-[100px] rounded-sm'></input>
                        //     </div>
                        // </div>
                    ))}
                    {
                        uploaded.map((data, index) => (
                            <ImageComponent type='uploaded' item={data} setUploadedThumbnail={setUploadedThumbnail} removeUploaded={removeUploaded} index={index}></ImageComponent>
                            // <div className='w-full h-[250px] border border-primary text-center relative overflow-hidden'>
                            //     <img className="mx-auto max-h-[250px]" src={data.url}></img>
                            //     <div className='absolute top-2 right-2 cursor-pointer flex' >
                            //         {data.is_thumbnail == 0 ? <StarBorder onClick={() => setUploadedThumbnail(index)} className='text-primary' /> : <Star className='text-primary'></Star>}
                            //         <Delete onClick={() => removeUploaded(index)} className='text-primary'></Delete>
                            //     </div>
                            //     <div className='absolute bottom-4 right-4'>
                            //         <input value={data.order} type='number' className='max-w-[100px] rounded-sm'></input>
                            //     </div>
                            // </div>
                        ))
                    }

                    <label htmlFor='uploaded' className='w-full h-[250px] border border-primary rounded-sm flex flex-col text-center justify-center cursor-pointer border-dashed uppercase text-primary bg-slate-50'>
                        + Add Image
                    </label>
                    <input id="uploaded" type="file" multiple onChange={onChangeUploaded} hidden />

                </div>
                <div className="flex justify-between md:justify-end my-5">
                    <Link to="/admin/products">

                        <div className="md:mr-2">
                            <ButtonCustom bg_color='#f8f8f8' label="cancel" color="gray" disabled={isLoading} ></ButtonCustom>
                        </div>

                    </Link>
                    <div className="md:mr-2" onClick={reset}>
                        <ButtonCustom bg_color='#f8f8f8' label="Reset changes" color="gray" disabled={isLoading} ></ButtonCustom>
                    </div>
                    <div>
                        <Online>
                            <div className="md:mr-2" onClick={() => submitChanges(files, uploaded, images, deleted, product_id.variant_id, thumbnailType, thumbnail)}>
                                <ButtonCustom bg_color='#C84027' label={isLoading ? "Loading ..." : "SUBMIT"} color="white" disabled={isLoading}></ButtonCustom>
                            </div>
                        </Online>
                        <Offline>
                            <ButtonCustom bg_color='#C84027' label={'No Internet'} color="white" disabled={true}></ButtonCustom>
                        </Offline>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default EditImages