import React from "react";
import { BsUpload, BsXCircle, BsStar, BsStarFill } from "react-icons/bs";
export default function FormFileInput({
  id,
  label,
  files,
  onChange,
  error,
  removeFile,
  className,
  handleFavorite,
  favoriteIndex,
  ...props
}) {
  return (
    <div className="mt-2 lg:mt-5">
      {label && (
        <label className="block mb-3 text-lg text-formLabel font-bold">
          {label}:
        </label>
      )}
      <div className="flex flex-wrap ">
        {files &&
          files.map((file, index) => (
            <div key={index} className="relative mr-5">
              <BsXCircle
                className="absolute top-2 right-2 text-lg hover:cursor-pointer text-primary"
                onClick={() => {
                  removeFile(index);
                }}
              />
              {favoriteIndex === index ? (
                <BsStarFill
                  className="absolute top-2 right-10 text-lg hover:cursor-pointer text-primary"
                  onClick={() => {
                    handleFavorite(index);
                  }}
                />
              ) : (
                <BsStar
                  className="absolute top-2 right-10 text-lg hover:cursor-pointer text-primary"
                  onClick={() => {
                    handleFavorite(index);
                  }}
                />
              )}

              <img className="h-56 w-56 object-contain" src={file} />
            </div>
          ))}
        <label htmlFor={id}>
          <div className="h-56 w-56 bg-[#F5F8FB] flex items-center justify-center rounded-lg hover:cursor-pointer">
            <div className="flex flex-col items-center">
              <BsUpload className="text-2xl text-primary mb-3" />
              <p className="text-sm text-gray-500">Click to add images</p>
            </div>
          </div>
        </label>
      </div>

      <input
        id={id}
        accept="image/*"
        multiple={true}
        className="hidden"
        type="file"
        onChange={(e) => {
          if (e.target.files.length !== 0) {
            onChange(Array.from(e.target.files));
          }
        }}
        {...props}
      />
      {error && <p className="mt-2 text-sm font-bold text-primary">{error}</p>}
    </div>
  );
}
