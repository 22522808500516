import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import AdminSidebar from '../Components/common/AdminSidebar'
import CustomButton from "../Components/Button";
import BannerItem from '../Components/BannerItem';
import { deleteBanner, getBanners } from '../services/AdminService';
import TransitionAlert from "../Components/common/SnackbarAlert";
const Banner = () => {

    const [banners, setBanners] = React.useState([]);
    const [deleteResult, setDeleteResult] = React.useState({});
    const [showAlert, setShowAlert] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    // const params = useParams();
    const handleBanners = async () => {
        const response = await getBanners();
        setBanners(response);
    }
    const handleDeleteBanner = async (banner_id) => {
        setIsLoading(true);
        setShowAlert(false);

        try {
            let result = await deleteBanner(banner_id);
            setDeleteResult({
                messageType: "success",
                message: result.message,
                title: "Success!",
            });
            setShowAlert(true);
            handleBanners();
        } catch (e) {
            setDeleteResult({
                messageType: "error",
                message: e.message,
                title: "Something went wrong!",
            });
            setShowAlert(true);
        }
        setIsLoading(false);
    };
    const closeAlert = () => {
        setShowAlert(false);
    };
    useEffect(() => {
        handleBanners();
    }, [])
    return (
        <div className="flex flex-col lg:flex-row w-[95%] lg:w-[98%] mx-auto">
            <div className="w-full lg:w-1/4">
                <AdminSidebar />
            </div>
            <TransitionAlert
                open={showAlert}
                message={deleteResult.message}
                severity={deleteResult.messageType}
                title={deleteResult.title}
                handleClose={closeAlert}
            />
            <div className="w-full lg:w-3/4 lg:ml-6">
                <div className="w-full mx-auto mt-5">
                    <div className="flex justify-between items-start">
                        <h1 className="text-left uppercase text-2xl underline decoration-4 decoration-primary">
                            Banner Management
                        </h1>
                        <Link to="/admin/addBanner">
                            <CustomButton bg_color="#C84027" color='white' label={'Add Banner'} >  </CustomButton>
                        </Link>
                    </div>
                    <div className='grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5'>
                        {banners.map((data, index) => (
                            <BannerItem remove={() => handleDeleteBanner(data.banner_id)} key={'banner-' + index} img_url={data.img_url} order={data.order} title={data.title} text={data.text} banner_id={data.banner_id}></BannerItem>
                        ))}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner