import * as yup from "yup";

const addressSchema = yup.object().shape({
    city: yup
        .string()
        .required("The city field is required."),
    district: yup
        .string()
        .required("The district field is required."),
    addressLine: yup
        .string()
        .required("The address field is required."),

});

const orderSchema = yup.object().shape({
    cart: yup
        .array()
        .min(1, "Please add at least one item to cart."),
});

export { addressSchema, orderSchema };
