import React, { useContext } from 'react'
import { WishListContext } from '../context/WishListContext';

const useWishList = () => {
    const { wishList, setWishList } = useContext(WishListContext);

    return {
        wishList, setWishList
    };
};

export default useWishList