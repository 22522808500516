import axios from "axios";
import { saveAs } from "file-saver";
// const BASE_URL = "http://127.0.0.1:8000/api";
const BASE_URL = "https://api-vacosta-6sf6b.ondigitalocean.app/api";

const addVariant = async (variant) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  // axios.defaults.withCredentials = true;
  const { productSize, productColor, files, variant_id } = variant;
  try {
    const formData = new FormData();
    formData.append("variant_id", variant_id["variant_id"]);
    formData.append("sizes", JSON.stringify(productSize));
    formData.append("colors", JSON.stringify(productColor));

    const obj = {};
    Object.keys(files).forEach((e) => {
      const arr = [];
      files[e].forEach((l) => {
        arr.push(l);
      });
      obj[e] = arr;
      for (var i = 0; i < arr.length; i++) {
        formData.append(`images[${e}][${i}]`, arr[i]);
      }
    });

    const response = await axios.post(`${BASE_URL}/addVariant`, formData, {
      // headers: { "Content-Type": "multipart/form-data" },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error(error);
    }
  }
};

const addProduct = async (product) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  // axios.defaults.withCredentials = true;
  const {
    productName,
    productCode,
    productCategory,
    productSubCategory,
    productSeason,
    productSize,
    productColor,
    productAvailability,
    productType,
    productPrice,
    productWeight,
    productStyle,
    productPattern,
    productDecoration,
    productSilhouette,
    fabricType,
    productNeckline,
    productSleeveLength,
    files,
    thumbnailImageColor,
    thumbnailImageIndex,
    dailyStatus,
    activationStatus,
  } = product;
  try {
    const formData = new FormData();

    formData.append("product_name", productName);
    formData.append("product_code", productCode);
    formData.append("category", productSubCategory);
    formData.append("sizes", JSON.stringify(productSize));
    formData.append("colors", JSON.stringify(productColor));
    formData.append("style", productStyle);
    formData.append("silhouette", productSilhouette);
    formData.append("is_daily", dailyStatus);
    formData.append("active", activationStatus);
    formData.append("discount", 0);
    formData.append("discount_rate", 20);
    formData.append("price", productPrice);
    formData.append("is_available", productAvailability);
    formData.append("weight", productWeight);
    formData.append("season", productSeason);
    formData.append("neckline", productNeckline);
    formData.append("sleeve_length", productSleeveLength);
    formData.append("pattern", productPattern);
    formData.append("decoration", productDecoration);
    formData.append("fabric_type", fabricType);
    formData.append("tn_color", thumbnailImageColor);
    formData.append("tn_index", thumbnailImageIndex);

    const obj = {};
    Object.keys(files).forEach((e) => {
      const arr = [];
      files[e].forEach((l) => {
        arr.push(l);
      });
      obj[e] = arr;
      for (var i = 0; i < arr.length; i++) {
        formData.append(`images[${e}][${i}]`, arr[i]);
      }
    });

    const response = await axios.post(`${BASE_URL}/addProduct`, formData, {
      // headers: { "Content-Type": "multipart/form-data" },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error(error);
    }
  }
};

const getVariants = async (page_nb) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  // axios.defaults.withCredentials = true;
  try {
    const response = await axios.get(`${BASE_URL}/getVariants?page=${page_nb}`);
    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error(error);
    }
  }
};

const insertQuantity = async (variant_id, quantity) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const request = {
      variant_id: variant_id,
      quantity: quantity,
    };
    const response = await axios.post(`${BASE_URL}/updateQty`, request, {
      headers: { "Content-Type": "application/json" },
    });

    return response.data.message;
  } catch (error) {
    let { message } = error.response.data;
    throw Error(message);
  }
};

const deleteVariant = async (variant_id) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const formData = new FormData();
    formData.append("variant_id", variant_id);
    const response = await axios.post(`${BASE_URL}/deleteVariant`, formData, {
      headers: { "Content-Type": "application/json" },
    });

    return response.data;
  } catch (error) {
    let { message } = error.response.data;
    throw Error(message);
  }
};
const deleteProduct = async (product_id) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const formData = new FormData();
    formData.append("product_id", product_id);
    const response = await axios.post(`${BASE_URL}/deleteProduct`, formData, {
      headers: { "Content-Type": "application/json" },
    });

    return response.data;
  } catch (error) {
    let { message } = error.response.data;
    throw Error(message);
  }
};
const deleteType = async (type_id) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const formData = new FormData();
    formData.append("type_id", type_id);
    const response = await axios.post(`${BASE_URL}/deleteType`, formData, {
      headers: { "Content-Type": "application/json" },
    });

    return response.data;
  } catch (error) {
    let { message } = error.response.data;
    throw Error(message);
  }
};
const deleteCategory = async (category_id) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const formData = new FormData();
    formData.append("category_id", category_id);
    const response = await axios.post(`${BASE_URL}/deleteCategory`, formData, {
      headers: { "Content-Type": "application/json" },
    });

    return response.data;
  } catch (error) {
    let { message } = error.response.data;
    throw Error(message);
  }
};
const getVariantImages = async (product_id) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  const response = await axios.get(
    `${BASE_URL}/getVariantImages/${product_id}`,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
  return response.data;
};

const editThumbnail = async (product_id, image_id) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const formData = new FormData();
    formData.append("product_id", product_id);
    formData.append("image_id", image_id);
    const response = await axios.post(`${BASE_URL}/editThumbnail`, formData, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
const addRootCategory = async (type_id, type_name) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const formData = new FormData();
    formData.append("category_name", type_name);
    // formData.append("category_icon", icon);
    formData.append("type_id", type_id);
    const response = await axios.post(`${BASE_URL}/addRootCategory`, formData, {
      headers: { "Content-Type": "application/json" },
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};

const addSubCategory = async (parent_id, subCategory) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const formData = new FormData();
    formData.append("category_name", subCategory);
    formData.append("parent_id", parent_id);
    const response = await axios.post(`${BASE_URL}/addSubCategory`, formData, {
      headers: { "Content-Type": "application/json" },
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};

const addType = async (type) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const formData = new FormData();
    formData.append("type_name", type);
    const response = await axios.post(`${BASE_URL}/addType`, formData, {
      headers: { "Content-Type": "application/json" },
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};

const getProductById = async (product_id) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const formData = new FormData();
    formData.append("product_id", product_id);
    const response = await axios.get(
      `${BASE_URL}/getProductById/${product_id}`,
      formData,
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};

const getSizesById = async (variant_id) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const formData = new FormData();
    formData.append("variant_id", variant_id);
    const response = await axios.get(
      `${BASE_URL}/getSizesById/${variant_id}`,
      formData,
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};
const getColorsById = async (variant_id) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const formData = new FormData();
    formData.append("variant_id", variant_id);
    const response = await axios.get(
      `${BASE_URL}/getColorsById/${variant_id}`,
      formData,
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};
const updateProduct = async (product) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  const {
    productID,
    productName,
    productCode,
    productSubCategory,
    productSeason,
    productPrice,
    productWeight,
    productStyle,
    productPattern,
    productDecoration,
    productSilhouette,
    fabricType,
    productNeckline,
    productSleeveLength,
    activationStatus,
    discountRate,
    productAvailability,
    dailyStatus,
  } = product;
  try {
    const formData = new FormData();
    formData.append("product_id", productID);
    formData.append("product_name", productName);
    formData.append("category_id", productSubCategory);
    formData.append("product_code", productCode);
    formData.append("product_style", productStyle);
    formData.append("season", productSeason);
    formData.append("price", productPrice);
    formData.append("weight", productWeight);
    formData.append("pattern", productPattern);
    formData.append("decoration", productDecoration);
    formData.append("silhouette", productSilhouette);
    formData.append("fabric_type", fabricType);
    formData.append("neckline", productNeckline);
    formData.append("sleeve_length", productSleeveLength);
    formData.append("active", activationStatus);
    formData.append("discount_rate", discountRate);
    formData.append("is_available", productAvailability);
    formData.append("is_daily", dailyStatus);
    formData.append("active", activationStatus);
    const response = await axios.post(`${BASE_URL}/updateProduct`, formData, {
      headers: { "Content-Type": "application/json" },
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};

const editType = async (type_id, type_name) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const formData = new FormData();
    formData.append("type_id", type_id);
    formData.append("type_name", type_name);
    const response = await axios.post(`${BASE_URL}/editType`, formData, {
      headers: { "Content-Type": "application/json" },
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};

const editCategory = async (category_id, category_name) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const formData = new FormData();
    formData.append("category_id", category_id);
    formData.append("category_name", category_name);
    const response = await axios.post(`${BASE_URL}/editCategory`, formData, {
      headers: { "Content-Type": "application/json" },
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};
const setDelivered = async (order_id) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const formData = new FormData();
    formData.append("order_header_id", order_id);

    const response = await axios.post(`${BASE_URL}/setDelivered`, formData, {
      headers: { "Content-Type": "application/json" },
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};
const setProcessing = async (order_id) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const formData = new FormData();
    formData.append("order_header_id", order_id);

    const response = await axios.post(`${BASE_URL}/setProcessing`, formData, {
      headers: { "Content-Type": "application/json" },
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};
const searchProduct = async (searchValue, page) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const response = await axios.get(
      `${BASE_URL}/searchAdminProduct?page=${page}&query=${searchValue}`,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};

const getOrderHeaders = async (page_nb) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const response = await axios.get(
      `${BASE_URL}/getOrderHeaders?page=${page_nb}`,
      {
        // headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};
const exportOrders = async () => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const response = await axios.get(`${BASE_URL}/exportOrders`, {
      headers: { responseType: "arraybuffer" },
    });

    const blob = new Blob([response.data]);
    saveAs(blob, "orders.csv");

    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    }
  }
};
const getOrderDetails = async (order_id) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const response = await axios.get(
      `${BASE_URL}/getOrderDetails?order_id=${order_id}`,
      {
        // headers: { "Content-Type": "application/json" },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};

const getBanners = async () => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  const response = await axios.get(`${BASE_URL}/getBanners`);
  return response.data;
};
const getBannerByID = async (banner_id) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  const response = await axios.get(
    `${BASE_URL}/getBannerByID?banner=${banner_id}`
  );
  return response.data;
};
const addBanner = async (banner) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  const { title, text, order, desktopIMG, mobileIMG, color } = banner;
  try {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("text", text);
    formData.append("order", order);
    formData.append("color", color);
    formData.append("desktopIMG", desktopIMG);
    formData.append("mobileIMG", mobileIMG);
    const response = await axios.post(`${BASE_URL}/addBanner`, formData, {
      headers: { "Content-Type": "application/json" },
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};
const deleteBanner = async (banner_id) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  try {
    const formData = new FormData();
    formData.append("banner_id", banner_id);
    const response = await axios.post(`${BASE_URL}/deleteBanner`, formData, {
      headers: { "Content-Type": "application/json" },
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};
const editBanner = async (banner) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  const { banner_id, title, text, order, desktopIMG, mobileIMG, color } =
    banner;
  try {
    const formData = new FormData();
    formData.append("banner_id", banner_id);
    formData.append("title", title);
    formData.append("text", text);
    formData.append("order", order);
    formData.append("color", color);
    formData.append("desktopIMG", desktopIMG);
    formData.append("mobileIMG", mobileIMG);
    const response = await axios.post(`${BASE_URL}/editBanner`, formData, {
      headers: { "Content-Type": "application/json" },
    });

    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error("Something wrong happened please try later.");
    }
  }
};

const getImagesByVariant = async (variant_id) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  const response = await axios.get(
    `${BASE_URL}/getImagesByVariant?variant_id=${variant_id}`
  );
  return response.data;
};

const editImages = async (
  files,
  uploaded,
  images,
  deleted,
  variant_id,
  thumbnail_type,
  thumbnail
) => {
  const token = sessionStorage.getItem("admin_token");
  axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

  try {
    const formData = new FormData();
    formData.append("uploaded", JSON.stringify(uploaded));
    formData.append("images", JSON.stringify(images));
    files.forEach((file) => {
      formData.append("files[]", file);
    });
    // formData.append("files[]", files);
    formData.append("deleted", JSON.stringify(deleted));
    formData.append("variant_id", variant_id);
    formData.append("thumbnail_type", thumbnail_type);
    formData.append("thumbnail", thumbnail);
    const response = await axios.post(`${BASE_URL}/editImages`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      // headers: { 'content-type': 'multipart/form-data' }
    });
    // console.log(response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      let { message } = error.response.data;
      throw Error(message);
    } else if (error.request) {
      throw Error("Something wrong happened please try later.");
    } else {
      throw Error(error);
    }
  }
};
export {
  addProduct,
  addRootCategory,
  addSubCategory,
  addType,
  getVariants,
  insertQuantity,
  getProductById,
  getColorsById,
  getSizesById,
  addVariant,
  getVariantImages,
  editThumbnail,
  editType,
  editCategory,
  updateProduct,
  searchProduct,
  getOrderHeaders,
  getOrderDetails,
  getBanners,
  editBanner,
  getBannerByID,
  addBanner,
  deleteBanner,
  setDelivered,
  setProcessing,
  exportOrders,
  getImagesByVariant,
  editImages,
  deleteCategory,
  deleteVariant,
  deleteProduct,
  deleteType,
};
