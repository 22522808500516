import React, { createContext, useState, useEffect } from "react";
export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(null);
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    const localStorageToken = localStorage.getItem("vacosta_user_token");
    const adminToken = sessionStorage.getItem("admin_token");
    const getAdmin = sessionStorage.getItem('admin');
    if (localStorageToken) {
      setToken(localStorageToken);
      setIsLoggedIn(true);
    }
    else if (adminToken) {
      setToken(adminToken);
      setIsLoggedIn(true);
    }
    // if (getAdmin) {
    if (getAdmin == 'true') setAdmin(true);
    else setAdmin(false);
    // } else setAdmin(false);
    setIsLoadingProfile(false);
  }, []);

  const login = (token, user) => {
    // console.warn(user);
    if (user == 'admin') {
      sessionStorage.setItem("admin_token", token)
      sessionStorage.setItem('admin', true);
      setAdmin(true);
    }
    else {
      localStorage.setItem("vacosta_user_token", token);
      setAdmin(false);
    }
    setToken(token);
    setIsLoggedIn(true);
  };

  const logout = () => {
    localStorage.removeItem("vacosta_user_token");
    sessionStorage.removeItem("admin_token");
    setToken("");
    setIsLoggedIn(false);
    sessionStorage.removeItem("admin");
    setAdmin(false);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoadingProfile,
        isLoggedIn,
        admin,
        token,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContextProvider;
