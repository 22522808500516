import React from 'react'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Button, createTheme, TextField, ThemeProvider } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
const BottomBar = ({ toggleDrawer, sort, sortContent, clearFilters, arrayContent, chosenFilters, handleFilter, theme, setMin, setMax, min, max, priceIMG, fetch, handleSort }) => {
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        <div className='w-full bg-secondary p-6'>
            <div className='flex justify-between'>
                <h1 className='text-left uppercase text-2xl underline decoration-4 underline-offset-0 decoration-primary'>Filters</h1>
                <div onClick={toggleDrawer('bottom', false)}>
                    <CloseRoundedIcon fontSize='medium' sx={{ color: '#C84027' }}></CloseRoundedIcon>
                </div>
            </div>
            <div className='my-4'>
                {/* <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} children>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <div className='uppercase text-primary'>
                            Category
                        </div>

                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <FormGroup>
                                {arrayContent.map((data) => <FormControlLabel key={'category-' + data.category_id} control={<Checkbox checked={chosenFilters.includes(data.category_id)} value={data.category_id} onChange={(e) => handleFilter(e, data.category_id)} />} label={data.category_name} />)}
                            </FormGroup>
                        </div>
                    </AccordionDetails>
                </Accordion> */}

                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={{ marginTop: '5px' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                    >
                        <div className='uppercase text-primary'>
                            {sort}
                        </div>

                    </AccordionSummary>
                    <AccordionDetails>
                        {sortContent.map((data) =>
                            <div className='py-2 uppercase hover:underline hover:decoration-black hover:decoration-1'
                                onClick={(e) => handleSort(e, data)} >
                                {data}
                            </div>
                        )
                        }
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} sx={{ marginTop: '5px' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                    >
                        <div className='uppercase text-primary'>
                            Price
                        </div>

                    </AccordionSummary>
                    <AccordionDetails>
                        <ThemeProvider theme={theme}>
                            <div className='flex justify-between'>
                                <TextField
                                    // autoComplete={false}
                                    value={min}
                                    id="min"
                                    color='primary'
                                    label="Min."
                                    type="number"
                                    onChange={(e) => setMin(e.target.value)}
                                />
                                <img src={priceIMG} className="rotate-90 mx-2"></img>
                                <TextField
                                    value={max}
                                    id="max"
                                    color='primary'
                                    label="Max."
                                    type="number"
                                    onChange={(e) => setMax(e.target.value)}
                                />
                            </div>

                            <Button disableElevation color='primary' onClick={fetch} variant='contained' fullWidth sx={{ marginTop: '16px' }}>Apply Filters</Button>
                        </ThemeProvider>
                    </AccordionDetails>
                </Accordion>
            </div>
            <ThemeProvider theme={theme}>
                <Button variant='outlined' color='primary' fullWidth onClick={clearFilters} sx={{ textTransform: 'uppercase' }}>Clear Filters</Button>
            </ThemeProvider>

        </div>
    )
}

export default BottomBar