import React from "react";

export default function FormTextInput({
  id,
  label,
  value,
  placeholder,
  onChange,
  error,
  type,
  className,
  ...props
}) {
  return (
    <div className='mt-2 lg:mt-5'>
      {label && (
        <label
          className="block mb-3 text-lg text-formLabel font-bold"
          htmlFor={id}
        >
          {label}:
        </label>
      )}
      <input
        id={id}
        className="block rounded-sm bg-gray-50 w-full py-3 px-5 text-lg border-[#AAAAAA] border"
        value={value}
        type={type ? type : "text"}
        placeholder={placeholder}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        {...props}
      />
      {error && <p className="mt-2 text-sm font-bold text-primary">{error}</p>}
    </div>
  );
}
