import React from 'react'
import Loader from './Loader'

const LoadingPage = ({ open }) => {
    return (
        <div className={`z-50 fixed h-full w-full top-0 left-0 opacity-70 bg-white flex flex-col justify-center ${open}`}>
            <Loader></Loader>
        </div>
    )
}

export default LoadingPage