import React, { useEffect } from 'react'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { search } from '../services/ProductsService';
import { Link } from 'react-router-dom';

const MobileSearch = ({ setMobileSearch, toggleDrawer }) => {
    const [searchResults, setSearchResults] = React.useState([]);
    const [query, setQuery] = React.useState(null);
    const [section, setSection] = React.useState(1);
    const [preventLoad, setPreventLoad] = React.useState(true);
    const searchHandler = async () => {
        // setSection(1);
        let result = await search(query, 1);
        setSearchResults(result.data);
        if (result.current_page == result.last_page) setPreventLoad(true);
        else setPreventLoad(false);
        // setSearchResults(result.data);
    }
    const loadMore = async () => {
        let result = await search(query, section);
        const newArr = searchResults.concat(result.data);
        setSearchResults(newArr);
        if (result.current_page == result.last_page) setPreventLoad(true);
        else setPreventLoad(false);
    }
    useEffect(() => {
        if (query != null)
            searchHandler();
    }, [query])
    useEffect(() => {
        if (query != null)
            loadMore();
        console.log(searchResults)
    }, [section])
    return (
        <div className='fixed w-full h-full bg-white overflow-auto'>
            <input max={'75'} autoFocus className='fixed top-0 border-b border-b-slate-400 bg-slate-100 w-full p-4 outline-none' placeholder='Search for items...' value={query} onChange={(e) => setQuery(e.currentTarget.value)}></input>
            <CloseRoundedIcon onClick={() => setMobileSearch(false)} fontSize='small' sx={{ color: '#C84027', position: 'fixed', right: '16px', top: '18px' }}></CloseRoundedIcon>
            <div className='mt-16'>
                {searchResults.map((data) => (
                    <Link onClick={toggleDrawer('left', false)} to={`product/${data.product_id}`}>
                        <div className='hover:bg-slate-50 transition duration-200 ease-in-out cursor-pointer p-3 flex border-b border-slate-200'>
                            <img className='w-[25%]' src={`${data.img_url}`}></img>
                            <div className='flex flex-col ml-3 justify-between'>
                                <div className=''>
                                    <p className='text-md font-medium uppercase text-primary line-clamp-2'>{data.product_name}</p>
                                    <p className='text-xs font-light uppercase'>{data.category_name}</p>
                                </div>
                                <div className=''>
                                    <p className='text-gray-800'>{'$ ' + (Math.round(data.price * 100) / 100).toFixed(2)}</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                ))}
                {!preventLoad && <p className='p-2 uppercase text-slate-400 text-center' onClick={() => setSection(section + 1)}>Load More</p>}
            </div>
        </div>
    )
}

export default MobileSearch