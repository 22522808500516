import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from './Button'

const OrderConfirmation = ({ orderDate, amount, order_id, email, confirmed, setConfirmed }) => {
    const navigate = useNavigate();
    const exit = () => {
        setConfirmed(false);
        navigate('/', { replace: true });
    }
    return (
        <div className='xl:container xl:mx-auto mx-0 mt-0 md:mx-4 md:mt-4'>
            <div className='bg-primary p-6 md:p-10'>
                <h1 className='uppercase font-semibold text-white text-3xl'>
                    Thank you for your purchase!
                </h1>
                <p className='font-light text-white mt-2 text-lg'>Your order has been placed!</p>
            </div>
            <div className='px-6 py-4 md:px-10 md:py-6'>
                <p className='mb-4 text-lg text-slate-700'>We sent an email to {email} with your order confirmation and receipt. </p>
                <h4 className='uppercase text-slate-600 text-2xl mb-4'>Order Details</h4>
                <p className='uppercase text-slate-500 text-md mt-2'>Amount Paid: <span className='font-bold'>{'$ ' + (Math.round(amount * 100) / 100).toFixed(2)}</span></p>
                <p className='uppercase text-slate-500 text-md mt-2'>Date of order: <span className='font-bold'>{orderDate}</span></p>
                <div className='mx-auto mt-4 w-full md:w-1/4' onClick={exit}>
                    <Button label={'Homepage'} color="white" bg_color={'#C84027'}></Button>
                </div>
            </div>
        </div>
    )
}

export default OrderConfirmation