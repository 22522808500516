export const ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART";
export const REMOVE_ITEM_FROM_CART = "REMOVE_ITEM_FROM_CART";
export const DELETE_CART = "DELETE_CART";
export const BALANCE_CART = "BALANCE_CART";
export const DELETE_PRODUCT = 'DELETE_PRODUCT';

const addProductToCart = (product, quantity, state) => {
    const updatedCart = [...state.cart];
    const updatedItemIndex = updatedCart.findIndex(
        (item) => item.variant_id === product.variant_id
    );

    if (updatedItemIndex < 0) {
        updatedCart.push({ ...product, cartQuantity: quantity });
    } else {
        const updatedItem = {
            ...updatedCart[updatedItemIndex]
        };
        updatedItem.cartQuantity = updatedItem.cartQuantity + quantity;
        updatedCart[updatedItemIndex] = updatedItem;
    }

    return { ...state, cart: updatedCart };
};

const removeProductFromCart = (variant_id, state) => {
    const updatedCart = [...state.cart];
    const updatedItemIndex = updatedCart.findIndex(
        (item) => item.variant_id === variant_id
    );

    const updatedItem = {
        ...updatedCart[updatedItemIndex]
    };
    updatedItem.cartQuantity--;
    if (updatedItem.cartQuantity <= 0) {
        updatedCart.splice(updatedItemIndex, 1);
    } else {
        updatedCart[updatedItemIndex] = updatedItem;
    }
    return { ...state, cart: updatedCart };
};

const deleteProduct = (variant_id, state) => {
    const updatedCart = [...state.cart];
    const updatedItemIndex = updatedCart.findIndex(
        (item) => item.variant_id === variant_id
    );

    const updatedItem = {
        ...updatedCart[updatedItemIndex]
    };
    updatedItem.cartQuantity = 0;
    if (updatedItem.cartQuantity <= 0) {
        updatedCart.splice(updatedItemIndex, 1);
    } else {
        updatedCart[updatedItemIndex] = updatedItem;
    }
    return { ...state, cart: updatedCart };
}

const clearCart = (state) => {
    const updatedCart = [...state.cart];
    updatedCart.splice(0, updatedCart.length);
    return { ...state, cart: [] };
};

const balanceCart = (state, variant_id, quantity) => {
    const updatedCart = [...state.cart];
    const updatedItemIndex = updatedCart.findIndex(
        (item) => item.variant_id === variant_id
    );
    const updatedItem = {
        ...updatedCart[updatedItemIndex]
    };
    updatedItem.cartQuantity = quantity;
    if (updatedItem.cartQuantity <= 0) {
        updatedCart.splice(updatedItemIndex, 1);
    }
    else {
        updatedCart[updatedItemIndex] = updatedItem;
    }
    return { ...state, cart: updatedCart };
}
export const cartReducer = (state, action) => {
    switch (action.type) {
        case ADD_ITEM_TO_CART:
            return addProductToCart(action.product, action.quantity, state);
        case REMOVE_ITEM_FROM_CART:
            return removeProductFromCart(action.variant_id, state);
        case DELETE_PRODUCT:
            return deleteProduct(action.variant_id, state);
        case DELETE_CART:
            return clearCart(state);
        // case BALANCE_CART:
        //     return balanceCart(state, action.variant_id, action.quantity)
        default:
            return state;
    }
}