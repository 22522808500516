import * as yup from "yup";

const loginFormSchema = yup.object().shape({
  email: yup
    .string()
    .required("The email field is required.")
    .email("The email must be a valid one."),
  password: yup
    .string()
    .required("The password field is required.")
    .min(8, "Your password must be at least 8 characters long."),
});

const registrationFormSchema = yup.object().shape({
  firstname: yup
    .string()
    .required("Please fill in the first name field.")
    .max(30, "First name should be shorter than 30 characters long."),
  lastname: yup
    .string()
    .required("Please fill in the last name field.")
    .max(30, "Last name should be shorter than 30 characters long."),
  phone: yup
    .string()
    .required("Please fill in the phone number field.")
    .max(20, "Phone Number should be shorter than 20 characters long."),
  email: yup
    .string()
    .required("Please fill in the email field.")
    .email("Email entered should be valid.")
    .max(120, "Email should be shorter than 120 characters long."),
  password: yup
    .string()
    .required("Please fill in the password field.")
    .min(8, "Password should be longer than 8 characters long.")
    .max(120, "Password should be shorter than 120 characters long."),

});

const resetPasswordFormSchema = yup.object().shape({
  password: yup
    .string()
    .required("Please fill in the password field.")
    .min(8, "Password should be longer than 8 characters long.")
    .max(120, "Password should be shorter than 120 characters long."),

});

const resetPasswordEmailFormSchema = yup.object().shape({
  passwordResetEmail: yup
    .string()
    .required("Please fill in the email field.")
    .email("Email entered should be valid.")
    .max(120, "Email should be shorter than 120 characters long."),

});

export { loginFormSchema, registrationFormSchema, resetPasswordFormSchema, resetPasswordEmailFormSchema };
