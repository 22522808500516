import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import HeaderLogo from "../assets/logos/Header_Logo.svg";
import Button from "../Components/Button";
import FormTextInput from "../Components/common/FormTextInput";
import TransitionAlert from "../Components/common/SnackbarAlert";
import { resetPassword } from "../services/AuthService";
import { resetPasswordFormSchema } from "../validations/AuthValidation";
import { getYupValidationErrors } from "../validations/ValidationHelper";
export default function PasswordResetPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordResetResult, setPasswordResetResult] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [inputErrors, setInputErrors] = useState({});

  const handleResetPassword = async () => {
    setIsLoading(true);
    setShowAlert(false);
    const email = searchParams.get("email");
    const passwordResetToken = searchParams.get("password_reset_token");
    try {
      if (email && passwordResetToken) {
        await resetPasswordFormSchema.validate(
          { password },
          { abortEarly: false }
        );
        let result = await resetPassword(email, passwordResetToken, password);
        setPasswordResetResult({
          messageType: "success",
          message: result.message,
          title: "Success!",
        });
        setInputErrors({});
        setShowAlert(true);
        navigate('/auth/login', { replace: true })
      } else {
        throw Error("An email and valid password reset token are required.");
      }
    } catch (e) {
      if (e.name === "ValidationError") {
        let errors = getYupValidationErrors(e);
        setInputErrors(errors);
      } else {
        setPasswordResetResult({
          messageType: "error",
          message: e.message,
          title: "Something went wrong!",
        });
        setShowAlert(true);
      }
    }
    setIsLoading(false);
  };

  const closeAlert = () => {
    setShowAlert(false);
  };
  useEffect(() => {
    // handleResetPassword();
  }, [])
  return (
    <div className="flex bg-backgroundColor">
      <div className="flex flex-1 flex-col items-center">
        <img
          className="w-52 h-auto mt-4"
          src={HeaderLogo}
          alt="Vacosta Style Logo"
        />
        <div className="mt-20 sm:w-9/12 md:w-5/12">
          <h1 className="text-primary text-4xl font-semibold border-b-4 border-b-gray-50 pb-4">
            RESET PASSWORD
          </h1>
          <TransitionAlert
            open={showAlert}
            message={passwordResetResult.message}
            severity={passwordResetResult.messageType}
            title={passwordResetResult.title}
            handleClose={closeAlert}
          />
          <FormTextInput
            id="password"
            label="Password"
            placeholder="Password..."
            value={password}
            onChange={setPassword}
            error={inputErrors.password}
          />
          <div className="mt-5" onClick={handleResetPassword}>
            <Button

              disabled={isLoading}
              bg_color={'#C84027'}
              color={'white'}
              label={isLoading ? "Loading ..." : "RESET PASSWORD"}
            >

            </Button>
          </div>
        </div>
      </div>
      <img
        src={require("../assets/images/registration-picture.jpg")}
        className="w-0 lg:w-auto h-screen"
        alt=""
      />
    </div>
  );
}
